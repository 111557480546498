/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";

/* gql hooks */

// project imports
import MainCard from "ui-component/cards/MainCard";
import { RootState, useDispatch, useSelector } from "store";
import { setPage, setRowsPerPage, setSearchText } from "store/slices/table";
import AdminTable from "./tables";

/* custom hooks */
import _debounce from "lodash.debounce";

import Spinner from "components/spinner";
import { EventReport } from "types/event";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useDebouncedSearch from "views/event/event-list/hooks/useDebounceSearch";
import { useGQL } from "../hooks/useGQL";
import EventReportTable from "./tables";
import FilteModal from "./filter";
import { ArrangementOrder } from "types";

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];
// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const EventReports = () => {
  const navigate = useNavigate();
  const [searchTextInput, setSearchTextInput] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [debouncedSearch] = useDebouncedSearch((event: any | undefined) => {
    if (event?.target) {
      setSearchTextInput(event.target.value);
      // dispatch(setSearchText(event.target.value));
    }
  });

  const [order, setOrder] = useState<ArrangementOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("_id");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);

  const dispatch = useDispatch();

  /* queries & mutations */
  const { GET_ALL_EVENT_REPORT_LIST } = useGQL();
  const { error, loading, data, refetch } = GET_ALL_EVENT_REPORT_LIST();

  /* local states */
  const [eventReports, setEventReports] = useState<EventReport[]>([]);

  const [meta, setMeta] = useState<Meta>();
  const [filtersInput, setFiltersInput] = useState<string | null>("");

  const [filterInputData, setFiltersInputData] = useState({
    searchText: "",
    eventType: "",
    status: "",
    from: "",
    to: "",
    modality: "",
  });
  useEffect(() => {
    if (data?.getEventReport?.eventReports) {
      setEventReports(data?.getEventReport?.eventReports);
      setTotal(data?.getEventReport?.pagination?.total);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      eventReportInput: {
        limit,
        skip,
        searchText: filterInputData.searchText
          ? filterInputData.searchText
          : "",
        eventType:
          filterInputData.eventType === "" ? null : filterInputData.eventType,
        status: filterInputData.status === "" ? null : filterInputData.status,
        from: filterInputData.from === "" ? null : filterInputData.from,
        to: filterInputData.to === "" ? null : filterInputData.to,
        modality:
          filterInputData.modality === "" ? null : filterInputData.modality,
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    refetch({
      eventReportInput: {
        limit: meta?.limit,
        skip: meta?.skip,
        searchText: filterInputData.searchText
          ? filterInputData.searchText
          : "",
        eventType:
          filterInputData.eventType === "" ? null : filterInputData.eventType,
        status: filterInputData.status === "" ? null : filterInputData.status,
        from: filterInputData.from === "" ? null : filterInputData.from,
        to: filterInputData.to === "" ? null : filterInputData.to,
        modality:
          filterInputData.modality === "" ? null : filterInputData.modality,
      },
    });
  };

  useEffect(() => {
    setPage(0);
    handleRefetch();
  }, [filterInputData, rowsPerPage]);

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const openSort = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  return (
    <>
      <MainCard
        title="Event Reports"
        content={false}
        sx={{ position: "relative" }}
      >
        <CardContent>
          <Grid marginLeft={"-1.5rem"} item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Filter</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FilteModal
                  handleRefetch={handleRefetch}
                  total={total}
                  loading={loading}
                  setFiltersInputData={setFiltersInputData}
                  filterInputData={filterInputData}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </CardContent>

        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Divider />
            <EventReportTable {...{ eventReports, handleRefetch }} />

            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getEventReport?.pagination.total / rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </>
  );
};

export default EventReports;
