import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

// assets
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import LocalOfferTwoToneIcon from "@mui/icons-material/LocalOfferTwoTone";
import { TabsProps } from "types";
import useConfig from "hooks/useConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSnackbar from "hooks/common/useSnackbar";
import StarBorderTwoToneIcon from "@mui/icons-material/StarBorderTwoTone";
import lang from "constants/language";
import IndividualUserInformation from "./individualUserInformation";
import { useGQL } from "./hooks/useGQL";
import { getUserInformationDetailsResponse } from "./types";
import IndividualMembershipInformation from "./individualMembership";
import GppGoodTwoToneIcon from "@mui/icons-material/GppGoodTwoTone";
import CPDViewAndList from "./CPD diary/Cpd-List";
import NotesList from "./membership-history/Notes-list/list";
import EventHistoryList from "./event-history/list";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import InvoiceList from "./paymentHistory/invoice-list";
import makeFirstLetterUpperCaseOfEachWord from "common/upperCase";
import { useLazyQuery } from "@apollo/client";
import { GET_END_DATE_OF_INDIVIDUAL_USER } from "grapqhl/individualUser/query";
// tabs
function TabPanel({
  children,
  value,
  index,
  userId,
  handleOpenSnackbar = () => {},
  setValue = () => {},
  ...other
}: TabsProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const check =
    value === 1 || value === 2 || value === 3 || value === 4 || value === 5;
  if ((userId === null || typeof userId === "undefined") && check) {
    setValue(0);
    handleOpenSnackbar({
      message: lang.INDIVIDUAL_USER.CREATE_USER_FIRST,

      alertType: "error",
    });
  } else {
    if (Object.fromEntries(searchParams).tab) {
      value = Number(Object.fromEntries(searchParams).tab);
    }
    setValue(value);
  }

  // for dynamic tabs
  const removeTabParam = () => {
    if (searchParams.has("tab")) {
      searchParams.delete("tab");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    removeTabParam();
  }, [value]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// tabs option
const tabsOption = [
  {
    label: "User information",
    icon: <PersonOutlineTwoToneIcon />,
    caption: "Account information setting",
  },
  {
    label: "Membership Information",
    icon: <StarBorderTwoToneIcon />,
    caption: "Edit/View membership",
  },

  {
    label: "Membership history",
    icon: <PersonOutlineTwoToneIcon />,
    caption: "Membership history",
  },
  {
    label: "Event history",
    icon: <CalendarMonthTwoToneIcon />,
    caption: "Event history",
  },
  {
    label: "Payment history",
    icon: <PaymentTwoToneIcon />,
    caption: "Payment history",
  },
  {
    label: "CPD Diary",
    icon: <GppGoodTwoToneIcon />,
    caption: "CPD Diary",
  },
];

// ==============================|| PROFILE 2 ||============================== //

const IndividualUserManagement = () => {
  const theme = useTheme();
  const params = new URLSearchParams(window.location.search);
  const tabNumber = params.get("tab") as string;
  const { borderRadius } = useConfig();
  const [value, setValue] = React.useState<number>(0);
  const [searchParams, setParams] = useSearchParams();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [userInformation, setUserInformation] =
    useState<getUserInformationDetailsResponse>({
      _id: "",
      company: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      position: "",
      industry: "",
      timeInProfession: "",
      status: "",
      isGuestUser: false,
      hasPaidJoiningFee: false,
      address: {
        flatUnit: "",
        streetName: "",
        streetNumber: "",
        postcode: "",
        suburb: "",
        country: "",
        state: "",
      },
      membership: {
        membershipType: "",
        status: "",
        validUptoDate: "",
        code: "",
        memberSince: "",
      },
      certification: {
        crm: "",
        aml: "",
      },
    });

  const { GET_USER_INFORMATION_DETAILS, GET_END_DATE } = useGQL();
  const navigate = useNavigate();
  const userId = params.get("UserId") as string;
  const { handleOpenSnackbar } = useSnackbar();

  const [endDate, setEndDate] = useState();

  const {
    error: getEventError,
    loading: getEventLoading,
    data: userInformationData,
    refetch,
  } = GET_USER_INFORMATION_DETAILS(userId || "");

  useEffect(() => {
    setUserInformation(userInformationData?.getUser?.user);
  }, [userInformationData]);

  useEffect(() => {
    if (tabNumber) {
      setValue(parseInt(tabNumber));
    }
  }, [tabNumber]);

  const [getEndData] = useLazyQuery(GET_END_DATE_OF_INDIVIDUAL_USER);

  const fetchData = async () => {
    const { data } = await getEndData({
      variables: {},
      fetchPolicy: "network-only",
    });

    setEndDate(data?.searchMembershipTypeExpiry?.endDate);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={
            !getEventLoading &&
            (userId
              ? makeFirstLetterUpperCaseOfEachWord(
                  userInformationData?.getUser?.user?.firstName +
                    " " +
                    userInformationData?.getUser?.user?.lastName
                ) || null
              : "Individual User Management")
          }
          content={false}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={4} xl={2}>
              <CardContent>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  orientation="vertical"
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      borderBottom: "none",
                    },
                    "& button": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.grey[600]
                          : theme.palette.grey[600],
                      minHeight: "auto",
                      minWidth: "100%",
                      py: 1.5,
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      textAlign: "left",
                      justifyContent: "flex-start",
                      borderRadius: `${borderRadius}px`,
                    },
                    "& button.Mui-selected": {
                      color: theme.palette.primary.main,
                      background:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.main
                          : theme.palette.grey[50],
                    },
                    "& button > svg": {
                      marginBottom: "0px !important",
                      marginRight: 1.25,
                      marginTop: 1.25,
                      height: 20,
                      width: 20,
                    },
                    "& button > div > span": {
                      display: "block",
                    },
                    "& > div > span": {
                      display: "none",
                    },
                  }}
                >
                  {tabsOption.map((tab, index) => (
                    <Tab
                      key={index}
                      icon={tab.icon}
                      label={
                        <Grid container direction="column">
                          <Typography variant="subtitle1" color="inherit">
                            {tab.label}
                          </Typography>
                          <Typography
                            component="div"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab.caption}
                          </Typography>
                        </Grid>
                      }
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </CardContent>
            </Grid>
            <Grid item xs={12} md={8} xl={10}>
              <CardContent
                sx={{
                  borderLeft: "1px solid",
                  borderColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : theme.palette.grey[200],
                  height: "100%",
                }}
              >
                <TabPanel value={value} index={0} setValue={setValue}>
                  <IndividualUserInformation
                    userInformation={userInformation}
                    refetchUserInformation={refetch}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                  userId={userId}
                  handleOpenSnackbar={handleOpenSnackbar}
                  setValue={setValue}
                >
                  {userId ? (
                    <IndividualMembershipInformation
                      userInformation={userInformation}
                      refetchIndividualInformation={refetch}
                      endDate={endDate}
                    />
                  ) : (
                    ""
                  )}
                </TabPanel>

                <TabPanel
                  value={value}
                  index={2}
                  userId={userId}
                  handleOpenSnackbar={handleOpenSnackbar}
                  setValue={setValue}
                >
                  {userId ? (
                    <NotesList
                      membershipInformation={userInformation?.membership}
                      refetchCompanyInformation=""
                      userId={userId}
                    />
                  ) : (
                    ""
                  )}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={3}
                  userId={userId}
                  handleOpenSnackbar={handleOpenSnackbar}
                  setValue={setValue}
                >
                  {userId ? <EventHistoryList /> : ""}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={4}
                  userId={userId}
                  handleOpenSnackbar={handleOpenSnackbar}
                  setValue={setValue}
                >
                  {userId ? <InvoiceList /> : ""}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={5}
                  userId={userId}
                  handleOpenSnackbar={handleOpenSnackbar}
                  setValue={setValue}
                >
                  {userId ? <CPDViewAndList /> : ""}
                </TabPanel>
              </CardContent>
            </Grid>
          </Grid>
          <Divider />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default IndividualUserManagement;
