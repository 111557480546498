/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Chip from "ui-component/extended/Chip";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import { headCells } from "../constants/variables";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "../hooks/useSnackbar";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";

import { getCpdListResponse } from "views/individual-user/types";

import { useNavigate } from "react-router-dom";

import moment from "moment";
import TableErrorMessage from "common/tableErrorMessage";

type Sort = { order: string; orderBy: string } | null;
type CpdListProps = {
  cpdList: getCpdListResponse[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

type Order = "asc" | "desc";
const CPDListTable = ({ cpdList, handleEdit, handleRefetch }: CpdListProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const userId = params.get("UserId") as string;

  useEffect(() => {
    handleRefetch();
  }, [order, orderBy]);

  const statusMap = {
    PENDING: "Pending",
    APPROVED: "Approved",
    NOT_APPROVED: "Not approved",
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const setOrder = isAsc ? "desc" : "asc";
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  const ActionButton = ({ cpdId }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState("");

    const handleMenuActionClick = (event: any, cpdId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(cpdId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };
    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, cpdId)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={(e) => handleEdit(e, selected)}> Edit</MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {cpdList.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              cpdList
                .filter(
                  (cpd) => cpd.status !== "PENDING" || cpd.eventId !== null
                )
                .map((cpd) => (
                  <TableRow key={cpd._id}>
                    <TableCell align="left">
                      {" "}
                      {moment(cpd?.eventDate).format("DD/MM/YY")}
                    </TableCell>
                    <TableCell align="left">{cpd?.eventName}</TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        maxWidth: "250px",
                        wordWrap: "break-word",
                      }}
                    >
                      {cpd?.description}
                    </TableCell>
                    <TableCell align="left">{statusMap[cpd?.status]}</TableCell>
                    <TableCell align="center">
                      {cpd?.rejectionDescription || "-"}
                    </TableCell>
                    <TableCell align="center">{cpd?.points}</TableCell>
                    <TableCell align="center" sx={{ pr: 3 }}>
                      <ActionButton cpdId={cpd?._id} />
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CPDListTable;
