import { TableCellProps } from "@mui/material";
import { initialValues } from "./variables";

export interface EnhancedTableHeadProps extends TableCellProps {
  headCells: HeadCell[];
  order: ArrangementOrder;
  orderBy: string;
  onRequestSort: (e: React.SyntheticEvent, p: string) => void;
}

export type ArrangementOrder = "asc" | "desc" | undefined;

export type HeadCell = {
  id: string;
  numeric: boolean;
  label: string;
  disablePadding?: string | boolean | undefined;
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  sort: boolean;
};

type Point = {
  type: string;
  coordinates: [number];
};

type Address = {
  displayAddress: string;
  location: Point;
};

type Pagination = {
  total: number;
  hasNextPage: boolean;
};

export type AppUser = {
  _id: string;
  __typename: string;
  authProvider: string;
  authProviderId: string;
  password: string;
  firstName: string;
  lastName: string;
  address: Address;
  status: string;
  bio: string;
  lastLoggedInAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type GetAppUsersDTO = {
  input: {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
  };
};

export interface CreateAppUserDTO {
  input: {
    authProvider: string;
    authProviderId: string;
    firstName: string;
    lastName: string;
    address: string;
    bio: string;
  };
}

export interface UpdateAppUserDTO {
  authProvider?: string;
  authProviderId?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  bio?: string;
}

interface IAppUser {
  message: string;
  user?: AppUser;
  users?: [AppUser];
  pagination?: Pagination;
}

export type UserResponse = {
  getAllAppUsers?: IAppUser;
  getAppUser?: IAppUser;
  createAppUser?: IAppUser;
  updateAppUser?: IAppUser;
  deleteAppUser?: IAppUser;
};

export type FormInputType = typeof initialValues;

export enum AuthType {
  EMAIL = "email",
  PHONE = "phone",
}

export const StatusMappingObject: any = {
  VALID: "Valid",
  AWATING_PAYMENT: "Awating Payment",
  GRACE: "Grace",
  RESIGNED: "Resigned",
};

export const PaymentMethod = [
  {
    value: "OFFLINE_EFT",
    label: "Offline EFT",
  },
  {
    value: "OFFLINE_CREDIT_CARD",
    label: "Offline Credit Card",
  },
  {
    value: "OFFLINE_OTHER",
    label: "Offline Other",
  },
];

export const paymentMethodMap = {
  OFFLINE_PAYMENT: "Offline Payment",
  ONLINE_PAYMENT: "Online Payment",
  AWAITING_PAYMENT: "Awaiting Payment",
  OFFLINE_EFT: "Offline EFT",
  OFFLINE_CREDIT_CARD: "Offline Credit Card",
  OFFLINE_OTHER: "Offline Other",
};
