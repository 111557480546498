import { gql } from "@apollo/client";

export const GET_USER = gql`
  query ($id: String!) {
    getUser(id: $id) {
      message
      user {
        _id
        name
        email
        phone
        bio
        admin {
          _id
          name
          email
          role
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query ($input: GetUsersDTO!) {
    getAllUsers(input: $input) {
      message
      users {
        _id
        name
        email
        bio
        phone
        admin {
          _id
          name
          email
          phone
          role
        }
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const SEARCH = gql`
  query Search($searchInput: GetUsersListDTO!) {
    search(input: $searchInput) {
      data {
        type
        _id
        firstName
        lastName
        title
        membershipType
      }
    }
  }
`;
