import { TableCell, TableRow } from '@mui/material';

const TableErrorMessage = ({ colSpan = 12 }: { colSpan: any }) => (
  <TableRow>
    <TableCell
      colSpan={colSpan}
      sx={{ textAlign: 'center', direction: 'row', justifyContent: 'center' }}
    >
      {'No Data Found'}
    </TableCell>
  </TableRow>
);

export default TableErrorMessage;
