import * as Yup from "yup";
export const individualUserSchena = Yup.object({
  company: Yup.string().min(3).max(1000).label("Company Name"),
  firstName: Yup.string().min(2).max(1000).required("First name is required"),
  lastName: Yup.string()
    .min(2)
    .max(1000)
    .required("Family name is required")
    .label("Family name"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number must only contain numbers")
    .min(8, "Main contact phone number must be at least 8 digits long")
    .required("Mobile number is required"),
  email: Yup.string().email().min(3).max(1000).required("Email is required"),
  position: Yup.string().min(3).max(1000),
  industry: Yup.string().min(3).max(1000),
  timeInProfession: Yup.number()
    .min(0, "Time in Profession must be greater than or equal to 0")
    .max(100, "Time in Profession must be less than or equal to 100")
    .test(
      "is-two-decimal-places",
      "Time in Profession can have at most two decimal places",
      (value: any) => {
        if (value === undefined || value === null) {
          return true; // Allow empty values (nullable)
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    )
    .label("Time in Profession")
    .nullable(),
  certification: Yup.object({
    crm: Yup.string(),
    aml: Yup.string(),
  }),
  address: Yup.object({
    flatUnit: Yup.string().nullable(),
    streetNumber: Yup.string().required("Street number is required"),
    streetName: Yup.string().required("Street name is required"),
    postcode: Yup.string().required("Postcode is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    suburb: Yup.string().required("Suburb is required"),
  }),
});
