import { useMutation, useQuery } from '@apollo/client';
import {
  CHANGE_PASSWORD,
  GET_USER_PROFILE,
  UPDATE_ADMIN,
  GET_SURCHARGE_VALUE,
  CREATE_OR_UPDATE_SURCHARGE_VALUE,
} from 'grapqhl';
import {
  Admin,
  AdminResponse,
  ChangePasswordDTO,
  ChangePasswordResponse,
  CreateOrUpdateSurchargeValue,
  Surcharge,
  SurchgareResponse,
  UserProfileResponse,
} from '../../../types/profile';

export const useGQL = () => {
  const ADMIN_CHANGE_PASSWORD = () =>
    useMutation<ChangePasswordDTO, ChangePasswordResponse>(CHANGE_PASSWORD);
  const GET_ADMIN_PROFILE = () =>
    useQuery<UserProfileResponse>(GET_USER_PROFILE);
  const UPDATE_ADMIN_PROFILE = () =>
    useMutation<AdminResponse, { input: any }>(UPDATE_ADMIN);
  const GET_SURCHARGE = () => useQuery<SurchgareResponse>(GET_SURCHARGE_VALUE);
  const CREATE_OR_UPDATE_SURCHARGE_VALUES = () =>
    useMutation<CreateOrUpdateSurchargeValue, { input: Partial<Surcharge> }>(
      CREATE_OR_UPDATE_SURCHARGE_VALUE
    );

  return {
    ADMIN_CHANGE_PASSWORD,
    GET_ADMIN_PROFILE,
    UPDATE_ADMIN_PROFILE,
    CREATE_OR_UPDATE_SURCHARGE_VALUES,
    GET_SURCHARGE,
  };
};
