/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';

/* gql hooks */

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { RootState, useDispatch, useSelector } from 'store';

/* variables imports */

import NotesListTable from './tables';
import { useGQL } from 'views/individual-user/hooks/useGQL';
import {
  EventHistoryListResponse,
  NotesResponseList,
} from 'views/individual-user/types';
import { setPage, setRowsPerPage } from 'store/slices/table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/system';
import moment from 'moment';
import { StatusMappingObject } from './constants/types';

// ==============================|| CUSTOMER LIST ||============================== //
const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;
const EventHistoryList = () => {
  const [meta, setMeta] = useState<Meta>();
  const dispatch = useDispatch();

  const { searchText, page, rowsPerPage, sort } = useSelector(
    (state: RootState) => state.table
  );
  const params = new URLSearchParams(window.location.search);
  const userId = params.get('UserId') as string;

  /* queries & mutations */
  const { GET_EVENT_HISTORY_LIST } = useGQL();
  const { error, loading, data, refetch } = GET_EVENT_HISTORY_LIST({ userId });

  const [eventHistories, setEventHistories] = useState<
    EventHistoryListResponse[]
  >([]);
  useEffect(() => {
    if (data?.getUserEventBookingHistory?.eventBookingHistory) {
      setEventHistories(data?.getUserEventBookingHistory?.eventBookingHistory);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      eventHistoryInput: {
        limit,
        skip,
        userId,
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    refetch({
      eventHistoryInput: {
        limit: meta?.limit,
        skip,
        userId,
      },
    });
  };
  const [anchor, setAnchor] = useState(null);

  const handleChangePage = (event, newPage: number) => {
    dispatch(setPage(newPage - 1));
  };

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    }
  };

  return (
    <>
      <MainCard content={false} sx={{ position: 'relative' }}>
        <NotesListTable {...{ eventHistories, handleRefetch }} />
        <Grid item xs={12} sx={{ p: 3 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Pagination
                count={Math.ceil(
                  data?.getUserEventBookingHistory?.pagination?.total /
                    rowsPerPage
                )}
                color="primary"
                onChange={handleChangePage}
                defaultPage={page + 1}
                page={page + 1}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
                hidePrevButton={page === 0 ?? true}
              />
            </Grid>
            <Grid item>
              <span>Show</span>
              <Button
                size="large"
                endIcon={<ExpandMoreIcon />}
                onClick={handleClickPage}
                className="pagination-button"
              >
                {rowsPerPage} per page
              </Button>
              <Menu
                id="menu-user-list-style1"
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClosePage}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {rowsPerPageOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={(event) => handlePageMenuItemClick(event)}
                  >
                    {option.label} rows
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default EventHistoryList;
