/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { resourceInformationInputDto } from "types/resource";

import { Formik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "hooks/common/useSnackbar";
import { Category } from "store/constant";
import lang from "constants/language";
import { useNavigate } from "react-router-dom";
import MainCard from "ui-component/cards/MainCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Stack } from "@mui/system";
import { resourceInformationSchema } from "./resource-information-validation";
import { useGQL } from "./hooks/useGQL";

import CustomTinyMCE from "components/editors/CustomTinyMCE";

const cancelButtonStyle = {
  background: "none",
  color: "#212121",
  border: "1px solid #212121",
  borderRadius: "5",
  minWidth: "0px",
  width: "120px",
  maxHeight: "44px",
};

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const CreateResourceInformation = () => {
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();
  const { CREATE_RESOURCE, GET_RESOURCE_INFORMATION, UPDATE_RESOURCE_INFO } =
    useGQL();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const resourceId = params.get("ResourcesId") as string;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitButtonType, setSubmitButtonType] = useState<string>("draft");

  const [resource, setResource] = useState<resourceInformationInputDto>({
    title: "",
    isMemberOnly: false,
    permalink: null,
    date: new Date(),
    author: "",
    category: "",
    subCategories: [],
    description: "",
    status: "",
  });

  const {
    error: resourceError,
    loading: resourceLoading,
    data: resourceInformationData,
    refetch,
  } = GET_RESOURCE_INFORMATION(resourceId);

  useEffect(() => {
    if (resourceInformationData?.getResource?.resource) {
      setResource(resourceInformationData?.getResource?.resource);
      setImageSrc(resourceInformationData?.getResource?.resource?.media);
    }
  }, [resourceInformationData]);

  const [imageSrc, setImageSrc] = useState<string>("");
  const [imgError, setImgError] = useState<boolean>(false);

  const [handleCreate, { data: create, loading, error }] = CREATE_RESOURCE();

  const [handleUpdate, { data: update }] = UPDATE_RESOURCE_INFO();

  const handleCreateResourceInformation = async (
    values: Partial<resourceInformationInputDto>,
    setSubmitting: (isSubmitting: boolean) => void,
    status = "PUBLISHED"
  ) => {
    try {
      const input = { ...values };
      input.status = status;

      if (!resourceId) {
        const { data } = await handleCreate({
          variables: {
            input: { ...input },
          },
        });
        if (data) {
          handleOpenSnackbar({
            message:
              status === "PUBLISHED"
                ? lang.RESOURCE.PUBLISH
                : lang.RESOURCE.DRAFT,
            alertType: "success",
          });
          setSubmitting(false);
        }
      }

      setTimeout(() => {
        navigate("/resources/list");
      }, 2000);
      if (resourceId) {
        const input = { ...values };
        input.status = "PUBLISHED";
        delete input._id;
        delete input.slug;
        input.isDeleted = false;
        const { data } = await handleUpdate({
          variables: {
            resourceId: resourceId,
            input: {
              ...input,
            },
          },
        });
        if (data) {
          handleOpenSnackbar({
            message: lang.RESOURCE.UPDATE,
            alertType: "success",
          });
          setSubmitting(false);
        }
        refetch();

        setTimeout(() => {
          navigate("/resources/list");
        }, 2000);
      }
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };

  const handleDraft = async (values) => {
    const inputData = { ...values };
    inputData.status = "DRAFT";
    delete inputData.slug;
    delete inputData._id;
    inputData.isDeleted = false;
    const { data } = await handleUpdate({
      variables: {
        resourceId: resourceId,
        input: {
          ...inputData,
        },
      },
    });
    if (data) {
      handleOpenSnackbar({
        message: lang.RESOURCE.DRAFT,
        alertType: "success",
      });
      setSubmitting(false);
    }
    refetch();

    setTimeout(() => {
      navigate("/resources/list");
    }, 2000);
  };

  const handleDelete = async () => {
    const isConfirm = await getConfirmation({
      title: "Delete Resource Information",
      message: "Are you sure you want to delete resource information ?",
    });
    if (isConfirm) {
      try {
        const { data } = await handleUpdate({
          variables: {
            resourceId: resourceId,
            input: {
              isDeleted: true,
            },
          },
        });
        if (data) {
          handleOpenSnackbar({
            message: lang.RESOURCE.DELETE,
            alertType: "success",
          });
          setSubmitting(false);
        }
        refetch();

        setTimeout(() => {
          navigate("/resources/list");
        }, 2000);
      } catch (e: any) {
        handleOpenSnackbar({ message: e.message, alertType: "error" });
      }
    }
  };

  return resourceLoading ? null : (
    <>
      <MainCard>
        <FormWrapper>
          <Formik
            enableReinitialize
            initialValues={{
              title: resource?.title == undefined ? "" : resource?.title,
              isMemberOnly: resource?.isMemberOnly || false,
              permalink: resource?.permalink || "",
              date: resource?.date || new Date(),
              author: resource?.author || "",
              category: resource?.category || "",
              subCategories: resource?.subCategories || [],
              description: resource?.description || "",
              status: resource?.status || "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (submitButtonType === "draft") {
                if (resourceId) {
                  handleDraft(values);
                } else {
                  handleCreateResourceInformation(
                    values,
                    setSubmitting,
                    "DRAFT"
                  );
                }
              } else {
                handleCreateResourceInformation(values, setSubmitting);
              }
              setSubmitting(true);
            }}
            validationSchema={resourceInformationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              handleChange,
              getFieldProps,
              setFieldValue,
              setErrors,

              /* and other goodies */
            }) => (
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Grid item xs={8} container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      component="h1"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                    >
                      {!resourceId
                        ? "Create new resource"
                        : "Update existing resource"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Title"
                      label="Title"
                      {...getFieldProps("title")}
                    />
                    {touched.title && errors.title && (
                      <FormHelperText error id="title-error">
                        {errors.title}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isMemberOnly}
                          {...getFieldProps(`isMemberOnly`)}
                          onChange={(e) => {
                            setFieldValue(`isMemberOnly`, e.target.checked);
                          }}
                          name="checked"
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="subtitle1">
                          Members only
                        </Typography>
                      }
                    />
                    {touched.isMemberOnly && errors.isMemberOnly && (
                      <FormHelperText error id="lastName-error">
                        {errors.isMemberOnly}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Permalink"
                      label="Permalink"
                      {...getFieldProps(`permalink`)}
                    />
                    {touched.permalink && errors.permalink && (
                      <FormHelperText error id="permalink-error">
                        {errors.permalink}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label="Date"
                        sx={{ width: "100%" }}
                        value={values?.date && new Date(values?.date)}
                        onChange={(newValue) => {
                          setFieldValue(`date`, dayjs(newValue).toJSON());
                        }}
                      />
                    </LocalizationProvider>
                    {touched.date && errors.date && (
                      <FormHelperText error id="date">
                        {errors.date}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      placeholder="Author"
                      label="Author"
                      {...getFieldProps(`author`)}
                    />

                    {touched.author && errors.author && (
                      <FormHelperText error id="author-error">
                        {errors.author}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="Category"
                      label="Category"
                      select
                      fullWidth
                      sx={{ width: "100%" }}
                      {...getFieldProps("category")}
                    >
                      {Category.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    {touched?.category && errors?.category && (
                      <FormHelperText error id="category-error">
                        {errors?.category}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={[]}
                      value={values.subCategories ? values.subCategories : []}
                      onChange={(event, value) => {
                        setFieldValue("subCategories", value);
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub categories"
                          placeholder="Sub categories"
                        />
                      )}
                    />

                    {touched?.subCategories && errors?.subCategories && (
                      <FormHelperText error id="subCategories-error">
                        {errors?.subCategories}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                {/* </Grid> */}

                <Divider sx={{ height: 10, width: "100%" }} />

                <Grid item xs={12} marginTop={"1.5rem"}>
                  <CustomTinyMCE
                    height={350}
                    setEditorState={(e) => {
                      handleChange({
                        target: {
                          name: "description",
                          value: e,
                        },
                      });
                    }}
                    value={values?.description}
                  />
                  {touched?.description && errors?.description && (
                    <FormHelperText error id="description">
                      {errors?.description}
                    </FormHelperText>
                  )}
                </Grid>

                <Divider sx={{ height: 10, width: "100%" }} />
                <Grid container justifyContent="flex-end">
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="right"
                    sx={{ mt: "1rem" }}
                  >
                    {resourceId && (
                      <Button
                        color="primary"
                        onClick={handleDelete}
                        sx={cancelButtonStyle}
                      >
                        Delete
                      </Button>
                    )}

                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      onMouseEnter={() => setSubmitButtonType("draft")}
                      sx={cancelButtonStyle}
                      onClick={() => {
                        imageSrc === ""
                          ? setImgError(true)
                          : setImgError(false);
                      }}
                    >
                      Save as draft
                    </Button>

                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                      color="primary"
                      onMouseEnter={() => setSubmitButtonType("publish")}
                      onClick={() => {
                        imageSrc === ""
                          ? setImgError(true)
                          : setImgError(false);
                      }}
                    >
                      Publish
                    </Button>
                  </Stack>
                </Grid>
              </form>
            )}
          </Formik>
        </FormWrapper>
      </MainCard>
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default CreateResourceInformation;
