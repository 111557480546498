import { gql } from "@apollo/client";

export const UPDATE_USER_INFORMATION = gql`
  mutation UpdateUserInformation(
    $input: CreateUserInformationDTO!
    $updateUserInformationId: String
  ) {
    updateUserInformation(input: $input, id: $updateUserInformationId) {
      message
      user {
        _id
        firstName
        lastName
        email
        password
        phone
        position
        company
        industry
        timeInProfession
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        certification {
          crm
          aml
        }
        status
        membership {
          membershipType
          code
          status
          validUptoDate
        }
      }
    }
  }
`;
export const UPDATE_USER_MEMBERSHIP = gql`
  mutation UpdateUser($input: UserMembershipInput!, $updateUserId: String) {
    updateUser(input: $input, id: $updateUserId) {
      user {
        membership {
          membershipType
          code
          status
          validUptoDate
        }
      }
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($userId: String!) {
    resetUserPassword(userId: $userId) {
      message
    }
  }
`;

export const ADDCPD = gql`
  mutation CreateCpdPoint($createCpdPoint: CreateCpdInputDto!) {
    createCpdPoint(createCpdPoint: $createCpdPoint) {
      _id
      eventId
      eventName
      description
      units
      eventDate
      points
      status
      rejectionDescription
      createdBy
    }
  }
`;

export const UPDATE_CPD = gql`
  mutation UpdateCpdPoint($updateCpdPoint: UpdateCpdPointDto!) {
    updateCpdPoint(updateCpdPoint: $updateCpdPoint) {
      _id
      eventId
      eventName
      description
      units
      eventDate
      points
      status
      rejectionDescription
      createdBy
    }
  }
`;
export const UPDATE_CPD_STATUS = gql`
  mutation UpdateCpdStatus($updateCpdStatusInput: UpdateCpdStatusInputDto!) {
    updateCpdStatus(updateCpdStatusInput: $updateCpdStatusInput) {
      message
    }
  }
`;
export const CREATE_NOTES = gql`
  mutation AddUserNote($addUserNoteInput: AddUserNoteInputDto!) {
    addUserNote(addUserNoteInput: $addUserNoteInput) {
      description
      createdAt
      status
      createdBy
    }
  }
`;

export const ADD_INVOICE = gql`
  mutation CreateUserInvoicePayment(
    $invoicePaymentInput: CreatePaymentInputDto!
  ) {
    createUserInvoicePayment(invoicePaymentInput: $invoicePaymentInput) {
      _id
      invoiceNum
      transactionId
      paymentDate
      paymentMethod
      paidAmount
      description
      dueDate
    }
  }
`;
export const ACTIVATE_USER_STATUS = gql`
  mutation ActivateUsersd($activateUsersdId: String!) {
    ActivateUsersd(id: $activateUsersdId) {
      message
      user {
        _id
        status
      }
    }
  }
`;

export const DELETE_USER_PERMANENTLY_FROM_ALL_MODULES = gql`
  mutation DeleteUser($deleteUserId: String!) {
    deleteUser(id: $deleteUserId) {
      message
    }
  }
`;

export const UPDATE_PAYMENT_DETAILS = gql`
  mutation UpdateInvoicePayment($input: UpdatePaymentInputDto!) {
    updateInvoicePayment(input: $input) {
      _id
      invoiceNum
      transactionId
      paymentDate
      paymentMethod
      paidAmount
      description
      dueDate
      status
    }
  }
`;
