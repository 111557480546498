import React, { useEffect, useState } from 'react';
// material-ui
import {
  // Checkbox,
  Divider,
  Grid,
  TextField,
  // FormGroup,
  // FormControlLabel,
  FormHelperText,
  // Typography,
  MenuItem,
  Box,
  Button,
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
// import { gridSpacing } from 'store/constant';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import { useNavigate } from 'react-router-dom';
import useGQL from 'hooks/useGQL';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import Snackbar from 'ui-component/extended/Snackbar';
import { RootState } from 'store';
import { RegisterType, userRole as role } from '../constants/types';
import styled from 'styled-components';
import { firstLetterUppercase } from 'utils/commonHelpers';
import Spinner from 'components/spinner';
import { userRole } from 'constants/profile';

const AdminAddForm = ({ adminDataInformation, refetchAdmin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector((state: RootState) => state.snackbar);

  const [pageLoading, setPageLoading] = useState(false);
  const { ADMIN_REGISTER, UPDATE_ADMIN } = useGQL();

  const params = new URLSearchParams(window.location.search);
  const AdminId = params.get('AdminId') as string;

  const [handleRegister, { loading, error, data }] = ADMIN_REGISTER();
  const [handleUpdate, { loading: updateLoading, data: updateData }] =
    UPDATE_ADMIN();

  useEffect(() => {
    if (data?.register) {
      dispatch(
        openSnackbar({
          open: true,
          message:
            data?.register?.message ||
            `${firstLetterUppercase(
              data?.register?.role || 'Admin'
            )} created successfully!`,
          anchorOrigin: { horizontal: 'center' },
          variant: 'alert',
          alert: {
            color: 'success',
          },
        })
      );

      navigate('/admins/list');
    }
  }, [loading, data]);

  useEffect(() => {
    if (updateData?.updateAdmin) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Admin updated successfully',

          anchorOrigin: { horizontal: 'center' },
          variant: 'alert',
          alert: {
            color: 'success',
          },
        })
      );

      navigate('/admins/list');
      refetchAdmin();
    }
  }, [updateLoading, updateData]);

  if (pageLoading) {
    return <Spinner />;
  }
  return (
    <>
      {open && <Snackbar />}
      <Formik
        enableReinitialize
        initialValues={{
          firstName: adminDataInformation?.firstName || '',
          familyName: adminDataInformation?.familyName || '',
          email: adminDataInformation?.email || '',
          phone: adminDataInformation?.phone || '',
          role: adminDataInformation?.role || '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .min(1)
            .max(100)
            .required('First name is required'),
          familyName: Yup.string()
            .min(1)
            .max(100)
            .required('Family name is required'),
          email: Yup.string().email().required('Email is required'),
          phone: Yup.string()
            .matches(/^[0-9]+$/, 'Phone number must only contain numbers')
            .min(8, 'Main contact phone number must be at least 8 digits long')
            .required('Phone number is required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setPageLoading(true);
          try {
            AdminId
              ? await handleUpdate({
                  variables: {
                    updateAdminId: AdminId,
                    input: {
                      firstName: values.firstName,
                      familyName: values.familyName,
                      phone: values.phone.toString(),
                      role: values.role,
                      status: adminDataInformation?.status,
                    },
                  },
                })
              : await handleRegister({
                  variables: {
                    input: {
                      firstName: values.firstName,
                      familyName: values.familyName,
                      email: values.email,
                      phone: values.phone.toString(),
                      role: values.role,
                      password: 'superadmin',
                    },
                  },
                });

            setSubmitting(false);
          } catch (err: any) {
            setPageLoading(false);
            dispatch(
              openSnackbar({
                open: true,
                message: err?.message,
                anchorOrigin: { horizontal: 'center' },
                variant: 'alert',
                alert: {
                  color: 'error',
                },
              })
            );

            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          getFieldProps,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <MainCard title={AdminId ? 'Update Admin' : 'Add Admin'}>
                <GridContainer container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter first name"
                      label="First Name"
                      value={values.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.firstName && errors.firstName && (
                      <FormHelperText error id="firstName-error">
                        {firstLetterUppercase(errors.firstName)}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter family name"
                      value={values.familyName}
                      name="familyName"
                      label="Family Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.familyName && errors.familyName && (
                      <FormHelperText error id="familyName-error">
                        {firstLetterUppercase(errors.familyName)}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter email"
                      value={values.email}
                      disabled={adminDataInformation ? true : false}
                      label="Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="email-error">
                        {firstLetterUppercase(errors.email)}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter phone number"
                      value={values.phone}
                      name="phone"
                      type="text"
                      label="Phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.phone && errors.phone && (
                      <FormHelperText error id="phone-error">
                        {firstLetterUppercase(errors.phone)}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="admin-role"
                      label="Permissions Level"
                      select
                      fullWidth
                      {...getFieldProps('role')}
                    >
                      {userRole.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    {errors?.role && (
                      <FormHelperText error id="role-error">
                        {errors?.role}
                      </FormHelperText>
                    )}
                  </Grid>
                </GridContainer>
              </MainCard>
              <Box sx={{ mt: '1rem' }}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save changes
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export const GridContainer = styled(({ ...props }) => <Grid {...props} />)`
  width: 100%;
  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 1440px) {
    width: 40%;
  }
`;
export default AdminAddForm;
