import { AWSUpload } from "./aws-upload";

const filePicker = (
  cb: (value: string, meta?: Record<string, any> | undefined) => void,
  value: string,
  meta: Record<string, any>
) => {
  const awsUpload = new AWSUpload();
  var input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");

  if (input.files) {
    input.onchange = async function () {
      var file = input.files?.[0] as File;

      var reader = new FileReader();

      await awsUpload.uploadFile(file).then((data: any) => {
        if (data) {
          cb(data.location, { title: file.name });
        }
      });

      reader.onload = function () {
        // const imageUrl = URL.createObjectURL(file);
        // cb(imageUrl, { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  }
};

export default filePicker;
