import { AdminAddress } from "types/profile";
import { Certification } from "views/individual-user/types";

export const userRole = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "editor",
    label: "Editor",
  },
];

// review state options
export const status = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "pending",
    label: "Pending",
  },
];

export const EventType = [
  {
    value: "EVENT",
    label: "Event",
  },
  {
    value: "COURSE",
    label: "Course",
  },
  {
    value: "DISCUSSION_GROUP",
    label: "Discussion Group",
  },
];

export const EventTypes = [
  {
    value: "",
    label: "",
  },
  {
    value: "EVENT",
    label: "Event",
  },
  {
    value: "COURSE",
    label: "Course",
  },
  {
    value: "DISCUSSION_GROUP",
    label: "Discussion Group",
  },
];

export const EventStatus = [
  {
    value: "",
    label: "",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
  {
    value: "REMOVED",
    label: "Removed",
  },
  {
    value: "CANCELlED",
    label: "Delete",
  },
];
export const eventTypeMappingObj: any = {
  EVENT: "Event",
  COURSE: "Course",
  DISCUSSION_GROUP: "Discussion Group",
};

export const statusTypeMappingObj: any = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  REMOVED: "Removed",
};

export const statusAttendeesMappingObj: any = {
  RESERVED: "Reserved",
  PAID: "Paid",
};

export type FilterInput = {
  from?: Date | null | string;
  to?: Date | null | string;
};

export type TransactionReportInputDto = {
  limit?: number;
  skip?: number;
  from?: number | null;
  filter?: FilterInput;
  to?: number | null;
};

export type EventDetails = {
  title: string | null;
  startDate?: Date | null;
  eventId: string | null;
};

export type userDetails = {
  familyName: string | null;
  firstName: string | null;
  companyName: string | null;
  companyCode: string | null;
  membershipType: string | null;
  membershipStatus: string | null;
  userId: string | null;
  address: AdminAddress | null;
};

export type TransactionsReport = {
  _id: string;
  snNumber: number;
  transactionId: string;
  paymentDate: Date | null;
  userDetails: userDetails | null;
  eventDetails: EventDetails | null;
  fee: number;
  GST: number;
  totalDue: number;
  creditCardSurcharge: number;
  paidAmount: number;
  description: string;
  receiptEmail: string;
  dueDate: Date;
  paymentMethod: string;
  isRefunded?: boolean | null;
};

export const IndividualUserMembershipStatus = [
  { label: "", value: "" },
  { label: "Valid", value: "VALID" },
  { label: "Awaiting Payment", value: "AWAITING_PAYMENT" },
  { label: "Grace", value: "GRACE" },
  { label: "Resigned", value: "RESIGNED" },
];

export const guestUserMap = {
  true: "Yes",
  false: "No",
};

export const membershipMappingObj = {
  CORPORATE: "Corporate Member",
  INDIVIDUAL: "Individual",
  CORPORATE_PRINCIPAL_MEMBER: "Corporate Principal Member",
  INDIVIDUAL_STUDENT: "Individual Student",
  INDIVIDUAL_RETIRED: "Individual Retired",
};
