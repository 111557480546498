import { gql } from "@apollo/client";

export const GET_ALL_NOTIFICATIONS = gql`
  query GetAllNotifications($input: GetNotificationDTO!) {
    getAllNotifications(input: $input) {
      pagination {
        total
      }
      unreadCount
      message
      data {
        _id
        title
        type
        eventId
        message
        userId
        isRead
        date
        eventId
        userId
      }
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation MarkAsRead($notificationId: String!) {
    markAsRead(notificationId: $notificationId) {
      message
    }
  }
`;

export const MARK_ALL_READ = gql`
  mutation MarkAllRead {
    markAllRead {
      message
    }
  }
`;
