import { gql } from "@apollo/client";

export const GET_ANALYTICS_DATA = gql`
  query ($input: GetGoogleAnalyticsDTO!) {
    GetGoogleAnalyticsData(input: $input) {
      topLandingPage {
        landingPage
        screenPageViews
      }
    }
  }
`;
