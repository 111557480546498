// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack } from '@mui/material';

import { gridSpacing } from 'store/constant';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// ==============================|| PLUGIN - EDITORS ||============================== //

const modules = {
  toolbar: [],
};

const WysiwygEditor = ({
  value,
  onChange,
  disabled = true,
}: {
  value: any;
  onChange: any;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} spacing={gridSpacing}>
      <Stack
        spacing={gridSpacing}
        sx={{
          '& .quill': {
            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
            borderColor: 'black',
            borderRadius: '12px',
            '& .ql-toolbar': {
              bgcolor:
                theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
              borderColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.light + 20
                  : 'primary.light',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
            },
            '& .ql-container': {
              borderColor:
                theme.palette.mode === 'dark'
                  ? `${theme.palette.dark.light + 20} !important`
                  : 'primary.light',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
              '& .ql-editor': {
                minHeight: 150,
              },
            },
          },
        }}
      >
        <ReactQuill
          readOnly={disabled}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          modules={modules}
        />
      </Stack>
    </Grid>
  );
};

export default WysiwygEditor;
