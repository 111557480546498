import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Spinner from "components/spinner";
import lang from "constants/language";
import dayjs from "dayjs";
import { Formik } from "formik";
import useSnackbar from "hooks/common/useSnackbar";
import { useEffect, useState } from "react";
import {
  AddInvoiceInput,
  AddInvoiceInputDto,
  CorporateMembership,
} from "types/event";
import EventDataInformation from "views/event/Event-Information";
import * as Yup from "yup";
import { useGQL } from "../hooks/useGQL";
import { PaymentMethod } from "views/company/company-list/constants/types";
// import { PaymentMethod } from '../company-list/constants/types';
const AddInvoice = ({ open, setOpen, refetchInvoiceInformation }) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      md: 700,
      xs: "90%",
    },
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
  };
  const { handleOpenSnackbar } = useSnackbar();
  const { ADD_INVOICE_DETAIL_OF_INDIVIDUAL } = useGQL();
  const [handleAdd, { data: update, loading }] =
    ADD_INVOICE_DETAIL_OF_INDIVIDUAL();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("UserId") as string;
  useEffect(() => {
    if (update?.createUserInvoicePayment) {
      handleOpenSnackbar({
        message: lang.INDIVIDUAL_USER.PAYMENT_RECORD_ADD,
        alertType: "success",
      });
      setOpen(false);
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }
  const handleAddInvoice = async (
    values: Partial<AddInvoiceInput>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await handleAdd({
        variables: {
          invoicePaymentInput: { ...values, userId },
        },
      });
      refetchInvoiceInformation();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <Formik
            initialValues={{
              invoiceNum: "",
              transactionId: null,
              paymentDate: dayjs().toJSON(),
              paymentMethod: "",
              amount: null,
              description: "",
            }}
            validationSchema={Yup.object().shape({
              invoiceNum: Yup.string()
                .required("Invoice no is required")
                .matches(
                  /^[A-Za-z0-9\-]+$/,
                  "Invoice number can only contain letters, numbers, and hyphens"
                ),

              transactionId: Yup.string().nullable(),

              paymentDate: Yup.date()
                .required("Payment date is required")
                .nullable(),
              paymentMethod: Yup.string().required(
                "Payment method is required"
              ),
              amount: Yup.number()
                .required("Amount is required")
                .min(1)
                .nullable(),

              description: Yup.string().required("Description is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const input = {
                ...values,
              };
              setSubmitting(true);
              handleAddInvoice(input, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              getFieldProps,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    marginBottom: "1.5rem",
                  }}
                >
                  Add Payment Details
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ maxWidth: "697px" }}
                >
                  {" "}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Invoice No"
                      label="Invoice No"
                      {...getFieldProps("invoiceNum")}
                    />
                    {touched.invoiceNum && errors.invoiceNum && (
                      <FormHelperText error id="invoiceNum-error">
                        {errors.invoiceNum}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Transaction Id"
                      label="Transaction Id"
                      {...getFieldProps("transactionId")}
                    />
                    {touched.transactionId && errors.transactionId && (
                      <FormHelperText error id="transactionId-error">
                        {errors.transactionId}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      placeholder="Amount"
                      InputProps={{
                        inputProps: { min: 1, step: 0.01 },
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      label="Amount"
                      {...getFieldProps("amount")}
                    />
                    {touched.amount && errors.amount && (
                      <FormHelperText error id="amount-error">
                        {errors.amount}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label="Payment Date"
                        value={new Date(values.paymentDate)}
                        sx={{ width: "100%" }}
                        onChange={(newValue) => {
                          setFieldValue(
                            "paymentDate",
                            dayjs(newValue).toJSON()
                          );
                        }}
                      />
                    </LocalizationProvider>
                    {touched.paymentDate && errors.paymentDate && (
                      <FormHelperText error id="paymentDate-error">
                        {errors.paymentDate}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      placeholder="Method"
                      label="Method"
                      {...getFieldProps("paymentMethod")}
                    >
                      {PaymentMethod.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {touched.paymentMethod && errors.paymentMethod && (
                      <FormHelperText error id="paymentMethod-error">
                        {errors.paymentMethod}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Description"
                      label="Description"
                      multiline
                      {...getFieldProps("description")}
                    />
                    {touched.description && errors.description && (
                      <FormHelperText error id="description-error">
                        {errors.description}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Box
                  sx={{ marginTop: "2em", display: "flex", gap: "20px" }}
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{ color: "white" }}
                    variant="contained"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Add Payment Record"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </>
    </Modal>
  );
};
export default AddInvoice;
