import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTheme } from "styled-components";
import {
  IndividualUserMembershipStatus,
  guestUserMap,
} from "./constants/types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import {
  AmlCertificates,
  CrmCertificates,
  Industries,
  States,
  country,
  stateMapObject,
} from "store/constant";
import { Stack } from "@mui/system";
import { useLazyQuery } from "@apollo/client";
import {
  GET_MEMBERS_REPORT,
  GET_MEMBERS_REPORT_FOR_EXPORT,
} from "grapqhl/membersReport/query";
import { MembersReportListOutput } from "types/members";
import { membershipMappingObj } from "./tables";
import { IndividualStatusMappingObj } from "views/individual-user/individual-user-list/constants/types";
import exportFromJSON from "export-from-json";
import { useState } from "react";
import moment from "moment";

const FilterMemberListModal = ({
  handleRefetch,
  total,
  setFilterMemberData,
  filterInput,
}) => {
  const theme = useTheme();

  const [exportClicked, setExportClicked] = useState(false);

  const formik = useFormik({
    initialValues: {
      // Define your initial form field values here
      status: "",
      aml: "",
      company: "",
      country: "",
      crm: "",
      endDate: "",
      membershipType: "",
      startDate: "",
      state: "",
      isGuestUser: false,
    },
    onSubmit: (values, { resetForm }) => {
      setFilterMemberData({ ...values });
      handleRefetch();
    },
    enableReinitialize: true,
  });

  const handleResetEvent = () => {
    formik.values.isGuestUser = false;
    formik.resetForm(); // Reset the form fields
  };

  const formatDataForExport = (data: MembersReportListOutput[]) => {
    const membersReportData = data.map((member: any) => {
      return {
        memberNumber: member?.memberNumber
          ? String(member?.memberNumber).padStart(4, "0")
          : "0000",
        firstName: member?.firstName,
        lastName: member?.lastName,
        phone: member?.phone,
        email: member?.email,
        company: member?.company ? member?.company : undefined,
        position: member?.position ? member?.position : undefined,
        industry: Industries.find((i) => i.value === member.industry)?.label,
        crm: member?.certification?.crm,
        aml: member?.certification?.aml,
        flatunit: member?.address?.flatUnit
          ? member?.address?.flatUnit
          : undefined,
        streetNumber: member?.address?.streetNumber,
        streetName: member?.address?.streetName,
        suburb: member?.address?.suburb,
        postcode: member?.address?.postcode,
        state: stateMapObject[member?.address?.state],
        country: member?.address?.country,
        fullAddress: member?.address?.fullAddress,
        membershipType:
          membershipMappingObj[member?.membership?.membershipType],
        membershipStatus:
          IndividualStatusMappingObj[member?.membership?.status],
        code: member?.membership?.code ? member?.membership?.code : undefined,

        isGuestUser: guestUserMap[member?.isGuestUser],

        paymentDate: member?.membership?.paymentDate
          ? moment(member?.membership?.paymentDate).format("DD/MM/YYYY")
          : undefined,
        validUptoDate: member?.membership?.validUptoDate
          ? moment(member?.membership?.validUptoDate).format("DD/MM/YYYY")
          : undefined,
        timeInProfession: member?.timeInProfession
          ? member?.timeInProfession
          : undefined,
        professionSubscribed:
          member?.membership?.status === "VALID" ? "Yes" : "No",
      };
    });
    return membersReportData;
  };
  const [getMemberReport] = useLazyQuery(GET_MEMBERS_REPORT_FOR_EXPORT, {
    variables: {
      getMemberListReportInputForExport: {
        filterInput: {
          status: filterInput?.status === "" ? null : filterInput?.status,
          aml: filterInput?.aml === "" ? null : filterInput?.aml,
          company: filterInput?.company === "" ? null : filterInput?.company,
          country: filterInput?.country === "" ? null : filterInput?.country,
          crm: filterInput?.crm === "" ? null : filterInput?.crm,
          endDate: filterInput?.endDate === "" ? null : filterInput?.endDate,
          membershipType:
            filterInput?.membershipType === ""
              ? null
              : filterInput?.membershipType,
          startDate:
            filterInput?.startDate === "" ? null : filterInput?.startDate,
          state: filterInput?.state === "" ? null : filterInput?.state,
          isGuestUser: filterInput?.isGuestUser,
        },
      },
    },
    fetchPolicy: "network-only",
    onCompleted(data) {
      const updatedData = formatDataForExport(
        data?.generateMemberReportsForExport?.memberReportList
      );
      const dataToExport = {
        data: updatedData,
        fields: {
          memberNumber: "Member Number ",
          firstName: "First Name",
          lastName: "Family Name",
          phone: "Mobile Number",
          email: "Email",
          company: "Company Name",
          position: "Position",
          industry: "Industry",
          crm: "CRM Certification",
          aml: "AML Certification",
          flatunit: "Flat Number",
          streetNumber: "Street Number",
          streetName: "Street Name",
          suburb: "Suburb",
          postcode: "Postcode",
          state: "State",
          country: "Country",
          fullAddress: "Full Address",
          membershipType: "Member Type",
          membershipStatus: "Member Status",
          code: "Corp Code",
          isGuestUser: "Is Guest User ?",
          paymentDate: "Date Joined ACI",
          validUptoDate: "Date Paid To",
          timeInProfession: "Time In Profession (Years)",
          professionSubscribed: "Time in Profession Subscribed",
        },
        fileName: "Members Report",
        exportType: exportFromJSON.types.xls,
      };
      if (exportClicked) {
        exportFromJSON(dataToExport);
      }
      setExportClicked(false);
    },
    onError(error) {
      setExportClicked(false);
      console.log("error", error);
    },
  });

  const Membership_Type = [
    { label: "", value: "" },
    { label: "Corporation Member", value: "CORPORATE" },
    { label: "Individual", value: "INDIVIDUAL" },
    {
      label: "Corporate Principal Member",
      value: "CORPORATE_PRINCIPAL_MEMBER",
    },
    {
      label: "Individual Student",
      value: "INDIVIDUAL_STUDENT",
    },
    {
      label: "Individual Retired",
      value: "INDIVIDUAL_RETIRED",
    },
  ];

  return (
    <>
      {/* Formik form */}
      <form onSubmit={formik.handleSubmit}>
        {/* Form fields */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="company"
              name="company"
              fullWidth
              label="Company"
              value={formik.values.company}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 100, // Maximum character limit, including spaces
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="eventType"
              label="Membership Type"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("membershipType")}
            >
              {Membership_Type.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="status"
              label="Membership Status"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("status")}
            >
              {IndividualUserMembershipStatus.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {/* Add more fields as needed */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="crm"
              label="Compliance Accreditation"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("crm")}
            >
              {CrmCertificates.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="aml"
              label="AML Accreditation"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("aml")}
            >
              {AmlCertificates.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                value={
                  formik.values.startDate
                    ? new Date(formik.values.startDate)
                    : null
                }
                label="Join Start Date"
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "startDate",
                    newValue ? dayjs(newValue).toJSON() : null
                  );
                }}
              />
            </LocalizationProvider>
            {}
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                value={
                  formik.values.endDate ? new Date(formik.values.endDate) : null
                }
                minDate={new Date(formik.values.startDate)}
                disabled={formik.values.startDate ? false : true}
                label="Join End Date"
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "endDate",
                    newValue ? dayjs(newValue).toJSON() : null
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="state"
              label="State"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("state")}
            >
              {States.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="country"
              label="Country"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("country")}
            >
              {country.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <FormControlLabel
                label={
                  <Typography variant="subtitle1">Is guest user</Typography>
                }
                control={
                  <Checkbox
                    color="primary"
                    checked={formik?.values?.isGuestUser}
                    // defaultChecked={formik?.values?.isGuestUser}
                    {...formik.getFieldProps("isGuestUser")}
                  />
                }
              />
            </Stack>
          </Grid>
        </Grid>

        {/* Submit button */}
        <Grid container justifyContent="flex-end">
          <Stack
            direction="row"
            spacing={2}
            alignItems="right"
            sx={{ mt: "1rem" }}
          >
            {" "}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={(e) => {
                setExportClicked(false);
                handleResetEvent();
              }}
            >
              Clear Filter
            </Button>
            <Button
              disabled={exportClicked}
              color="primary"
              onClick={() => {
                setExportClicked(true);
                getMemberReport();
              }}
            >
              Export{" "}
              {exportClicked && (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  sx={{ marginLeft: "0.5rem" }}
                />
              )}
            </Button>
            <Button
              // disabled={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {
                setExportClicked(false);
              }}
            >
              View
            </Button>
          </Stack>
        </Grid>
      </form>
    </>
  );
};
export default FilterMemberListModal;
