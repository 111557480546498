import { gql } from '@apollo/client';

export const UPDATE_EVENT_INFORMATION = gql`
  mutation ($input: UpdateEventInformationDto!, $eventId: String) {
    updateEventInformation(input: $input, eventId: $eventId) {
      _id
      title
      isCompliance
      bannerColor
      eventType
      pageType
      suitableFor
      cpdPoints
      coverImageUrl
      relevantFiles
      openAnytime
      mainLink
      mainLocation
      sessions {
        startDate
        endDate
        startTime
        endTime
        modality
        link
      }
    }
  }
`;

export const PRICING_AND_BOOKING = gql`
  mutation UpdatePricingAndBooking(
    $eventId: String
    $input: UpdatePricingAndBookingDto!
  ) {
    updatePricingAndBooking(eventId: $eventId, input: $input) {
      message
    }
  }
`;

export const UPDATE_FURTHER_DETAILS = gql`
  mutation UpdateFurtherDetails(
    $eventId: String
    $input: UpdateFurtherDeitalsDto!
  ) {
    updateFurtherDetails(eventId: $eventId, input: $input) {
      message
    }
  }
`;

export const GENERATE_SIGNED_URL_FOR_IMAGE = gql`
  mutation GenerateSigned($input: SignedUrlInputType!) {
    generateSignedUrl(input: $input)
  }
`;

export const CANCEL_EVENT_DATA = gql`
  mutation CancelEventInformation($eventId: String!, $status: StatusTypeEnum!) {
    cancelEventInformation(eventId: $eventId, status: $status) {
      message
    }
  }
`;

export const UPDATE_ATTENDEE_STATUS = gql`
  mutation UpdateEventBookingStatus(
    $eventBookingUpdate: UpdateEventBookingStatusDTO!
  ) {
    updateEventBookingStatus(eventBookingUpdate: $eventBookingUpdate) {
      message
    }
  }
`;

export const ADD_ATTENDEE = gql`
  mutation AddAttendee($docId: String!, $input: CreateAttendeeUserInput!) {
    addAttendee(docId: $docId, input: $input) {
      address {
        flatUnit
        streetNumber
        streetName
        postcode
        state
        suburb
        country
      }
      firstName
      lastName
    }
  }
`;
