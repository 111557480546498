/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import useAlertDialog from 'hooks/common/useAlertDialog';
import FailureLoad from 'components/spinner/fail';
import useSnackbar from 'hooks/common/useSnackbar';
import Spinner from 'components/spinner';
import { useGQL } from './hooks/useGQL';
import { PaymentDefaultValue } from '../../constants/profile';
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const PaymentSetting = () => {
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();

  const [surchargeDetail, setSurchargeDetail] =
    useState<any>(PaymentDefaultValue);
  const { GET_SURCHARGE, CREATE_OR_UPDATE_SURCHARGE_VALUES } = useGQL();
  const { error, loading, data, refetch } = GET_SURCHARGE();
  const [handleUpdate, { data: update }] = CREATE_OR_UPDATE_SURCHARGE_VALUES();

  let hasVisa = false;
  let hasMasaterCard = false;
  let hasAmericanExpress = false;

  useEffect(() => {
    if (data?.getSurchargeValue) {
      const { _id, settingType, paymentType } = data.getSurchargeValue;

      if (paymentType.length > 0) {
        const surchrageValueVisa = paymentType?.find(
          (item) => item?.method === 'VISA'
        );
        const surchrageValueMasterCard = paymentType?.find(
          (item) => item?.method === 'MASTERCARD'
        );
        const surchrageValueAmericanExpress = paymentType?.find(
          (item) => item?.method === 'AMERICAN_EXPRESS'
        );
        setSurchargeDetail({
          _id,
          settingType,
          surchrageValueVisa: surchrageValueVisa
            ? surchrageValueVisa.value
            : null,
          surchrageValueMasterCard: surchrageValueMasterCard
            ? surchrageValueMasterCard.value
            : null,
          surchrageValueAmericanExpress: surchrageValueAmericanExpress
            ? surchrageValueAmericanExpress.value
            : null,
        });
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (update?.createAndUpdateSurcharge) {
      handleOpenSnackbar({
        message: 'Surcharge updated successfully',
        alertType: 'success',
      });
      // window.location.reload();
    }
  }, [update]);

  if (loading) {
    <Spinner />;
  }

  const handleProfileUpdate = async (
    values,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await handleUpdate({
        variables: {
          input: { ...values },
        },
      });
      refetch();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: 'error' });
      setSubmitting(false);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : error ? (
        handleOpenSnackbar({ message: error.message, alertType: 'error' })
      ) : (
        <>
          <MainCard>
            <FormWrapper>
              <Formik
                enableReinitialize
                initialValues={surchargeDetail}
                validationSchema={Yup.object().shape({
                  surchrageValueVisa: Yup.number()
                    .lessThan(100)
                    .moreThan(0)
                    .nullable()
                    .label('Visa surcharge value'),
                  surchrageValueMasterCard: Yup.number()
                    .lessThan(100)
                    .moreThan(0)
                    .nullable()
                    .label('MasterCard surcharge value'),
                  surchrageValueAmericanExpress: Yup.number()
                    .lessThan(100)
                    .moreThan(0)
                    .nullable()
                    .label('American Express surcharge value'),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  const isConfirm = await getConfirmation({
                    title: 'Create Surcharge Value',
                    message:
                      'Are you sure you want to create surcharge value ?',
                  });
                  if (isConfirm) {
                    /* make data sync with update userDTO */
                    const input = { ...values };
                    delete input._id;
                    const surchargeInput = {
                      settingType: 'PAYMENT_SETTING',
                      paymentType: [
                        {
                          method: 'VISA',
                          rate: 'flat/percentage',
                          value: input.surchrageValueVisa
                            ? input.surchrageValueVisa
                            : null,
                        },
                        {
                          method: 'MASTERCARD',
                          rate: 'flat/percentage',
                          value: input.surchrageValueMasterCard
                            ? input.surchrageValueMasterCard
                            : null,
                        },
                        {
                          method: 'AMERICAN_EXPRESS',
                          rate: 'flat/percentage',
                          value: input.surchrageValueAmericanExpress
                            ? input.surchrageValueAmericanExpress
                            : null,
                        },
                      ],
                    };
                    setSubmitting(true);
                    handleProfileUpdate(surchargeInput, setSubmitting);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                      <Typography variant="h3">
                        Credit Card Surcharge Settings
                      </Typography>
                    </Grid>
                    <Grid sx={{ marginTop: '15px' }} container spacing={3}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="outline-adornment-full-name">
                          Visa Surcharge
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          placeholder="Enter Surcharge % For Visa"
                          value={values.surchrageValueVisa}
                          name="surchrageValueVisa"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100, step: 0.01 },
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {touched.surchrageValueVisa &&
                          errors.surchrageValueVisa && (
                            <FormHelperText error id="surchrageValueVisa-error">
                              {errors.surchrageValueVisa}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="outline-adornment-full-name">
                          MasterCard Surcharge
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          placeholder="Enter Surcharge % For Master Card"
                          value={values.surchrageValueMasterCard}
                          name="surchrageValueMasterCard"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100, step: 0.01 },
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {touched.surchrageValueMasterCard &&
                          errors.surchrageValueMasterCard && (
                            <FormHelperText
                              error
                              id="surchrageValueMasterCard-error"
                            >
                              {errors.surchrageValueMasterCard}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="outline-adornment-full-name">
                          American Express Surcharge
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          placeholder="Enter Surcharge % For AmericanExpress"
                          value={values.surchrageValueAmericanExpress}
                          name="surchrageValueAmericanExpress"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100, step: 0.01 },
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {touched.surchrageValueAmericanExpress &&
                          errors.surchrageValueAmericanExpress && (
                            <FormHelperText
                              error
                              id="surchrageValueAmericanExpress-error"
                            >
                              {errors.surchrageValueAmericanExpress}
                            </FormHelperText>
                          )}
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: '1rem' }}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                      >
                        Save Changes
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </FormWrapper>
          </MainCard>
        </>
      )}
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default PaymentSetting;
