import { MemberTypesEnum } from "enums/memberTypes.enums";
import { membershipMappingObj } from "views/transactions-report/transactions-report-list/constants/types";

export function getMembershipDetails(transaction) {
    const membershipType = transaction?.userDetails?.membershipType
      ? membershipMappingObj[transaction.userDetails.membershipType]
      : "-";
  
    const description = 
      transaction?.userDetails?.membershipType === MemberTypesEnum.INDIVIDUAL && !transaction?.description
        ? "Individual Membership"
        : transaction?.description;
  
    return { membershipType, description };
  }
  