/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  CardContent,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  TextField,
  Box,
} from '@mui/material';

/* gql hooks */

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { RootState, useDispatch, useSelector } from 'store';

/* variables imports */

import { useGQL } from 'views/event/hooks/useGQL';
import { AttendiesList } from 'types/event';
import { setPage, setRowsPerPage, setSearchText } from 'store/slices/table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttendiesTable from './tables';
import { headCells } from '../list/constants/variables';
import moment from 'moment';

import SearchIcon from '@mui/icons-material/Search';
import PopModalUserAdd from './pop-modal-user-add';
import useDebouncedSearch from './hooks/useDebounceSearch';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ATTENDIES_USER } from 'grapqhl/event/query';
import exportFromJSON from 'export-from-json';
import { Industries, stateMapObject } from 'store/constant';
import { statusAttendeesMappingObj } from 'views/event/event-list/constants/types';
import { IndividualStatusMappingObj } from 'views/individual-user/individual-user-list/constants/types';
import { membershipMappingObj } from 'views/members-report/members-report-list/tables';
import { guestUserMap } from 'views/members-report/members-report-list/constants/types';

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];

interface CsvHeaderProps {
  label: string;
  key: string;
}

// ==============================|| CUSTOMER LIST ||============================== //
const Attendies = ({ refetchEvent, eventDetails }) => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const eventId = params.get('eventId') as string;
  const [meta, setMeta] = useState<Meta>();
  const [searchTextInput, setSearchTextInput] = useState<string>('');
  const [disableEmail, setDisableEmail] = useState(false);
  const { searchText, page, rowsPerPage, sort } = useSelector(
    (state: RootState) => state.table
  );

  const [total, setTotal] = useState(0);

  const [debouncedSearch] = useDebouncedSearch((event: any | undefined) => {
    if (event?.target) {
      setSearchTextInput(event.target.value);
    }
  });

  type Meta = { limit: number; skip: number };
  type Sort = { order: string; orderBy: string } | null;

  /* queries & mutations */
  const { GET_ALL_ATTENDIES_LIST } = useGQL();
  const { error, loading, data, refetch } = GET_ALL_ATTENDIES_LIST(eventId, {});

  /* local states */
  const [attendiesInformation, setAttendiesInformation] = useState<
    AttendiesList[]
  >([]);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data?.getAllAttendiesList?.attendies) {
      setAttendiesInformation(data?.getAllAttendiesList?.attendies);

      setTotal(data?.getAllAttendiesList?.pagination?.total);
    }
  }, [data]);
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      input: {
        limit,
        skip,
        order: sort.order,
        orderBy: sort.orderBy,
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    refetch({
      input: {
        limit: meta?.limit,
        skip: meta?.skip,
        order: sort.order,
        orderBy: sort.orderBy,
        searchText: searchTextInput,
      },
    });
  };

  useEffect(() => {
    dispatch(setPage(0));
    handleRefetch();
  }, [searchTextInput,rowsPerPage]);

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
    setDisableEmail(false);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  const handleChangePage = (event, newPage: number) => {
    dispatch(setPage(newPage - 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    }
  };

  const formatDataForExport = (data: AttendiesList[]) => {
    const attendiesData = data.map((item) => {
      return {
        memberNumber: item?.memberNumber
          ? String(item?.memberNumber).padStart(4, '0')
          : '0000',
        firstName: item?.firstName,
        lastName: item?.lastName,
        phone: item?.phone,
        email: item?.email,
        company: item?.company ? item?.company : undefined,
        position: item?.position ? item?.position : undefined,
        industry: Industries.find((i) => i.value === item.industry)?.label,
        crm: item?.certification?.crm,
        aml: item?.certification?.aml,
        flatunit: item?.address?.flatUnit ? item?.address?.flatUnit : undefined,
        streetNumber: item?.address?.streetNumber,
        streetName: item?.address?.streetName,
        suburb: item?.address?.suburb,
        postcode: item?.address?.postcode,
        state: stateMapObject[item?.address?.state],
        country: item?.address?.country,
        note: item?.note ? item?.note : undefined,

        membershipType: membershipMappingObj[item?.membership?.membershipType],
        membershipStatus: IndividualStatusMappingObj[item?.membership?.status],
        code: item?.membership?.code ? item?.membership?.code : undefined,

        isGuestUser: item?.isGuestUser === true ? 'Yes' : 'No',
        paymentDate: item?.membership?.paymentDate
          ? moment(item?.membership?.paymentDate).format('DD/MM/YYYY')
          : undefined,
        validUptoDate: item?.membership?.validUptoDate
          ? moment(item?.membership?.validUptoDate).format('DD/MM/YYYY')
          : undefined,

        timeInProfession: item?.timeInProfession
          ? item?.timeInProfession
          : undefined,
        professionSubscribed:
          item?.membership?.status === 'VALID' ? 'Yes' : 'No',

        date: moment(item?.createdAt).format('DD/MM/YYYY'),

        event: item?.event,
        status: statusAttendeesMappingObj[item?.status],
        paidAmount: item?.paidAmt || '-',
      };
    });
    return attendiesData;
  };

  const [getAttendiesList] = useLazyQuery(GET_ALL_ATTENDIES_USER, {
    variables: {
      input: {
        limit: total,
        skip: 0,
        order: sort.order,
        orderBy: sort.orderBy,
        searchText: searchTextInput,
      },
      docId: eventId,
    },
    fetchPolicy: 'network-only',

    onCompleted(data) {
      const attendiesData: AttendiesList[] = [
        ...data.getAllAttendiesList.attendies,
      ];

      const formattedData = formatDataForExport(attendiesData);

      const dataToExport = {
        data: formattedData,
        fields: {
          memberNumber: 'Membership No ',
          firstName: 'First Name',
          lastName: 'Family Name',
          phone: 'Mobile Number',
          email: 'Email',
          company: 'Company Name',
          position: 'Position',
          industry: 'Industry',
          crm: 'CRM Certification',
          aml: 'AML Certification',
          flatunit: 'Flat Number',
          streetNumber: 'Street Number',
          streetName: 'Street Name',
          suburb: 'Suburb',
          postcode: 'Postcode',
          state: 'State',
          country: 'Country',
          note: 'Note',
          membershipType: 'Membership Type',
          membershipStatus: 'Membership Status',
          code: 'Corp Code',
          isGuestUser: 'Is Guest User ?',
          paymentDate: 'Date Joined ACI',
          validUptoDate: 'Date Paid To',
          timeInProfession: 'Time In Profession (Years)',
          professionSubscribed: 'Time in Profession Subscribed',
          date: 'Registration Date',
          event: 'Event Name',
          status: 'Payment Status',
          paidAmount: 'Paid Amount',
        },
        fileName: `${eventDetails?.title}_${
          eventDetails?.sessions?.length &&
          moment(eventDetails?.sessions[0]?.startDate).format('DD/MM/YY')
        }`,
        exportType: exportFromJSON.types.xls,
      };
      exportFromJSON(dataToExport);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          sx={{
            marginBottom: 2,
            marginTop: 2,
            marginRight: 2,
          }}
          onClick={() => getAttendiesList()}
        >
          Export Attendees
        </Button>
      </Box>
      <MainCard content={false} sx={{ position: 'relative' }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'center' }}
          >
            <Grid item xs={12} sm={9}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 100, // Maximum character limit, including spaces
                }}
                fullWidth
                onChange={debouncedSearch}
                placeholder="Search user"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                sx={{ width: '100%' }}
                variant="outlined"
                onClick={(e) => {
                  setOpen(true);
                }}
              >
                Add User
              </Button>
            </Grid>
          </Grid>
          <PopModalUserAdd
            open={open}
            setOpen={setOpen}
            refetch={refetch}
            setDisableEmail={setDisableEmail}
            disableEmail={disableEmail}
            totalAttendee={data?.getAllAttendiesList?.pagination?.total}
          />
          <>
            <AttendiesTable
              {...{ attendiesInformation, refetch, loading, eventId }}
            />
            {/* table pagination */}
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getAllAttendiesList?.pagination?.total / rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        </CardContent>
      </MainCard>
    </>
  );
};

export default Attendies;
