// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import LocalizationSection from "./LocalizationSection";
import MegaMenuSection from "./MegaMenuSection";
import NotificationSection from "./NotificationSection";
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";

// assets
import { IconMenu2 } from "@tabler/icons";
import Grouped from "./Autocomplete";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box component="span" sx={{ display: { xs: "none", md: "block" } }}>
          <LogoSection />
        </Box>
        <Avatar
          variant="rounded"
          style={{ width: "25px", height: "25px" }}
          sx={{
            top: "16px",
            "@media (min-width: 900px)": {
              left: "50px",
            },
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: "hidden",
            transition: "all .2s ease-in-out",
            background:
              theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : "#004C8F",
            color:
              theme.palette.mode === "dark"
                ? theme.palette.secondary.main
                : "#fff",
            "&:hover": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : "#004C8F",
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.light,
            },
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="1rem" />
        </Avatar>
      </Box>

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Grouped />
      <NotificationSection />
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: "none" }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
