/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import styled from 'styled-components';

import useSnackbar from 'hooks/common/useSnackbar';
import Spinner from 'components/spinner';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { termArray } from 'store/constant';

// assets
import useAuth from 'hooks/useAuth';

import { Stack } from '@mui/system';
import TinyMCE from 'components/editors/TinyMCE';
import CustomTinyMCE from 'components/editors/CustomTinyMCE';
import lang from 'constants/language';
import { furtherDetailValidation } from 'views/event/further-detail/further-detail-validation-schema';
import MainCard from 'ui-component/cards/MainCard';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { Navigate, useNavigate } from 'react-router-dom';
import { membershipValidation } from './membership-validation';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import {
  ListMembershipsResponse,
  MembershipType,
  MembershipsResponseState,
} from './membership-list/constants/types';
import { useGQL } from './hooks/useGQL';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const AddMembership = () => {
  const { handleOpenSnackbar } = useSnackbar();
  const [speakers, setSpeakers] = useState<any>([]);
  const { UPDATE_MEMBERSHIP, GET_MEMBERSHIP_INFORMATION } = useGQL();
  const currentDate = new Date();

  const params = new URLSearchParams(window.location.search);
  const membershipId = params.get('membershipId') as string;
  const navigate = useNavigate();

  const [memershipInfo, setMembershipInfo] = useState<MembershipsResponseState>(
    {
      name: '',
      startDate: new Date(),
      endDate: new Date(),
      membershipType: '',
      membershipFee: 0,
      membershipFeeIncGST: 0,
      joiningFee: 0,
      joiningFeeIncGST: 0,
      corporateFees: [
        {
          seats: '',
          unitPrice: 0,
        },
      ],
      createdBy: '',
      membershipStatus: '',
    }
  );

  const {
    error: memershipError,
    loading: memershipLoading,
    data: membershipInformation,
    refetch,
  } = GET_MEMBERSHIP_INFORMATION(membershipId);

  useEffect(() => {
    setMembershipInfo(membershipInformation?.getMemberInformation?.membership);
  }, [membershipInformation]);

  const [handleUpdate, { data: update }] = UPDATE_MEMBERSHIP();

  useEffect(() => {
    if (update?.updateMembership) {
      handleOpenSnackbar({
        message: membershipId ? lang.MEMBERSHIP.UPDATE : lang.MEMBERSHIP.CREATE,
        alertType: 'success',
      });
    }
  }, [update]);

  const handleUpdateMembership = async (
    values,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const input = { ...values };

      input.joiningFeeIncGST = parseFloat(
        parseFloat(input.joiningFeeIncGST).toFixed(2)
      );
      input.membershipFeeIncGST = parseFloat(input.membershipFeeIncGST);
      delete input.gstMembershipFee;
      delete input.gstJoiningFee;
      values?.membershipType === 'CORPORATE' && delete input.joiningFee;
      values?.membershipType === 'CORPORATE' && delete input.joiningFeeIncGST;
      values?.membershipType === 'CORPORATE' && delete input.membershipFee;
      values?.membershipType === 'CORPORATE' &&
        delete input.membershipFeeIncGST;

      await handleUpdate({
        variables: {
          input: { ...input },
        },
      });
      refetch();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: 'error' });
      setSubmitting(false);
    }
  };

  const initial = {
    name: memershipInfo?.name,
    membershipType: memershipInfo?.membershipType || '',
    startDate: memershipInfo?.startDate,
    endDate: memershipInfo?.endDate,
    joiningFee: memershipInfo?.joiningFee || 0,
    gstJoiningFee: 10,
    joiningFeeIncGST: memershipInfo?.joiningFeeIncGST?.toFixed(2) || 0,
    membershipFee: memershipInfo?.membershipFee || 0,
    gstMembershipFee: 10,
    membershipFeeIncGST: memershipInfo?.membershipFeeIncGST?.toFixed(2) || 0,
    corporateFees: memershipInfo?.corporateFees || [
      {
        seats: '',
        unitPrice: 0,
      },
    ],
  };

  return (
    <>
      <MainCard>
        <FormWrapper>
          <Formik
            enableReinitialize
            initialValues={initial}
            validationSchema={membershipValidation}
            onSubmit={async (values, { setSubmitting }) => {
              const input = {
                ...values,
                _id: membershipId ? membershipId : null,
                corporateFees:
                  values?.membershipType === 'INDIVIDUAL'
                    ? null
                    : values?.corporateFees,
              };

              setSubmitting(true);
              handleUpdateMembership(input, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid item xs={8} container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Add New Subscription</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton
                      type="button"
                      sx={{ color: '#004C8F', marginTop: '1rem' }}
                      onClick={(e) => navigate('/membership/list')}
                    >
                      <ArrowBackTwoToneIcon />
                      <Typography>Back</Typography>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Name"
                      label="Name"
                      {...getFieldProps('name')}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="name-error">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label="Start Date"
                        value={
                          values.startDate ? new Date(values.startDate) : null
                        }
                        sx={{ width: '100%' }}
                        onChange={(newValue) => {
                          const startDate = dayjs(newValue).toJSON();
                          setFieldValue('startDate', startDate);
                          setFieldValue('endDate', null);
                        }}
                      />
                    </LocalizationProvider>

                    {touched.startDate && errors.startDate && (
                      <FormHelperText error id="email-error">
                        {errors?.startDate}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label="End Date"
                        disabled={!values?.startDate}
                        minDate={
                          values?.startDate
                            ? new Date(
                                new Date(values.startDate).getTime() +
                                  24 * 60 * 60 * 1000
                              )
                            : null
                        }
                        value={values.endDate ? new Date(values.endDate) : null}
                        sx={{ width: '100%' }}
                        onChange={(newValue) => {
                          const endDate = dayjs(newValue).toJSON();
                          setFieldValue('endDate', endDate);
                        }}
                      />
                    </LocalizationProvider>
                    {(errors.startDate || values?.endDate === null) &&
                      touched?.startDate && (
                        <FormHelperText error id="email-error">
                          {errors?.endDate}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="membershipType"
                      label="Membership Type"
                      select
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('membershipType')}
                    >
                      {MembershipType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    {touched?.membershipType && errors?.membershipType && (
                      <FormHelperText error id="membershipType-error">
                        {errors?.membershipType}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                {values?.membershipType && (
                  <Grid marginTop={'1em'} item xs={8} container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h2">Fee Structure</Typography>
                    </Grid>
                    {values?.membershipType === 'INDIVIDUAL' && (
                      <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h4">Joining Fee</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Joining Fee</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              placeholder="Joining Fee"
                              label="Joining Fee"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0, step: 0.01, max: 100 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              {...getFieldProps('joiningFee')}
                              onChange={(fee: any) => {
                                handleChange(fee);
                                const parsedFee = parseFloat(fee?.target.value);
                                const calculatedAmount =
                                  parsedFee +
                                  parsedFee * (values?.gstJoiningFee / 100);

                                setFieldValue(
                                  'joiningFeeIncGST',
                                  calculatedAmount.toFixed(2)
                                );
                              }}
                            />
                            {touched.joiningFee && errors.joiningFee && (
                              <FormHelperText error id="joiningFee-error">
                                {errors.joiningFee}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>GST Joining Fee</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              placeholder="GST Joining Fee"
                              type="number"
                              disabled
                              InputProps={{
                                inputProps: { min: 0, step: 0.01, max: 100 },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              label="GST Joining Fee"
                              {...getFieldProps('gstJoiningFee')}
                              onChange={(fee: any) => {
                                handleChange(fee);
                                setFieldValue(
                                  'joiningFeeIncGST',
                                  (
                                    parseFloat(fee?.target.value) +
                                    values?.joiningFee
                                  ).toFixed(2)
                                );
                              }}
                            />
                            {touched.gstJoiningFee && errors.gstJoiningFee && (
                              <FormHelperText error id="gstJoiningFee-error">
                                {errors.gstJoiningFee}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Joining Fee Inc GST</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              placeholder="Joining Fee Inc GST"
                              label="Joining Fee Inc GST"
                              disabled
                              type="number"
                              InputProps={{
                                inputProps: { min: 0, step: 0.01 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              {...getFieldProps('joiningFeeIncGST')}
                            />
                            {touched.joiningFeeIncGST &&
                              errors.joiningFeeIncGST && (
                                <FormHelperText
                                  error
                                  id="joiningFeeIncGST-error"
                                >
                                  {errors.joiningFeeIncGST}
                                </FormHelperText>
                              )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h4">Membership Fee</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Membership Fee</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              placeholder="Membership Fee"
                              label="Membership Fee"
                              type="number"
                              {...getFieldProps('membershipFee')}
                              InputProps={{
                                inputProps: { min: 0, step: 0.01 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(fee: any) => {
                                handleChange(fee);
                                const parsedFee = parseFloat(fee?.target.value);
                                const calculatedAmount =
                                  parsedFee +
                                  parsedFee * (values?.gstMembershipFee / 100);

                                setFieldValue(
                                  'membershipFeeIncGST',
                                  calculatedAmount.toFixed(2)
                                );
                              }}
                            />
                            {touched.membershipFee && errors.membershipFee && (
                              <FormHelperText error id="membershipFee-error">
                                {errors.membershipFee}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>GST Membership Fee</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              placeholder="GST Membership Fee"
                              label="GST Membership Fee"
                              InputProps={{
                                inputProps: { min: 0, step: 0.01, max: 100 },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              disabled
                              {...getFieldProps('gstMembershipFee')}
                            />
                            {touched.gstMembershipFee &&
                              errors.gstMembershipFee && (
                                <FormHelperText
                                  error
                                  id="gstMembershipFee-error"
                                >
                                  {errors.gstMembershipFee}
                                </FormHelperText>
                              )}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Membership Fee Inc GST</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              placeholder="Membership Fee Inc GST"
                              label="Membership Fee Inc GST"
                              InputProps={{
                                inputProps: { min: 0, step: 0.01 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              {...getFieldProps('membershipFeeIncGST')}
                              disabled
                            />
                            {touched.membershipFeeIncGST &&
                              errors.membershipFeeIncGST && (
                                <FormHelperText
                                  error
                                  id="membershipFeeIncGST-error"
                                >
                                  {errors.membershipFeeIncGST}
                                </FormHelperText>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {values?.membershipType === 'CORPORATE' && (
                      <Grid item xs={12} container spacing={3}>
                        <Grid sx={{ marginBottom: '-1.6rem' }} item xs={12}>
                          <Typography variant="h4">Corporate</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FieldArray
                            name="corporateFees"
                            render={(arrayHelpers) => (
                              <>
                                {values?.corporateFees.map((session, index) => (
                                  <div key={index}>
                                    <Grid
                                      sx={{ marginTop: '5px' }}
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item lg={5.5}>
                                        <TextField
                                          id={`corporateFees.${index}.seats`}
                                          label={'Seats'}
                                          placeholder="00-00"
                                          fullWidth
                                          {...getFieldProps(
                                            `corporateFees.${index}.seats`
                                          )}
                                        />
                                        <FormHelperText error id="title-error">
                                          <ErrorMessage
                                            name={`corporateFees.${index}.seats`}
                                          />
                                        </FormHelperText>
                                      </Grid>
                                      <Grid item lg={5.5}>
                                        <TextField
                                          id={`corporateFees.${index}.unitPrice`}
                                          label={'Price Per Seat'}
                                          fullWidth
                                          InputProps={{
                                            inputProps: { min: 0 },
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                          type="number"
                                          {...getFieldProps(
                                            `corporateFees.${index}.unitPrice`
                                          )}
                                        />
                                        <FormHelperText error id="title-error">
                                          <ErrorMessage
                                            name={`corporateFees.${index}.unitPrice`}
                                          />
                                        </FormHelperText>
                                      </Grid>
                                      <Grid item lg={1}>
                                        <IconButton
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          sx={{
                                            color: 'red',
                                            marginLeft: '1.5rem',
                                          }}
                                        >
                                          <CancelTwoToneIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                                <Grid item xs={12}>
                                  <IconButton
                                    type="button"
                                    sx={{ color: '#004C8F', marginTop: '1rem' }}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        seats: '',
                                        unitPrice: 0,
                                      })
                                    }
                                  >
                                    <AddCircleTwoToneIcon />
                                    <Typography>Add New Rule</Typography>
                                  </IconButton>
                                </Grid>
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}

                <Grid container justifyContent="flex-end">
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="right"
                    sx={{ mt: '1rem' }}
                  >
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      sx={{ color: 'white' }}
                    >
                      {isSubmitting ? 'Please wait...' : 'Submit'}
                    </Button>
                  </Stack>
                </Grid>
              </form>
            )}
          </Formik>
        </FormWrapper>
      </MainCard>
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default AddMembership;
