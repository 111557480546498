/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import moment from 'moment';

import {
  MembersReport,
  eventTypeMappingObj,
  statusTypeMappingObj,
} from '../constants/types';

import { headCells } from '../constants/variables';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSort } from 'store/slices/table';

import { IndividualStatusMappingObj } from 'views/individual-user/individual-user-list/constants/types';
import TableErrorMessage from 'common/tableErrorMessage';

type Sort = { order: string; orderBy: string } | null;
type MembersReportTable = {
  membersReport: MembersReport[];
};
export const membershipMappingObj = {
  CORPORATE: 'Corporate Member',
  INDIVIDUAL: 'Individual',
  CORPORATE_PRINCIPAL_MEMBER: 'Corporate Principal Member',
  INDIVIDUAL_STUDENT: 'Individual Student',
  INDIVIDUAL_RETIRED: 'Individual Retired',
};

type Order = 'asc' | 'desc';
const MembersReportListTable = ({ membersReport }: MembersReportTable) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const setOrder = isAsc ? 'desc' : 'asc';
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === 'action') {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {membersReport.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              membersReport.map((member) => (
                <TableRow key={member._id}>
                  <TableCell align="left">
                    {member?.memberNumber
                      ? String(member?.memberNumber).padStart(4, '0')
                      : '0000'}
                  </TableCell>
                  <TableCell align="left">{member?.firstName}</TableCell>
                  <TableCell align="left">{member?.lastName}</TableCell>
                  <TableCell align="left">{member?.address?.fullAddress}</TableCell>

                  <TableCell align="left">
                    {member?.membership &&
                      (member?.membership.status === 'VALID' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              member?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="success"
                        />
                      ) : member?.membership.status === 'RESIGNED' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              member?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="error"
                        />
                      ) : member?.membership.status === 'AWAITING_PAYMENT' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              member?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="warning"
                        />
                      ) : member?.membership.status === 'GRACE' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              member?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="secondary"
                        />
                      ) : member?.membership.status === 'CORPORATE' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              member?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="primary"
                        />
                      ) : null)}
                  </TableCell>
                  <TableCell align="left">
                    {member?.membership?.membershipType &&
                      membershipMappingObj[member?.membership?.membershipType]}
                  </TableCell>
                  <TableCell align="left">{member?.position}</TableCell>
                  <TableCell align="left">{member?.company}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MembersReportListTable;
