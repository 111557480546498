import dayjs from 'dayjs';
import React from 'react';

export const formatTime = (session) => {
  const currentDateForStartTime = dayjs(session?.startTime, 'h:mm A').toDate();
  const currentDateForEndTime = dayjs(session?.endTime, 'h:mm A').toDate();

  return {
    formattedStartTime: currentDateForStartTime,
    formattedEndTime: currentDateForEndTime,
  };
};
