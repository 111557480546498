/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  TablePagination,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* gql hooks */

// project imports
import MainCard from "ui-component/cards/MainCard";
import { RootState, useDispatch, useSelector } from "store";
import { setPage, setRowsPerPage, setSearchText } from "store/slices/table";

/* custom hooks */
import { useDialog } from "./hooks/useDialogs";
import _debounce from "lodash.debounce";

// assets

/* variables imports */
import Spinner from "components/spinner";

import InvoiceTable from "./tables";
import AddInvoice from "../add-invoice";
import { IndividualUserInvoiceList } from "views/individual-user/types";
import { useGQL } from "views/individual-user/hooks/useGQL";

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];

// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const InvoiceList = () => {
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("UserId") as string;

  const dispatch = useDispatch();

  /* queries & mutations */
  const { GET_INDIVIDUAL_INVOICE_HISTORY } = useGQL();
  const { error, loading, data, refetch } = GET_INDIVIDUAL_INVOICE_HISTORY({
    userId,
  });

  const { searchText, page, rowsPerPage, sort } = useSelector(
    (state: RootState) => state.table
  );

  /* local states */
  const [invoiceList, setInvoiceList] = useState<IndividualUserInvoiceList[]>(
    []
  );
  const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();
  const [meta, setMeta] = useState<Meta>();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data?.getAllUserInvoicePayments?.paymentList) {
      setInvoiceList(data?.getAllUserInvoicePayments?.paymentList);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;

    setMeta({ limit, skip });
    refetch({
      getInvoicePaymentInput: {
        limit,
        skip,
        userId,
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    refetch({
      getInvoicePaymentInput: {
        limit: meta?.limit,
        skip,
        userId,
      },
    });
  };

  useEffect(() => {
    dispatch(setPage(0));
    handleRefetch();
  }, []);

  useEffect(() => {
    setPage(0);
    handleRefetch();
  }, [rowsPerPage]);

  const [anchor, setAnchor] = useState(null);

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  const handleChangePage = (event, newPage: number) => {
    dispatch(setPage(newPage - 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    }
  };

  const handleEdit = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => {
    // const selection = companyUsers.find((event) => event._id === id);
    // if (selection) {
    //   // window.location.reload();
    //   navigate(`/company/add?CompanyId=${selection._id}`);
    // }
  };

  return (
    <>
      <MainCard
        title="Payment History"
        content={false}
        sx={{ position: "relative" }}
      >
        <Button
          variant="outlined"
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
          }}
          onClick={(e) => {
            setOpen(true);
          }}
        >
          Add Payment Record
        </Button>

        {loading ? (
          <Spinner />
        ) : (
          <>
            <InvoiceTable {...{ invoiceList, handleEdit, handleRefetch }} />
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getAllUserInvoicePayments?.pagination?.total /
                        rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
      <AddInvoice
        open={open}
        setOpen={setOpen}
        refetchInvoiceInformation={handleRefetch}
      />
    </>
  );
};

export default InvoiceList;
