import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const makeFirstLetterUpperCaseOfEachWord = (data: string) => {
  if (data && data !== undefined) {
    const formattedData = data
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formattedData ? formattedData : null;
  } else {
    return;
  }
};
export default makeFirstLetterUpperCaseOfEachWord;
