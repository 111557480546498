enum BannerColor {
  GRCI_PURPLE = 'GRCI_PURPLE',
  GRCI_YELLOW = 'GRCI_YELLOW',
  GRCI_GRAY = 'GRCI_GRAY',
  GRCI_HELIOTROPE = 'GRCI_HELIOTROPE',
  GRCI_BLUE = 'GRCI_BLUE',
  GRCI_RED_ORANGE = 'GRCI_RED_ORANGE',
  GRCI_ORANGE = 'GRCI_ORANGE',
  EMPTY = '',
}

export default BannerColor;

export enum EventType {
  EVENT = 'EVENT',
  COURSE = 'COURSE',
  DISCUSSION_GROUP = 'DISCUSSION_GROUP',
}

export enum PageType {
  TYPE_1 = 'TYPE_1',
  TYPE_2 = 'TYPE_2',
}

export enum EventModality {
  ONLINE = 'ONLINE',
  FACE_TO_FACE = 'FACE_TO_FACE',
  DISTANCE = 'DISTANCE',
  HYBRID = 'HYBRID',
}
export enum StatusType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
