/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Chip from "ui-component/extended/Chip";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { eventTypeMappingObj, statusTypeMappingObj } from "../constants/types";

import { headCells } from "../constants/variables";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "../hooks/useSnackbar";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Spinner from "components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";
import { getAdminListType } from "types/event";
import { useGQL } from "views/event/hooks/useGQL";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_EVENT_INFORMATION } from "grapqhl/event/query";
import lang from "constants/language";
import { CANCEL_EVENT_DATA } from "grapqhl/event/mutation";
import { useNavigate } from "react-router-dom";
import TableErrorMessage from "common/tableErrorMessage";

type Sort = { order: string; orderBy: string } | null;
type EventListTableProps = {
  events: getAdminListType[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
  order?: any;
  orderBy: any;
  setOrder: any;
  setOrderBy: any;
};

type Order = "asc" | "desc";
const EventTable = ({
  events,
  handleEdit,
  handleRefetch,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}: EventListTableProps) => {
  const dispatch = useDispatch();

  const { getConfirmation } = useAlertDialog();
  const { handleOpenSnackbar } = useSnackbar();

  const { UPDATE_EVENT_INFORMATIONS } = useGQL();
  const [handleUpdate, { data: update, loading, error }] =
    UPDATE_EVENT_INFORMATIONS();

  const [getEventInfo] = useLazyQuery(GET_EVENT_INFORMATION);
  const [cancelEventMutation] = useMutation(CANCEL_EVENT_DATA);
  const navigate = useNavigate();
  useEffect(() => {
    handleRefetch();
  }, [order, orderBy]);

  const handleEventInformationUpdate = async (
    event: React.MouseEvent,
    id: string,
    status: string
  ) => {
    const isConfirm = await getConfirmation({
      title: `${status === "INACTIVE" ? "Inactive" : "Active"} Event`,
      message: `Are you sure you want to ${
        status === "INACTIVE" ? "inactive" : "active"
      } event ?`,
    });
    if (isConfirm) {
      const { data } = await getEventInfo({
        variables: { docId: id },
      });
      const input = {
        ...data?.getEvent,
      };
      delete input.booking;
      delete input.speaker;
      delete input.pricing;
      delete input.furtherDetails;
      delete input._id;

      input.status = `${status === "INACTIVE" ? "INACTIVE" : "ACTIVE"}`;
      input.openAnytime = input.bookingOpenAnytime;
      delete input.bookingOpenAnytime;
      try {
        await handleUpdate({
          variables: { eventId: id, input: { ...input } },
        });
        handleOpenSnackbar({
          message: `Event ${
            status === "INACTIVE" ? "inactivated" : "activated"
          } successfully`,
          alertType: "success",
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: "error" });
      }
    }
  };

  const handleCancelEvent = async (eventId, status) => {
    const isConfirm = await getConfirmation({
      title: "Remove Event",
      message: "Are you sure you want to remove event ?",
    });
    if (isConfirm) {
      try {
        const { data } = await cancelEventMutation({
          variables: {
            eventId,
            status,
          },
        });
        handleRefetch();
        handleOpenSnackbar({
          message: lang.EVENT.EVENT_REMOVED,
          alertType: "success",
        });
        navigate("/event/list");
      } catch (error: any) {
        handleOpenSnackbar({
          message: error.message,
          alertType: "error",
        });
      }
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const ActionButton = ({ eventId, status, event }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState("");

    const handleMenuActionClick = (event: any, companyUserId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(companyUserId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };

    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, eventId)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={(e) => handleEdit(e, selected)}>
            {" "}
            Edit Event
          </MenuItem>
          {status === "ACTIVE" && (
            <MenuItem
              onClick={(e) =>
                handleEventInformationUpdate(e, selected, "INACTIVE")
              }
            >
              {" "}
              Inactivate Event
            </MenuItem>
          )}
          {status === "INACTIVE" && event?.furtherDetails !== null && (
            <MenuItem
              onClick={(e) =>
                handleEventInformationUpdate(e, selected, "ACTIVE")
              }
            >
              {" "}
              Activate Event
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === "action") {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {events.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              events.map((event) => (
                <TableRow key={event._id}>
                  <TableCell sx={{ width: "17rem" }} align="left">
                    {event?.title}
                  </TableCell>
                  <TableCell align="left">
                    {eventTypeMappingObj[event?.eventType]}
                  </TableCell>
                  <TableCell align="left">
                    {(event.sessions.length &&
                      moment(event.sessions[0].startDate).format("DD/MM/YY")) ||
                      "-"}
                  </TableCell>
                  <TableCell align="left">
                    {(event.sessions.length &&
                      moment(
                        event.sessions[event.sessions.length - 1].endDate
                      ).format("DD/MM/YY")) ||
                      "-"}
                  </TableCell>
                  <TableCell align="center">
                    {event?.sessions[0]?.startTime} {`  -  `}
                    {event?.sessions[event?.sessions?.length - 1]?.endTime}
                  </TableCell>
                  <TableCell align="center">
                    {event?.booking?.seats || "-"}
                  </TableCell>
                  <TableCell align="center">{event?.cpdPoints || 0}</TableCell>
                  <TableCell align="left">
                    {event?.availability == "Open" && (
                      <Chip
                        label={event?.availability}
                        size="medium"
                        chipcolor="success"
                      />
                    )}
                    {event?.availability == "Full" && (
                      <Chip
                        label={event?.availability}
                        size="medium"
                        chipcolor="error"
                      />
                    )}
                    {event?.availability == "Draft" && (
                      <Chip
                        label={event?.availability}
                        size="medium"
                        chipcolor="warning"
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {event.status == "ACTIVE" && (
                      <Chip
                        label={statusTypeMappingObj[event?.status]}
                        size="medium"
                        chipcolor="success"
                      />
                    )}

                    {event.status == "INACTIVE" && (
                      <Chip
                        label={statusTypeMappingObj[event?.status]}
                        size="medium"
                        chipcolor="warning"
                      />
                    )}

                    {event.status == "REMOVED" && (
                      <Chip
                        label={statusTypeMappingObj[event?.status]}
                        size="medium"
                        chipcolor="error"
                      />
                    )}
                  </TableCell>

                  <TableCell align="center" sx={{ pr: 3 }}>
                    <ActionButton
                      eventId={event._id}
                      status={event?.status}
                      event={event}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EventTable;
