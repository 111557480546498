/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Typography,
} from '@mui/material';

/* gql hooks */

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { RootState, useDispatch, useSelector } from 'store';
import { setPage, setRowsPerPage } from 'store/slices/table';

/* custom hooks */
import _debounce from 'lodash.debounce';

import { MembersReport } from './constants/types';
import Spinner from 'components/spinner';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useDebouncedSearch from 'views/event/event-list/hooks/useDebounceSearch';
import { useGQL } from '../hooks/useGQL';
import MembersReportListTable from './tables';
import FilterMemberListModal from './filter';
import { ArrangementOrder } from 'types';

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];
// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const MembersReports = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState<ArrangementOrder>('asc');
  const [orderBy, setOrderBy] = useState<string>('_id');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);

  const dispatch = useDispatch();
  const [filterMemberData, setFilterMemberData] = useState({
    status: '',
    aml: '',
    company: '',

    crm: '',
    endDate: '',
    membershipType: '',
    startDate: '',

    isGuestUser: false,
    phone: '',
    email: '',

    flatUnit: '',
    streetName: '',
    streetNumber: '',
    postcode: '',
    suburb: '',
    state: '',
    country: '',
    code: '',
    validUptoDate: '',
  });

  /* queries & mutations */
  const { GET_ALL_MEMBERS_REPORT } = useGQL();
  const { error, loading, data, refetch } = GET_ALL_MEMBERS_REPORT({
    limit: 15,
    skip: 0,
    filterInput: {},
  });

  /* local states */
  const [membersReport, setMembersReport] = useState<MembersReport[]>([]);

  const [meta, setMeta] = useState<Meta>();


  useEffect(() => {
    if (data?.generateMemberReports?.memberReportList) {
      setMembersReport(data?.generateMemberReports?.memberReportList);
      setTotal(data?.generateMemberReports?.pagination?.total);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      getMemberListReportInput: {
        limit,
        skip,
        filterInput: {
          status:
            filterMemberData?.status === '' ? null : filterMemberData?.status,
          aml: filterMemberData?.aml === '' ? null : filterMemberData?.aml,
          company:
            filterMemberData?.company === '' ? null : filterMemberData?.company,
          country:
            filterMemberData?.country === '' ? null : filterMemberData?.country,
          crm: filterMemberData?.crm === '' ? null : filterMemberData?.crm,
          endDate:
            filterMemberData?.endDate === '' ? null : filterMemberData?.endDate,
          membershipType:
            filterMemberData?.membershipType === ''
              ? null
              : filterMemberData?.membershipType,
          startDate:
            filterMemberData?.startDate === ''
              ? null
              : filterMemberData?.startDate,
          state:
            filterMemberData?.state === '' ? null : filterMemberData?.state,
          isGuestUser: filterMemberData?.isGuestUser === false ? false : true,
        },
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    refetch({
      getMemberListReportInput: {
        limit: meta?.limit,
        skip: meta?.skip,

        filterInput: {
          status:
            filterMemberData?.status === '' ? null : filterMemberData?.status,
          aml: filterMemberData?.aml === '' ? null : filterMemberData?.aml,
          company:
            filterMemberData?.company === '' ? null : filterMemberData?.company,
          country:
            filterMemberData?.country === '' ? null : filterMemberData?.country,
          crm: filterMemberData?.crm === '' ? null : filterMemberData?.crm,
          endDate:
            filterMemberData?.endDate === '' ? null : filterMemberData?.endDate,
          membershipType:
            filterMemberData?.membershipType === ''
              ? null
              : filterMemberData?.membershipType,
          startDate:
            filterMemberData?.startDate === ''
              ? null
              : filterMemberData?.startDate,
          state:
            filterMemberData?.state === '' ? null : filterMemberData?.state,
          isGuestUser: filterMemberData?.isGuestUser,
        },
      },
    });
  };

  useEffect(() => {
    setPage(0);
    handleRefetch();
  }, [filterMemberData,rowsPerPage]);

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  return (
    <>
      <MainCard
        title="Member Reports"
        content={false}
        sx={{ position: 'relative' }}
      >
        <CardContent>
          <Grid marginLeft={'-1.5rem'} item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Filter</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FilterMemberListModal
                  handleRefetch={handleRefetch}
                  total={total}
                  setFilterMemberData={setFilterMemberData}
                  filterInput={filterMemberData}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </CardContent>

        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Divider />
            <MembersReportListTable {...{ membersReport, handleRefetch }} />

            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.generateMemberReports?.pagination.total /
                        rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </>
  );
};

export default MembersReports;
