import { useQuery } from "@apollo/client";

import { TransactionReportInputDto } from "../transactions-report-list/constants/types";
import { GET_ALL_TRANSACTIONS_LIST, GET_TRANSACTIONS_FOR_EXPORT } from "grapqhl/transactionReport/query";

export const useGQL = () => {
  const GET_ALL_TRANSACTIONS = (
    input: TransactionReportInputDto = {}
  ) =>
    useQuery(GET_ALL_TRANSACTIONS_LIST, { variables: { input } });

  const GET_ALL_TRANSACTIONS_REPORT_FOR_EXPORTS = (
  ) =>
    useQuery(GET_TRANSACTIONS_FOR_EXPORT, {
      variables: {},
    });
  return {
    GET_ALL_TRANSACTIONS,
    GET_ALL_TRANSACTIONS_REPORT_FOR_EXPORTS,
  };
};
