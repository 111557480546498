import S3 from "react-aws-s3";
import { v4 as uuid } from "uuid";

type AWSConfig = {
  bucketName: string;
  region: string;
  accessKeyId: string;
  secretAccessKey: string;
};

export class AWSUpload {
  awsConfig: AWSConfig;

  constructor() {
    this.awsConfig = {
      bucketName: process.env.REACT_APP_AWS_BUCKET || "myBucket",
      region: process.env.REACT_APP_AWS_REGION!,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY!,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY!,
    };
  }

  async uploadFile(file: File) {
    const ReactS3Client = new S3(this.awsConfig);
    const ext = file.name.split(".").pop();

    return ReactS3Client.uploadFile(file, `${uuid()}.${ext}`)
      .then((data) => {
        return data;
      })
      .catch((err) => console.error(err));
  }

  deleteFile(filename: string) {
    const ReactS3Client = new S3(this.awsConfig);
    ReactS3Client.deleteFile(filename)
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  }
}
