import { gql } from "@apollo/client";

export const GET_ALL_COMPANY_USERS = gql`
  query GetAllCorporateUsers($input: GetUsersDTO!) {
    getAllCorporateUsers(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      users {
        _id
        firstName
        lastName
        email
        code
        phone
        company
        position
        industry
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        createdAt
        membership {
          status
          membershipType
          totalSeats
          seatsTaken
          hasUnlimitedSeats
          validUptoDate
          notes {
            description
            createdAt
            status
            createdBy
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_INFORMATION_DETAILS = gql`
  query GetCorporateUser($getCorporateUserId: String!) {
    getCorporateUser(id: $getCorporateUserId) {
      message
      pagination {
        total
        hasNextPage
      }
      user {
        _id
        firstName
        lastName
        email
        phone
        company
        position
        industry
        code
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        createdAt
        membership {
          status
          membershipType
          totalSeats
          seatsTaken
          hasUnlimitedSeats
          validUptoDate
          notes {
            description
            createdAt
            status
            createdBy
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUserDetailsForSeating($code: String!, $input: GetUsersDTO!) {
    getUserDetailsForSeating(code: $code, input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      users {
        _id
        firstName
        lastName
        email
        phone
        position
        membership {
          status
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUserDetailsForSeating($code: String!) {
    getAllUserDetailsForSeating(code: $code) {
      company
      phone
      users {
        _id
        firstName
        lastName
        email
        phone
        position
        membership {
          status
        }
      }
    }
  }
`;

export const GET_ALL_INVOICE_HISTORY = gql`
  query GetAllCompanyPayments(
    $getAllCompanyPaymentsInput: GetAllCompanyPaymentsInputDto!
  ) {
    getAllCompanyPayments(
      getAllCompanyPaymentsInput: $getAllCompanyPaymentsInput
    ) {
      message
      pagination {
        total
        hasNextPage
      }
      companyPaymentList {
        _id
        amount
        paymentMethod
        invoiceNum
        transactionId
        paymentDate
        dueDate
      }
    }
  }
`;

export const GET_INVOICE_PAYMENT_DETAILS = gql`
  query GetCompanyPayment($paymentId: String!) {
    getCompanyPayment(paymentId: $paymentId) {
      amount
      paymentDate
      paymentMethod
      invoiceNum
      transactionId
      description
      companyId
      _id
    }
  }
`;
