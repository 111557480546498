/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";

/* gql hooks */

// project imports
import MainCard from "ui-component/cards/MainCard";
import { useDispatch } from "store";

/* custom hooks */
import _debounce from "lodash.debounce";

import { TransactionsReport } from "./constants/types";
import Spinner from "components/spinner";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGQL } from "../hooks/useGQL";
import FilterMemberListModal from "./filter";
import { ArrangementOrder } from "types";
import TransactionsReportsTable from "./tables";

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];
// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const TransactionReports = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState<ArrangementOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("_id");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);

  const dispatch = useDispatch();
  const [filterTransactionData, setFilterTransactionData] = useState({
    from: null,
    to: null
  });

  /* queries & mutations */
  const { GET_ALL_TRANSACTIONS } = useGQL();
  const { error, loading, data, refetch } = GET_ALL_TRANSACTIONS({
    limit: 15,
    skip: 0,
    filter: {
      from: filterTransactionData.from,
      to: filterTransactionData.to
    },

  });

  /* local states */
  const [transactionsReport, setTransactionsReport] = useState<
    TransactionsReport[]
  >([]);

  const [meta, setMeta] = useState<Meta>();

  useEffect(() => {
    if (data?.getPaymentReportList?.paymentList) {
      setTransactionsReport(data?.getPaymentReportList?.paymentList);
      setTotal(data?.getPaymentReportList?.pagination?.total);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      input: {
        limit,
        skip,
        filter: {
          from: filterTransactionData?.from,
          to: filterTransactionData?.to,
        },
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    refetch({
      input: {
        limit: meta?.limit,
        skip: meta?.skip,
        filter: {
          from: filterTransactionData?.from,
          to: filterTransactionData?.to,
        },
      },
    });
  };

  useEffect(() => {
    setPage(0);
    handleRefetch();
  }, [filterTransactionData, rowsPerPage]);

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  return (
    <>
      <MainCard
        title="Transaction Reports"
        content={false}
        sx={{ position: "relative" }}
      >
        <CardContent>
          <Grid marginLeft={"-1.5rem"} item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Filter</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FilterMemberListModal
                  handleRefetch={handleRefetch}
                  total={total}
                  setFilterTransactionData={setFilterTransactionData}
                  filter={filterTransactionData}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </CardContent>

        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Divider />
            <TransactionsReportsTable {...{ transactionsReport, handleRefetch }} />

            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getPaymentReportList?.pagination.total /
                      rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </>
  );
};

export default TransactionReports;
