import { HeadCell } from "./types";

// table header options
export const headCells: HeadCell[] = [
  {
    id: "company",
    numeric: true,
    label: "Company",
    align: "left",
    sort: false,
  },
  {
    id: "fullName",
    numeric: false,
    label: "Primary Contact Name",
    align: "left",
    sort: false,
  },
  {
    id: "phone",
    numeric: false,
    label: "Primary Contact Phone",
    align: "left",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    label: "Primary Contact Email",
    align: "left",
    sort: true,
  },
  {
    id: "totalSeats",
    numeric: false,
    label: "Total Seats",
    align: "center",
    sort: true,
  },
  {
    id: "seatsRemaining",
    numeric: false,
    label: "Seats Available ",
    align: "center",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
    align: "left",
    sort: true,
  },
  {
    id: "action",
    numeric: false,
    label: "Action ",
    align: "center",
    sort: false,
  },
];

export const initialValues = {
  company: "",
  name: "",
  phone: "",
  email: "",
  totalSeats: "",
  seatsRemaining: "",
  status: "",
};
