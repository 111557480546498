import { HeadCell } from "./types";

// table header options
export const headCells: HeadCell[] = [
  {
    id: "attendeNname",
    numeric: false,
    label: "Attendee Name",
    align: "left",
    sort: false,
  },
  {
    id: "Email",
    numeric: false,
    label: "Email",
    align: "left",
    sort: false,
  },
  {
    id: "registrationDate",
    numeric: false,
    label: "Registration Date",
    align: "left",
    sort: false,
  },
  {
    id: "status",
    numeric: false,
    label: "Payment Status",
    align: "left",
    sort: true,
  },
  {
    id: "flatunit",
    numeric: false,
    label: "Flat/Unit",
    align: "center",
    sort: true,
  },
  {
    id: "StreetNumber",
    numeric: false,
    label: "Street Number",
    align: "center",
    sort: true,
  },
  {
    id: "StreetName",
    numeric: false,
    label: "Street Name",
    align: "left",
    sort: true,
  },
  {
    id: "Suburb",
    numeric: false,
    label: "Suburb",
    align: "left",
    sort: true,
  },
  {
    id: "Postcode",
    numeric: false,
    label: "Postcode",
    align: "center",
    sort: true,
  },
  {
    id: "State",
    numeric: false,
    label: "State",
    align: "left",
    sort: true,
  },
  {
    id: "Country",
    numeric: false,
    label: "Country",
    align: "left",
    sort: true,
  },
  {
    id: "Note",
    numeric: false,
    label: "Note",
    align: "left",
    sort: true,
  },
  {
    id: "action",
    numeric: false,
    label: "Action",
    align: "center",
    sort: false,
  },
];

export const initialValues = {
  attendeNname: "",
  Email: "",
  registrationDate: "",
  status: "",
  flatunit: "",
  StreetNumber: "",
  StreetName: "",
  Suburb: "",
  Postcode: "",
  State: "",
  Country: "",
};
