import dayjs from "dayjs";

export const truncateString = (str, num) => {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
};

export const firstLetterUppercase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
function setObjectValue(obj, path, value) {
  const keys = path.split(".");
  const lastKeyIndex = keys.length - 1;

  for (let i = 0; i < lastKeyIndex; ++i) {
    const key = keys[i];
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }

  obj[keys[lastKeyIndex]] = value;
  return obj;
}

export const handleDropDown = ({
  values,
  onchangeDropdown,
  event,
  placeholder,
}: any) => {
  const splitted = placeholder.split(".");
  if (splitted.length > 1) {
    setObjectValue(values, placeholder, event.target.value);
    onchangeDropdown({
      ...values,
    });
  } else {
    onchangeDropdown({
      ...values,
      [placeholder]: event.target.value,
    });
  }
};

export const transformDate = (date: string | Date) =>
  dayjs(date).format("YYYY-MM-DD");

export const transformSessionToIso = (sessions: any[]) => {
  return sessions.map((session) => {
    return {
      ...session,
      startTime: dayjs(session.startTime).toISOString(),
      endTime: dayjs(session.endTime).toISOString(),
    };
  });
};
