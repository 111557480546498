/* eslint-disable import/prefer-default-export */

import { HeadCell } from "types";

// table header options
export const headCells: HeadCell[] = [
  {
    id: "sn",
    numeric: false,
    label: "Transaction Number",
    align: "left",
  },
  {
    id: "transactionId",
    numeric: false,
    label: "Transaction Id",
    align: "left",
  },
  {
    id: "paymentDate",
    numeric: false,
    label: "Payment Date",
    align: "left",
  },
  {
    id: "userFirstName",
    numeric: false,
    label: "User First Name",
    align: "left",
  },
  {
    id: "userFamilyname",
    numeric: false,
    label: "User Family Name",
    align: "left",
  },
  {
    id: "companyName",
    numeric: false,
    label: "Company Name",
    align: "left",
  },
  {
    id: "companyCode",
    numeric: false,
    label: "Company Code",
    align: "left",
  },
  {
    id: "membershipType",
    numeric: false,
    label: "Membership Type",
    align: "left",
  },
  {
    id: "membershipStatus",
    numeric: false,
    label: "Membership Status",
    align: "left",
  },
  {
    id: "address",
    numeric: false,
    label: "User Address",
    align: "left",
  },
  {
    id: "eventTitle",
    numeric: false,
    label: "Item Description",
    align: "left",
  },
  {
    id: "eventStartDate",
    numeric: false,
    label: "Item Start Date",
    align: "left",
  },
  {
    id: "fee",
    numeric: true,
    label: "Fee",
    align: "left",
  },
  {
    id: "GST",
    numeric: true,
    label: "GST",
    align: "left",
  },
  {
    id: "creditCardSurcharge",
    numeric: true,
    label: "Credit Card Surcharge",
    align: "left",
  },
  {
    id: "totalDue",
    numeric: true,
    label: "Total Due",
    align: "left",
  },
  {
    id: "paidAmount",
    numeric: true,
    label: "paidAmount",
    align: "left",
  },
  {
    id: "isRefunded",
    numeric: true,
    label: "IsRefunded",
    align: "left",
  },
];
