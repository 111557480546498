/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';

/* gql hooks */

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { RootState, useDispatch, useSelector } from 'store';

/* variables imports */

import NotesListTable from './tables';
import NotesManagement from '../notes-create';
import { useGQL } from 'views/individual-user/hooks/useGQL';
import { NotesResponseList } from 'views/individual-user/types';
import { setPage, setRowsPerPage } from 'store/slices/table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/system';
import moment from 'moment';
import { StatusMappingObject } from './constants/types';

// ==============================|| CUSTOMER LIST ||============================== //
const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;
const NotesList = ({
  membershipInformation,
  refetchCompanyInformation,
  userId,
}) => {
  const [meta, setMeta] = useState<Meta>();
  const dispatch = useDispatch();

  const { searchText, page, rowsPerPage, sort } = useSelector(
    (state: RootState) => state.table
  );

  /* queries & mutations */
  const { GET_NOTES } = useGQL();
  const { error, loading, data, refetch } = GET_NOTES({ userId });

  const [notes, setNotes] = useState<NotesResponseList[]>([]);
  useEffect(() => {
    if (data?.getUserNotes?.userNotes) {
      setNotes(data?.getUserNotes?.userNotes);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      getUserNotesListInput: {
        limit,
        skip,
        userId,
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    refetch({
      getUserNotesListInput: {
        limit: meta?.limit,
        skip,
        userId,
      },
    });
  };
  const [anchor, setAnchor] = useState(null);

  const handleChangePage = (event, newPage: number) => {
    dispatch(setPage(newPage - 1));
  };

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    }
  };

  return (
    <>
      <Grid item xs={12} sx={{ backgroundColor: '#F4F0EC', padding: '10px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          mb={1}
        >
          <Typography
            sx={{ marginBottom: '0.1.5rem' }}
            variant="h5"
            component="h1"
          >
            Member Since
          </Typography>
          <Stack direction="row">
            <Typography
              sx={{ marginBottom: '0.1.5rem' }}
              variant="h5"
              component="h1"
            >
              {membershipInformation?.memberSince
                ? moment(membershipInformation?.memberSince).format('DD/MM/YY')
                : '-'}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          mb={1}
        >
          <Typography
            sx={{ marginBottom: '0.1.5rem' }}
            variant="h5"
            component="h1"
          >
            Status
          </Typography>
          <Stack direction="row">
            <Typography
              sx={{ marginBottom: '0.1.5rem' }}
              variant="h5"
              component="h1"
            >
              {StatusMappingObject[membershipInformation?.status]}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <NotesManagement membershipInformation="" handleRefetch={handleRefetch} />
      <MainCard content={false} sx={{ position: 'relative' }}>
        <NotesListTable {...{ notes, handleRefetch }} />
        <Grid item xs={12} sx={{ p: 3 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Pagination
                count={Math.ceil(
                  data?.getUserNotes?.pagination.total / rowsPerPage
                )}
                color="primary"
                onChange={handleChangePage}
                defaultPage={page + 1}
                page={page + 1}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
                hidePrevButton={page === 0 ?? true}
              />
            </Grid>
            <Grid item>
              <span>Show</span>
              <Button
                size="large"
                endIcon={<ExpandMoreIcon />}
                onClick={handleClickPage}
                className="pagination-button"
              >
                {rowsPerPage} per page
              </Button>
              <Menu
                id="menu-user-list-style1"
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClosePage}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {rowsPerPageOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={(event) => handlePageMenuItemClick(event)}
                  >
                    {option.label} rows
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default NotesList;
