/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */

import React, { useState, useEffect } from "react";
// material-ui
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// project imports
import Chip from "ui-component/extended/Chip";
import useAlertDialog from "hooks/common/useAlertDialog";

/* queries */
import Spinner from "components/spinner";
import FailureLoad from "components/spinner/fail";
import useDebouncedSearch from "hooks/common/useDebounceSearch";
import { Link, useNavigate } from "react-router-dom";
import useSnackbar from "hooks/common/useSnackbar";
import { CustomMainCard } from "./index.styles";
import { EmailTemplate } from "types/emailTemplate";

import { ArrangementOrder1 } from "types";
import useTable from "hooks/common/useTable";
import { headCells } from "constants/actionItems";
import { RootState, useDispatch, useSelector } from "store";
import { useGQL } from "views/event/hooks/useGQL";
import { ActionItems } from "types/event";

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];

const ActionItemList = () => {
  const navigate = useNavigate();
  const { TableContainer, EnhancedTableHead } = useTable();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({
    limit: 15,
    skip: 0,
  });
  const [order, setOrder] = useState<ArrangementOrder1>("asc");
  const [orderBy, setOrderBy] = useState<string>("_id");
  const [items, setItems] = useState<ActionItems[]>([]);
  const [count, setCount] = useState<number>(1);
  const { GET_ACTION_ITEM_LIST } = useGQL();
  const { error, loading, data, refetch } = GET_ACTION_ITEM_LIST();

  useEffect(() => {
    if (data?.actionItemsList?.data) {
      setItems(data?.actionItemsList?.data);
      setCount(data.actionItemsList?.pagination?.total!);
    }
  }, [data]);

  useEffect(() => {
    /* set page meta */
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setPageMeta({ limit, skip });
    refetch({ input: { limit, skip, order, orderBy } });
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleRefetch = () => {
    refetch({
      input: {
        limit: pageMeta.limit,
        skip: pageMeta.skip,
        order,
        orderBy,
      },
    });
  };

  useEffect(() => {
    /* handle refetch here */
    setPage(0);
    handleRefetch();
  }, [orderBy, order, rowsPerPage]);

  const handleRequestSort = (
    event: React.SyntheticEvent<Element, Event>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
  };

  const [anchor, setAnchor] = useState(null);

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  return (
    <CustomMainCard title="Action Items" content={false}>
      <>
        {/* table */}
        {loading ? (
          <Spinner />
        ) : error ? (
          <FailureLoad />
        ) : (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {items?.map((event, index) => (
                    <TableRow
                      key={event._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      {/* table cells render data */}
                      <TableCell align="left">
                        {page === 0 ? index + 1 : index + pageMeta.skip + 1}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {event.type === "eventBookings"
                          ? event?.event?.title
                          : event.type === "cpdPoints"
                          ? event?.userInfo?.firstName +
                            " " +
                            event?.userInfo?.lastName
                          : event?.firstName + " " + event?.lastName}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {event.type === "usersCompany"
                          ? "Confirmation Required"
                          : event.type === "cpdPoints"
                          ? "Approval required "
                          : "Payment Required"}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {event.type === "users"
                          ? "Once user has made the payment, make sure to update their membership status."
                          : event.type === "usersCompany"
                          ? "Once you have verified their registration, make sure to update their membership status."
                          : event.type === "eventBookings"
                          ? "Once user has made the payment, make sure to update their event status."
                          : "Once you have verified their request, make sure to approve their CPD points."}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(count / rowsPerPage)}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </CustomMainCard>
  );
};

export default ActionItemList;
