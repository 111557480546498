import { TableCell, TableContainer as MuiTableContainer, TableHead as MuiTableHead, TableRow } from '@mui/material';
import { HeadCell } from 'types/page';

type Children = {
    children: React.ReactElement;
};

type TableHeadProps = {
    headCells: HeadCell[];
};

const useTable = () => {
    const TableContainer = ({ children }: Children) => {
        return (
            <MuiTableContainer
                sx={{ background: '#fff', borderRadius: '8px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, padding: '2rem' }}
            >
                {children}{' '}
            </MuiTableContainer>
        );
    };

    const TableHead = ({ headCells }: TableHeadProps) => {
        return (
            <MuiTableHead sx={{ height: '50px' }}>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell key={headCell.id} id={headCell.id} align={headCell.align} padding={headCell.padding}>
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </MuiTableHead>
        );
    };

    return { TableContainer, TableHead };
};

export default useTable;
