import { gql } from "@apollo/client";

export const UPDATE_RESOURCE_INFORMATION = gql`
  mutation CreateResource($input: CreateResourceDTO!) {
    createResource(input: $input) {
      message
    }
  }
`;

export const UPDATE_RESOURCE = gql`
  mutation UpdateResource(
    $resourceId: String!
    $input: UpdateResourceInputDTO!
  ) {
    updateResource(resourceId: $resourceId, input: $input) {
      message
    }
  }
`;

export const DUPLICATE = gql`
  mutation DuplicateResource($resourceId: String!) {
    duplicateResource(resourceId: $resourceId) {
      message
    }
  }
`;
