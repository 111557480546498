import { gql } from '@apollo/client';

export const GET_EVENT_REPORTS = gql`
  query GetEventReport($eventReportInput: EventReportInputDto!) {
    getEventReport(eventReportInput: $eventReportInput) {
      message
      pagination {
        total
        hasNextPage
      }
      eventReports {
        _id
        title
        eventType
        mainLocation
        mainLink
        sessions {
          startDate
          endDate
          startTime
          endTime
          modality
          link
          location
        }
        status
        noOfConfirmedUsers
        noOfCancelledUsers
      }
    }
  }
`;
