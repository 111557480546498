import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Spinner from "components/spinner";
import { Formik } from "formik";
import moment from "moment";
import { paymentMethodMap } from "views/company/company-list/constants/types";
import { useGQL } from "../../individual-user/hooks/useGQL";
import { useEffect } from "react";
import useSnackbar from "hooks/common/useSnackbar";
import lang from "constants/language";
import { PaymentStatus } from "./invoice-list/constants/types";
import { paymentStatusMap } from "./invoice-list/constants/variables";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import * as Yup from "yup";

// import { paymentMethodMap } from '../company-list/constants/types';
const ViewInvoice = ({
  open,
  setOpen,
  refetchCompanyInformation,
  invoiceInformation,
  loading,
  refetchINvoiceInformation,
  handleRefetch,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      md: 700,
      xs: "90%",
    },
    bgcolor: "#fff",
    boxShadow: 28,
    p: 4,
  };

  const { handleOpenSnackbar } = useSnackbar();

  const { UPDATE_INVOICE_DETAILS } = useGQL();
  const [handleUpdate, { data: update, loading: paymentLoading, error }] =
    UPDATE_INVOICE_DETAILS();
  useEffect(() => {
    if (update?.updateInvoicePayment) {
      handleOpenSnackbar({
        message: lang.PAYMENT_RECORD_UPDATE,
        alertType: "success",
      });
      refetchINvoiceInformation();
      handleRefetch();
      setOpen(false);
    }
  }, [update]);

  const handleUpdateInvoice = async (
    values,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const input = { ...values };
      console.log("infooo", input);
      await handleUpdate({
        variables: {
          input: {
            invoiceNum: input?.invoiceNum,
            description: input?.description ?? input?.itemDescription,
            invoiceId: invoiceInformation?._id,
            status: input?.status,
            paymentDate: input?.paymentDate,
            paidAmount: input?.paidAmount,
          },
        },
      });
      // refetchCompanyInformation();
      setSubmitting(false);
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };
  console.log("invoice information", invoiceInformation);
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        "& .MuiBackdrop-root": { backgroundColor: "rgba(0, 0, 0, 0.18)" },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {loading ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <Formik
              enableReinitialize
              initialValues={{
                invoiceNum: invoiceInformation?.invoiceNum || "",
                transactionId: invoiceInformation?.transactionId || "",
                paymentDate: invoiceInformation?.paymentDate || new Date(),
                paymentMethod:
                  paymentMethodMap[invoiceInformation?.paymentMethod] || "",
                paidAmount: invoiceInformation?.paidAmount || "",
                description: invoiceInformation?.description || "",
                status: invoiceInformation?.status || "",
              }}
              validationSchema={Yup.object().shape({
                paymentDate: Yup.date()
                  .required("Payment date is required")
                  .nullable(),
                status: Yup.string().required("Status is required").nullable(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                /* make data sync with update userDTO */
                const input = {
                  ...values,
                };
                setSubmitting(true);
                handleUpdateInvoice(input, setSubmitting);
              }}
            >
              {({
                handleSubmit,
                getFieldProps,
                errors,
                setFieldValue,
                touched,
                values,
                /* and other goodies */
              }) => (
                <form onSubmit={(e) => handleSubmit(e)}>
                  <Typography
                    variant="h3"
                    component="h1"
                    sx={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    View Payment Details
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ maxWidth: "697px" }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Invoice No"
                        label="Invoice No"
                        {...getFieldProps("invoiceNum")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Transaction Id"
                        label="Transaction Id"
                        disabled
                        {...getFieldProps("transactionId")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Amount"
                        label="Amount"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: {
                            step: 0.01,
                          },
                        }}
                        {...getFieldProps("paidAmount")}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value).toFixed(2);
                          getFieldProps("paidAmount").onChange({
                            target: {
                              name: "paidAmount",
                              value: Number(value),
                            },
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            values?.paymentDate
                              ? new Date(values?.paymentDate)
                              : null
                          }
                          label="Payment Date"
                          sx={{ width: "100%" }}
                          onChange={(newValue: any) => {
                            setFieldValue(
                              "paymentDate",
                              newValue ? dayjs(newValue).toJSON() : null
                            );
                          }}
                        />
                      </LocalizationProvider>
                      {touched?.paymentDate && errors.paymentDate && (
                        <FormHelperText error id="paymentDate-error">
                          {errors.paymentDate}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        placeholder="Method"
                        label="Method"
                        disabled
                        {...getFieldProps("paymentMethod")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="status"
                        label="Payment Status"
                        select
                        fullWidth
                        sx={{ width: "100%" }}
                        {...getFieldProps("status")}
                      >
                        {PaymentStatus.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      {touched.status && errors.status && (
                        <FormHelperText error id="status-error">
                          {errors.status}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Description"
                        label="Description"
                        multiline
                        {...getFieldProps("description")}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{ marginTop: "2em", display: "flex", gap: "20px" }}
                    justifyContent="flex-end"
                  >
                    <Button
                      sx={{ color: "white" }}
                      variant="contained"
                      onClick={() => setOpen(false)}
                    >
                      Back
                    </Button>
                    <Button type="submit" variant="outlined">
                      {"Save Changes"}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </>
    </Modal>
  );
};
export default ViewInvoice;

//................................................................end of code................
