/* eslint no-underscore-dangle: 0 */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import moment from 'moment';

import { headCells } from '../constants/variables';

import { useDispatch, useSelector } from 'react-redux';

import { StatusMappingObject } from '../constants/types';
import TableErrorMessage from 'common/tableErrorMessage';

type Sort = { order: string; orderBy: string } | null;
type NotesTableProps = {
  notes;
  handleRefetch;
};

type Order = 'asc' | 'desc';
const NotesListTable = ({ notes, handleRefetch }: NotesTableProps) => {
  const dispatch = useDispatch();
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          {notes.length === 0 ? (
            <TableErrorMessage colSpan={12} />
          ) : (
            <TableBody>
              {notes
                .slice()
                .sort(
                  (a, b): number =>
                    +new Date(b.createdAt) - +new Date(a.createdAt)
                )
                .map((note) => (
                  <TableRow key={note._id}>
                    <TableCell sx={{ width: '20rem' }} align="left">
                      {note?.description}
                    </TableCell>
                    <TableCell align="center">
                      {moment(note?.createdAt).format('DD/MM/YY')}
                    </TableCell>
                    <TableCell align="right">
                      {note && note?.status == 'VALID' ? (
                        <Chip
                          label={StatusMappingObject[note?.status]}
                          size="medium"
                          chipcolor="success"
                        />
                      ) : note && note?.status == 'RESIGNED' ? (
                        <Chip
                          label={StatusMappingObject[note?.status]}
                          size="medium"
                          chipcolor="error"
                        />
                      ) : note && note?.status == 'AWAITING_PAYMENT' ? (
                        <Chip
                          label={StatusMappingObject[note?.status]}
                          size="medium"
                          chipcolor="warning"
                        />
                      ) : note && note?.status == 'GRACE' ? (
                        <Chip
                          label={StatusMappingObject[note?.status]}
                          size="medium"
                          chipcolor="secondary"
                        />
                      ) : note && note?.status === 'CORPORATE' ? (
                        <Chip
                          label={StatusMappingObject[note?.status]}
                          size="medium"
                          chipcolor="primary"
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default NotesListTable;
