import { gql } from '@apollo/client';

export const GET_RESOURCE = gql`
  query GetResource($input: GetResourceDTO) {
    getResource(input: $input) {
      message
      resource {
        _id
        title
        isMemberOnly
        permalink
        date
        author
        category
        subCategories
        description
        status
        slug
        isDeleted
      }
    }
  }
`;
export const GET_RESOURCE_LIST = gql`
  query GetResourcesList($input: GetAllResourcesInputDTO!) {
    getResourcesList(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      resources {
        _id
        title
        isMemberOnly
        permalink
        date
        author
        category
        subCategories
        description
        status
        slug
        isDeleted
        downloadCount
      }
    }
  }
`;
