/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import { useEffect, useState } from 'react';
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import styled from 'styled-components';

import useSnackbar from 'hooks/common/useSnackbar';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { termArray } from 'store/constant';

// assets
import { furtherDetailValidation } from './further-detail-validation-schema';
import { useGQL } from '../hooks/useGQL';
import { PageType } from '../enum';
import { Stack } from '@mui/system';
import CustomTinyMCE from 'components/editors/CustomTinyMCE';
import lang from 'constants/language';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_SPEAKERS } from 'grapqhl/event/query';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const FurtherDetails = ({
  eventData,
  refetchEvent,
  handleCancelEvent,
}: any) => {
  const { handleOpenSnackbar } = useSnackbar();
  const [speakers, setSpeakers] = useState<any>([]);
  const { GET_SPEAKERS, UPDATE_FURTHER_DETAIL } = useGQL();
  // const [speakersMenu, setSpeakersMenu] = useState<any>([]);
  let speakersMenu: any = [];

  const [getAllSystemSpeakers] = useLazyQuery(GET_ALL_SPEAKERS);
  const fetchData = async () => {
    const { data } = await getAllSystemSpeakers({
      variables: {
        input: {},
      },
      fetchPolicy: 'network-only',
    });

    setSpeakers(data?.getAllSpeaker?.speakers);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (eventData?.speaker?.speakers) {
    const speakerIds = eventData.speaker.speakers.map((item) => item._id);
    speakersMenu.push(...speakerIds);
  }

  const currentDate = new Date();

  const params = new URLSearchParams(window.location.search);
  const eventId = params.get('eventId') as string;

  const [handleUpdate, { data: update }] = UPDATE_FURTHER_DETAIL();

  useEffect(() => {
    if (update?.updateFurtherDetails) {
      handleOpenSnackbar({
        message: lang.FURTHER_DETAIL.UPDATE,
        alertType: 'success',
      });
    }
  }, [update]);

  const handleFurtherDetailsUpdate = async (
    values,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      if (values?.speaker?.term === '' || values?.speaker?.term === null) {
        delete values?.speaker;
      }
      if (
        eventData?.pageType === 'TYPE_1' &&
        values?.furtherDetails?.singleDetail === ''
      ) {
        delete values?.furtherDetails;
      }
      if (
        eventData?.pageType === 'TYPE_2' &&
        values.furtherDetails.detailLeft === '' &&
        eventData?.furtherDetails?.detailRight
      ) {
        delete values?.furtherDetails;
      }

      await handleUpdate({
        variables: {
          eventId: eventId,

          input: {
            ...values,
          },
        },
      });
      refetchEvent();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: 'error' });
      setSubmitting(false);
    }
  };
  const initial = {
    furtherDetails: {
      detailLeft: eventData?.furtherDetails?.detailLeft || '',
      detailRight: eventData?.furtherDetails?.detailRight || '',
      singleDetail: eventData?.furtherDetails?.singleDetail || '',
    },
    speaker: {
      speakers: eventData?.speaker?.speakers?.length
        ? eventData?.speaker?.speakers.map((item) => item._id)
        : [],
      term: eventData?.speaker?.term || null,
    },
  };
  return (
    <>
      <FormWrapper>
        <Formik
          enableReinitialize
          initialValues={initial}
          validationSchema={furtherDetailValidation}
          onSubmit={async (values, { setSubmitting }) => {
            /* make data sync with update userDTO */

            const input = {
              ...values,
              furtherDetails: {
                detailLeft:
                  eventData?.pageType === 'TYPE_1'
                    ? null
                    : values.furtherDetails.detailLeft,
                detailRight:
                  eventData?.pageType === 'TYPE_1'
                    ? null
                    : values.furtherDetails.detailRight,
                singleDetail:
                  eventData?.pageType === 'TYPE_1'
                    ? values.furtherDetails.singleDetail
                    : null,
              },
            };

            setSubmitting(true);
            handleFurtherDetailsUpdate(input, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <Typography variant="h3">Event Details</Typography>
              </Grid>
              <Grid sx={{ marginTop: '15px' }} container spacing={3}>
                {eventData?.pageType === PageType.TYPE_1 && (
                  <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="outline-adornment-full-name">
                        Single Detail
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTinyMCE
                        height={200}
                        setEditorState={(e) => {
                          handleChange({
                            target: {
                              name: 'furtherDetails.singleDetail',
                              value: e,
                            },
                          });
                        }}
                        value={values.furtherDetails?.singleDetail}
                      />
                    </Grid>
                    <FormHelperText error id="title-error">
                      <ErrorMessage name={`furtherDetails.singleDetail`} />
                    </FormHelperText>
                  </Grid>
                )}

                {eventData?.pageType === 'TYPE_2' && (
                  <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="outline-adornment-full-name">
                        Left Hand Side
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTinyMCE
                        height={200}
                        setEditorState={(e) => {
                          handleChange({
                            target: {
                              name: 'furtherDetails.detailLeft',
                              value: e,
                            },
                          });
                        }}
                        value={values.furtherDetails?.detailLeft}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="outline-adornment-full-name">
                        Right Hand Side
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTinyMCE
                        height={200}
                        setEditorState={(e) => {
                          handleChange({
                            target: {
                              name: 'furtherDetails.detailRight',
                              value: e,
                            },
                          });
                        }}
                        value={values.furtherDetails?.detailRight}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                sx={{ marginTop: '20px', marginBottom: '20px' }}
                xs={12}
              >
                <Typography variant="h3">Presenters</Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                  <TextField
                    id="termUsed"
                    label="Description"
                    select
                    fullWidth
                    sx={{ width: '100%' }}
                    {...getFieldProps('speaker.term')}
                  >
                    {termArray.map((option) => (
                      <MenuItem
                        sx={{
                          padding: option.label === '' ? '15px' : undefined,
                        }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  {touched.speaker?.term && errors.speaker?.term && (
                    <FormHelperText error id="eventType-error">
                      {errors.speaker.term}
                    </FormHelperText>
                  )}
                </Grid>
                <FieldArray
                  name="speaker.speakers"
                  render={(selectedSpeakers) => (
                    <>
                      {values.speaker.speakers.map((session, index) => (
                        <div key={index}>
                          <Grid
                            sx={{ marginTop: '5px' }}
                            container
                            spacing={3}
                            alignItems="center"
                          >
                            <Grid item lg={11}>
                              <TextField
                                id="user"
                                label={
                                  values?.speaker?.term === 'Facilitator'
                                    ? 'Select Facilitator'
                                    : 'Select Presenter'
                                }
                                select
                                fullWidth
                                sx={{ width: '100%' }}
                                {...getFieldProps(`speaker.speakers[${index}]`)}
                                onChange={handleChange}
                                value={session}
                                // onMouseEnter={refetch()}
                              >
                                {speakers &&
                                  speakers.map((option) => {
                                    return (
                                      <MenuItem
                                        key={option?._id}
                                        value={option?._id}
                                        onClick={() => {
                                          speakersMenu.push(option?._id);
                                        }}
                                        disabled={speakersMenu.includes(
                                          option?._id
                                        )}
                                      >
                                        {`${option?.firstName} ${option?.lastName}`}
                                      </MenuItem>
                                    );
                                  })}
                              </TextField>
                              <FormHelperText error id="title-error">
                                <ErrorMessage
                                  name={`speaker.speakers.${index}`}
                                />
                              </FormHelperText>
                            </Grid>
                            <Grid item lg={1}>
                              <IconButton
                                type="button"
                                onClick={() => selectedSpeakers.remove(index)}
                                sx={{ color: 'red', marginLeft: '1.5rem' }}
                              >
                                <CancelTwoToneIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      <Grid item xs={12}>
                        <IconButton
                          type="button"
                          sx={{ color: '#004C8F', marginTop: '1rem' }}
                          onClick={() => {
                            selectedSpeakers.push('');
                          }}
                        >
                          <AddCircleTwoToneIcon />
                          <Typography>Add Presenter</Typography>
                        </IconButton>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="right"
                  sx={{ mt: '1rem' }}
                >
                  {eventData?.openAnytime === true ? (
                    <Button
                      disabled={isSubmitting}
                      variant="outlined"
                      onClick={(e) => {
                        handleCancelEvent(eventId, 'INACTIVE');
                      }}
                    >
                      Cancel event
                    </Button>
                  ) : (
                    eventData?.sessions[0]?.startDate &&
                    new Date(eventData?.sessions[0]?.startDate) >=
                      currentDate && (
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        onClick={(e) => {
                          handleCancelEvent(eventId, 'INACTIVE');
                        }}
                      >
                        Cancel event
                      </Button>
                    )
                  )}

                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    sx={{ color: 'white' }}
                  >
                    {isSubmitting ? 'Please wait...' : 'Save Changes'}
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default FurtherDetails;
