import { gql } from '@apollo/client';

export const UPDATE_MEMBERSHIP_INFORMATION = gql`
  mutation UpdateMembership($input: CreateMembershipDto!) {
    updateMembership(input: $input) {
      message
    }
  }
`;

export const DELETE_MEMBERSHIP_INFORMATION = gql`
  mutation Deletemembership($input: DeleteMembershipInputDto!) {
    deletemembership(input: $input) {
      message
    }
  }
`;
