/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* gql hooks */
import { useGQL } from "../hooks/useGQL";

// project imports
import MainCard from "ui-component/cards/MainCard";
import { RootState, useDispatch, useSelector } from "store";
import { setPage, setRowsPerPage, setSearchText } from "store/slices/table";

/* custom hooks */
import { useDialog } from "./hooks/useDialogs";
import _debounce from "lodash.debounce";

// assets
import SearchIcon from "@mui/icons-material/Search";

/* variables imports */
import Spinner from "components/spinner";
import useDebouncedSearch from "./hooks/useDebounceSearch";
import { gridSpacing } from "store/constant";
import { getUserInformationDetailsResponse } from "../types";
import IndividualUserTable from "./tables";
import { Box, Stack } from "@mui/system";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];
const INDIVIDUAL_USER_STATUS = [
  { label: "Valid", value: "Valid" },
  { label: "Awaiting Payment", value: "Awaiting Payment" },
  { label: "Grace", value: "Grace" },
  { label: "Resigned", value: "Resigned" },
];

export const MEMBERHSHIP_TYPE = [
  { label: "Corporation Member", value: "Corporate" },
  { label: "Individual", value: "Individual" },
  {
    label: "Corporate Principal Member",
    value: "Corporate Principal Member",
  },
  {
    label: "Individual Student",
    value: "Individual Student",
  },
  {
    label: "Individual Retired",
    value: "Individual Retired",
  },
];

// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const IndividualUserList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [searchTextInput, setSearchTextInput] = useState<string>("");
  const [debouncedSearch] = useDebouncedSearch(
    (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | undefined
    ) => {
      if (event?.target) {
        setSearchTextInput(event.target.value);
      }
    }
  );

  const dispatch = useDispatch();

  /* queries & mutations */
  const { GET_ALL_INDIVIDUAL_USERS } = useGQL();
  const { error, loading, data, refetch } = GET_ALL_INDIVIDUAL_USERS();

  const { searchText, page, rowsPerPage, sort } = useSelector(
    (state: RootState) => state.table
  );

  /* local states */
  const [individualUser, setIndividualUser] = useState<
    getUserInformationDetailsResponse[]
  >([]);
  const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();
  const [meta, setMeta] = useState<Meta>();

  const [membershipTypeState, setMembershipTypeState] = useState("");
  const [filterStatusState, setFilterStatusState] = useState("");
  const [isGuestUserState, setIsGuestUserState] = useState<boolean | null>(
    null
  );

  const handleFilterFunc = ({
    filterMembershipType,
    filterStatus,
    isGuestUser,
  }) => {
    setMembershipTypeState(filterMembershipType);
    setFilterStatusState(filterStatus);
    setIsGuestUserState(isGuestUser);
  };

  useEffect(() => {
    if (data?.getAllUsers?.users) {
      setIndividualUser(data?.getAllUsers?.users);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      input: {
        limit,
        skip,
        order: sort.order,
        orderBy: sort.orderBy,
        searchText: searchTextInput,
        filterInput: {
          filterMembershipType: membershipTypeState,
          filterStatus: filterStatusState,
          isGuestUser: isGuestUserState,
        },
      },
    });
  }, [
    rowsPerPage,
    page,
    membershipTypeState,
    filterStatusState,
    isGuestUserState,
  ]);

  const handleRefetch = () => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    refetch({
      input: {
        limit: meta?.limit,
        skip,
        order: sort.order,
        orderBy: sort.orderBy,
        searchText: searchTextInput,
        filterInput: {
          filterMembershipType: membershipTypeState,
          filterStatus: filterStatusState,
          isGuestUser: isGuestUserState,
        },
      },
    });
  };

  const [anchor, setAnchor] = useState(null);

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  useEffect(() => {
    dispatch(setPage(0));
    handleRefetch();
  }, [
    searchTextInput,
    membershipTypeState,
    filterStatusState,
    isGuestUserState,
  ]);

  const handleChangePage = (event, newPage: number) => {
    dispatch(setPage(newPage - 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    }
  };

  const handleEdit = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => {
    const selection = individualUser.find((event) => event._id === id);
    if (selection) {
      // window.location.reload();
      navigate(`/individualUser/add?UserId=${selection._id}`);
    }
  };
  const FilterModel = ({
    open,
    setOpen,

    handleFilterFunc,
    membershipTypeState,
    filterStatusState,
    setFilterStatusState,
    setMembershipTypeState,
    isGuestUserState,
    setIsGuestUserState,
  }) => {
    const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: {
        md: 700,
        xs: "90%",
      },
      bgcolor: "#fff",
      boxShadow: 24,
      p: 4,
    };

    const [filterMembershipType, setFilterMembershipType] =
      useState<string>("");
    const [filterStatus, setFilterStatus] = useState<string>("");
    const [isGuestUser, setIsGuestUser] = useState<boolean | string>(false);

    const handleMembershipType = (event: any, newValue: string) => {
      setFilterMembershipType(
        newValue === "Corporation Member" ? "Corporate" : newValue
      );
    };
    const handleMembershipStatus = (event: any, newValue: string) => {
      setFilterStatus(newValue);
    };
    const handleIsGuest = (newValue: boolean) => {
      setIsGuestUser(newValue);
    };

    const handleCloseModal = () => {
      handleFilterFunc({
        filterMembershipType: "",
        filterStatus: "",
        isGuestUser: null,
      });
      setOpen(false);
    };
    const handleCloseModalCross = () => {
      setOpen(false);
    };
    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "gray",
                    marginTop: "-1rem",
                    marginBottom: "2rem",
                  }}
                >
                  Filter
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: "-1.5rem" }} xs={1}>
                <IconButton
                  type="button"
                  onClick={(e) => handleCloseModalCross()}
                  sx={{
                    color: "#004C8F",
                  }}
                >
                  <CancelTwoToneIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  options={MEMBERHSHIP_TYPE}
                  defaultValue={
                    membershipTypeState
                      ? membershipTypeState === "Corporate"
                        ? "Corporation Member"
                        : membershipTypeState
                      : filterMembershipType
                  }
                  onInputChange={handleMembershipType}
                  renderInput={(params) => {
                    return <TextField {...params} label="Membership Type" />;
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  options={INDIVIDUAL_USER_STATUS}
                  defaultValue={
                    filterStatusState ? filterStatusState : filterStatus
                  }
                  onInputChange={handleMembershipStatus}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Stack sx={{ marginTop: "1.5rem" }}>
                <FormControlLabel
                  label={
                    <Typography variant="subtitle1">Is Guest User</Typography>
                  }
                  control={
                    <Checkbox
                      defaultChecked={isGuestUserState}
                      value={isGuestUserState ? isGuestUserState : isGuestUser}
                      onChange={(e) => {
                        handleIsGuest(e.target.checked);
                      }}
                      name="checked"
                      color="primary"
                    />
                  }
                />
              </Stack>
            </Grid>

            <Box
              sx={{ marginTop: "2em", display: "flex", gap: "20px" }}
              justifyContent="flex-end"
            >
              <Button
                sx={{ color: "white" }}
                variant="contained"
                onClick={() => handleCloseModal()}
              >
                Clear filter
              </Button>

              <Button
                variant="contained"
                sx={{ color: "white" }}
                type="submit"
                onClick={() => {
                  handleFilterFunc({
                    filterMembershipType,
                    filterStatus,
                    isGuestUser,
                  });
                  setOpen(false);
                }}
              >
                {"Apply Filter"}
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    );
  };
  return (
    <>
      <MainCard
        title="Individual Users"
        content={false}
        sx={{ position: "relative" }}
      >
        <Button
          variant="outlined"
          component={Link}
          to="/individualUser/add"
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
          }}
        >
          Add User
        </Button>
        <CardContent>
          <Grid container direction="row" spacing={gridSpacing}>
            <Grid item xs={12} sm={10}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#004C8F" }}>
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 500, // Maximum character limit, including spaces
                  onInput: (event: any) => {
                    const inputValue = event.target.value;
                    const wordCount = inputValue.trim().split(" ").length;
                    if (wordCount > 99) {
                      event.target.value = inputValue
                        .trim()
                        .split(" ")
                        .slice(0, 99)
                        .join(" ");
                    }
                  },
                }}
                fullWidth
                onChange={debouncedSearch}
                placeholder="Search User"
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                onClick={(e) => {
                  setOpen(true);
                }}
                sx={{ height: "100%", width: "100%" }}
                startIcon={<FilterListTwoToneIcon />}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </CardContent>

        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <IndividualUserTable
              {...{ individualUser, handleEdit, handleRefetch }}
            />
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getAllUsers?.pagination.total / rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
      <FilterModel
        open={open}
        setOpen={setOpen}
        handleFilterFunc={handleFilterFunc}
        membershipTypeState={membershipTypeState}
        filterStatusState={filterStatusState}
        setMembershipTypeState={setMembershipTypeState}
        setFilterStatusState={setFilterStatusState}
        isGuestUserState={isGuestUserState}
        setIsGuestUserState={setIsGuestUserState}
      />
    </>
  );
};

export default IndividualUserList;
