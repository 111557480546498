import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

import filePicker from "utils/file-picker";

const plugins = [
  "image",
  "preview",
  "code",
  "fullscreen",
  "table",
  "wordcount",
  "textcolor",
  "lists",
  "paste",
  "link",
];
const toolbar = `bold italic  | fontfamily | fontsize | blocks | forecolor | backcolor | alignleft aligncenter | alignright alignjustify | bullist numlist outdent indent | preview | code | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | link`;

type PropTypes = {
  value: string;
  height: number;
  setEditorState: ((a: string, editor: TinyMCEEditor) => void) | undefined;
};
const font_size_formats =
  "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px 37px 38px 39px 40px 41px 42px 43px 44px 45px 46px 47px 48px";
const CustomTinyMCE = ({
  value: initialValue,
  height,
  setEditorState,
}: PropTypes) => {
  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE}
        value={initialValue}
        init={{
          height,
          menubar: false,
          plugins,
          toolbar,
          font_size_formats,

          formats: {
            p: {
              block: "p",
              exact: true,
              styles: { "font-size": "18px" },
            },
            h1: { block: "h1", exact: true, styles: { "font-size": "36px" } },
            h2: { block: "h2", exact: true, styles: { "font-size": "30px" } },
            h3: { block: "h3", exact: true, styles: { "font-size": "24px" } },
            h4: { block: "h4", exact: true, styles: { "font-size": "16px" } },
            h5: { block: "h5", exact: true, styles: { "font-size": "14px" } },
            h6: { block: "h6", exact: true, styles: { "font-size": "10px" } },

            pre: {
              block: "pre",
              exact: true,
              styles: { "font-size": "10px" },
            },
            body: {
              block: "body",
              exact: true,
              styles: { "font-size": "10px" },
            },
          },
          style_formats: [
            { title: "Heading 1", format: "h1" },
            { title: "Heading 2", format: "h2" },
            { title: "Heading 3", format: "h3" },
            { title: "Heading 4", format: "h4" },
            { title: "Heading 5", format: "h5" },
            { title: "Heading 6", format: "h6" },
            { title: "Paragraph", format: "p" },
            { title: "preformatted", format: "pre" },
            { title: "body", format: "body" },
          ],

          // automatic_uploads: true,
          smart_paste: false,
          // paste_data_images: true,
          // paste_as_text: true,
          paste_enable_default_filters: false,
          paste_word_valid_elements:
            "b,strong,i,em,h1,h2,u,p,ol,ul,li,a[href],span,color,font-size,font-color,font-family,mark,table,tr,td",
          paste_retain_style_properties: "all",
          table_toolbar:
            "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
          font_family_formats:
            "Andale Mono=andale mono,times; Century Gothic=Gothic A1 , Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Outfit=Outfit; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap'); body { font-family:Outfit; font-size:18px;}",
          file_picker_callback: function (cb, value, meta) {
            filePicker(cb, value, meta);
          },
        }}
        onEditorChange={setEditorState}
      />
    </>
  );
};

export default CustomTinyMCE;
