/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Chip from "ui-component/extended/Chip";
import moment from "moment";

import {
  TransactionsReport,
  eventTypeMappingObj,
  membershipMappingObj,
  statusTypeMappingObj,
} from "../constants/types";

import { headCells } from "../constants/variables";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";

import { IndividualStatusMappingObj } from "views/individual-user/individual-user-list/constants/types";
import TableErrorMessage from "common/tableErrorMessage";
import { Box } from "@mui/system";

type Sort = { order: string; orderBy: string } | null;
type TransactionsReportTable = {
  transactionsReport: TransactionsReport[];
};

type Order = "asc" | "desc";
const TransactionsReportsTable = ({
  transactionsReport,
}: TransactionsReportTable) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === "action") {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    sx={{ whiteSpace: "nowrap" }}
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {transactionsReport.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              transactionsReport.map((transaction) => (
                <TableRow key={transaction?._id}>
                  <TableCell align="left">
                    {transaction?.snNumber}
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.transactionId}
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.paymentDate
                      ? moment(transaction?.paymentDate).format("DD/MM/YYYY")
                      : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.userDetails?.firstName}
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.userDetails?.familyName}
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        width: "200px",
                      }}
                    >
                      {transaction?.userDetails?.companyName}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.userDetails?.companyCode}
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.userDetails?.membershipType &&
                      membershipMappingObj[
                      transaction?.userDetails?.membershipType
                      ]}
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.userDetails?.membershipStatus &&
                      (transaction?.userDetails?.membershipStatus ===
                        "VALID" ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                            transaction?.userDetails?.membershipStatus
                            ]
                          }
                          size="medium"
                          chipcolor="success"
                        />
                      ) : transaction?.userDetails?.membershipStatus ===
                        "RESIGNED" ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                            transaction?.userDetails?.membershipStatus
                            ]
                          }
                          size="medium"
                          chipcolor="error"
                        />
                      ) : transaction?.userDetails?.membershipStatus ===
                        "AWAITING_PAYMENT" ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                            transaction?.userDetails?.membershipStatus
                            ]
                          }
                          size="medium"
                          chipcolor="warning"
                        />
                      ) : transaction?.userDetails?.membershipStatus ===
                        "GRACE" ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                            transaction?.userDetails?.membershipStatus
                            ]
                          }
                          size="medium"
                          chipcolor="secondary"
                        />
                      ) : transaction?.userDetails?.membershipStatus ===
                        "CORPORATE" ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                            transaction?.userDetails?.membershipStatus
                            ]
                          }
                          size="medium"
                          chipcolor="primary"
                        />
                      ) : null)}
                  </TableCell>
                  <TableCell align="left">
                  <Box
                      sx={{
                        width: "300px",
                      }}
                    >
                    {transaction?.userDetails?.address?.fullAddress}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        width: "100px",
                      }}
                    >
                      {transaction?.eventDetails?.title ?? "Individual Membership"}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.eventDetails?.startDate
                      ? moment(transaction?.eventDetails?.startDate).format(
                        "DD/MM/YYYY"
                      )
                      : "-"}
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        width: "80px",
                      }}
                    >
                      $ {transaction?.fee?.toFixed(2)}
                    </Box>{" "}
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        width: "80px",
                      }}
                    >
                      $ {transaction?.GST?.toFixed(2)}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    $ {transaction?.creditCardSurcharge?.toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        width: "80px",
                      }}
                    >
                      $ {transaction?.totalDue?.toFixed(2)}
                    </Box>
                  </TableCell>
                  <TableCell align="left">$ {transaction?.paidAmount?.toFixed(2)}</TableCell>
                  <TableCell align="left">
                    {transaction?.isRefunded && <Chip
                      label="Refunded"
                      size="medium"
                      chipcolor="secondary"
                    />}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TransactionsReportsTable;
