import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($input: LoginAdminDTO!) {
    login(input: $input) {
      message
      admin {
        _id
        firstName
        familyName
        company
        industry
        email
        role
        status
        phone
        position
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
      }
      accessToken
      refreshToken
    }
  }
`;

export const REGISTER = gql`
  mutation Register($input: CreateAdminInputDTO!) {
    register(input: $input) {
      _id
      email
      familyName
      firstName
      phone
      role
      status
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: String!) {
    forgotPassword(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($input: ResetPasswordDTO!) {
    resetPassword(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($input: ChangePasswordDTO!) {
    changePassword(input: $input) {
      message
      status
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
