/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

/* gql hooks */
import useGQL from "hooks/useGQL";

// project imports
import MainCard from "ui-component/cards/MainCard";
import AdminEditForm from "./forms/edit-admin";
import AdminTable from "./tables";

/* custom hooks */
import { useDialog } from "./hooks/useDialogs";

// assets
import SearchIcon from "@mui/icons-material/Search";

/* variables imports */
import { defaultAdmin } from "./constants/variables";
import { Admin } from "./constants/types";
import Spinner from "components/spinner";
import useDebouncedSearch from "./hooks/useDebounceSearch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { gridSpacing } from "store/constant";
import { ArrangementOrder } from "types";
import { RootState, useSelector } from "store";

// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];

const AdminsList = () => {
  const [debouncedSearch] = useDebouncedSearch(
    (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | undefined
    ) => {
      if (event?.target) {
        setSearchText(event.target.value);
      }
    }
  );

  /* queries & mutations */
  const { GET_ALL_ADMINS } = useGQL();
  const { error, loading, data, refetch } = GET_ALL_ADMINS();
  const { sort } = useSelector((state: RootState) => state.table);
  /* local states */
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [adminSelected, setAdminSelected] = useState<Admin>(defaultAdmin);
  const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();
  const [meta, setMeta] = useState<Meta>();
  const navigate = useNavigate();
  const [filtersInput, setFiltersInput] = useState<string>("");
  const handleInputChange = (event: React.SyntheticEvent, newValue: string) => {
    setFiltersInput(newValue);
  };

  const [order, setOrder] = useState<ArrangementOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [searchText, setSearchText] = useState<string>("");

  const { GET_ADMIN_PROFILE } = useGQL();
  const { data: adminData } = GET_ADMIN_PROFILE();

  const STATUS_TYPE = [
    { label: "Active", id: 1 },
    { label: "Inactive", id: 2 },
    { label: "Pending", id: 3 },
  ];

  const statusMap = {
    Active: "active",
    Inactive: "inactive",
    Pending: "pending",
  };

  useEffect(() => {
    if (data?.getAdminList) {
      setAdmins(data.getAdminList?.adminList);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      input: {
        limit,
        skip,
        order: sort.order,
        orderBy: sort.orderBy,
        searchText,
        filterInput: statusMap[filtersInput],
      },
    });
  }, [rowsPerPage, page]);

  const handleRefetch = () => {
    refetch({
      input: {
        limit: meta?.limit,
        skip: meta?.skip,
        order: sort.order,
        orderBy: sort.orderBy,
        searchText,
        filterInput: statusMap[filtersInput],
      },
    });
  };

  useEffect(() => {
    setPage(0);
    handleRefetch();
  }, [searchText, filtersInput, rowsPerPage]);

  const handleEdit = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => {
    const selection = admins.find((admin) => admin._id === id);
    if (selection) {
      setAdminSelected(selection);
      navigate(`/admins/add?AdminId=${selection._id}`);
    }
  };

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const openSort = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  return (
    <>
      <MainCard content={false}>
        <Stack
          direction={{ sm: "row" }}
          alignItems={{ sm: "center" }}
          justifyContent={{ sm: "space-between" }}
          mt={2}
          sx={{ marginInline: "20px" }}
          gap={2}
        >
          <Typography variant="h4">Admin List</Typography>
          {adminData?.getUserProfile?.role === "SUPERADMIN" && (
            <Button variant="outlined" component={Link} to="/admins/add">
              Add admin
            </Button>
          )}
        </Stack>
        <hr />
        <CardContent>
          <Grid
            container
            direction="row"
            display="flex"
            spacing={gridSpacing}
            justifyContent="flex-end"
          >
            <Grid item xs={12} sm={9}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#004C8F" }}>
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 500, // Maximum character limit, including spaces
                }}
                fullWidth
                onChange={debouncedSearch}
                placeholder="Search Admin"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                disablePortal
                options={STATUS_TYPE}
                inputValue={filtersInput}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                  <TextField {...params} label="Status" />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>

        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <AdminTable {...{ admins, handleEdit, handleRefetch }} />
            <AdminEditForm
              {...{
                adminSelected,
                handleDialogClose,
                openDialog,
                listRefetch: handleRefetch,
              }}
            />

            {/* table pagination */}

            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getAdminList?.pagination.total / rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </>
  );
};

export default AdminsList;
