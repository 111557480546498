/* eslint no-underscore-dangle: 0 */
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';

import { Formik } from 'formik';

import { headCells } from '../constants/variables';
import { useDispatch, useSelector } from 'react-redux';
import { statusAttendeesMappingObj } from 'views/event/event-list/constants/types';
import { Link } from 'react-router-dom';
import { stateMapObject } from 'store/constant';
import { useEffect, useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { BookingStatusEnum } from '../constants/types';
import { useGQL } from 'views/event/hooks/useGQL';
import useSnackbar from 'hooks/common/useSnackbar';
import lang from 'constants/language';

import * as Yup from 'yup';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 700,
    xs: '90%',
  },
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
};

type Order = 'asc' | 'desc';
const AttendiesTable = ({ attendiesInformation, eventId, refetch }) => {
  const dispatch = useDispatch();
  const { handleOpenSnackbar } = useSnackbar();
  const { UPDATE_STATUS_OF_EVENT_ATTENDEE } = useGQL();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [handleUpdate, { data: update, loading, error }] =
    UPDATE_STATUS_OF_EVENT_ATTENDEE();

  const [cancelWithRefund, setCancelWithRefund] = useState(false); // Corrected order of state and setter

  const handleAttendeeStatus = async (
    id?: string,
    status?: string,
    input?: {
      note?: string;
    },
    cancelWithRefund?: boolean
  ) => {
    try {
      const response = await handleUpdate({
        variables: {
          eventBookingUpdate: {
            eventBookingId: eventId,
            attendeeId: id,
            status,
            note: input?.note,
            cancelWithRefund:
              status === BookingStatusEnum.CANCELLED ? cancelWithRefund : null,
          },
        },
      });
      setOpen(false);
      refetch();
      handleOpenSnackbar({
        message: lang.EVENT_BOOKING.UPDATE,
        alertType: 'success',
      });
    } catch (e: any) {
      handleOpenSnackbar({
        message: e.message,
        alertType: 'error',
      });
    }
  };

  const ActionButton = ({ attendee }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState('');

    const handleMenuActionClick = (event: any, attendeeId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(attendeeId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };
    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, attendee?._id)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {attendee?.status === 'AWAITING_PAYMENT' && (
            <MenuItem
              onClick={(e) =>
                handleAttendeeStatus(selected, BookingStatusEnum.PAID)
              }
            >
              {' '}
              Paid
            </MenuItem>
          )}
          {attendee?.status === 'PAID' && (
            <MenuItem
              onClick={(e) =>
                handleAttendeeStatus(
                  selected,
                  BookingStatusEnum.AWAITING_PAYMENT
                )
              }
            >
              {' '}
              Awaiting Payment
            </MenuItem>
          )}
          {(attendee?.status === 'PAID' ||
            attendee?.status === 'AWAITING_PAYMENT') && (
            <MenuItem
              onClick={(e) => {
                setOpen(true);
                setSelectedId(selected);
              }}
            >
              {' '}
              Cancel Attendee{' '}
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}

          <TableBody>
            {attendiesInformation.length !== 0 &&
              attendiesInformation.map((user) => (
                <TableRow key={user._id}>
                  <TableCell align="left">
                    {user?.userId ? (
                      <Link
                        to={`/individualUser/add?UserId=${user?.userId}`}
                        style={{ textDecoration: 'none', color: '#004C8F' }}
                      >
                        {`${user?.firstName} ${user?.lastName}`}
                      </Link>
                    ) : (
                      `${user?.firstName} ${user?.lastName}`
                    )}
                  </TableCell>
                  <TableCell align="left">{user?.email}</TableCell>
                  <TableCell align="left">
                    {moment(user?.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="left">
                    {' '}
                    {user.status === 'PAID' && (
                      <Chip
                        label={statusAttendeesMappingObj[user?.status]}
                        size="medium"
                        chipcolor="success"
                      />
                    )}
                    {user.status === 'AWAITING_PAYMENT' && (
                      <Chip
                        label={statusAttendeesMappingObj[user?.status]}
                        size="medium"
                        chipcolor="warning"
                      />
                    )}
                    {user.status === 'CANCELLED' && (
                      <Chip
                        label={statusAttendeesMappingObj[user?.status]}
                        size="medium"
                        chipcolor="error"
                      />
                    )}
                    {user.status === 'FREE' && (
                      <Chip
                        label={statusAttendeesMappingObj[user?.status]}
                        size="medium"
                        chipcolor="secondary"
                      />
                    )}
                    {user.status === 'REFUNDED' && (
                      <Chip
                        label={statusAttendeesMappingObj[user?.status]}
                        size="medium"
                        chipcolor="info"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {user?.address?.flatUnit}
                  </TableCell>
                  <TableCell align="center">
                    {user?.address?.streetNumber}
                  </TableCell>
                  <TableCell align="left">
                    {user?.address?.streetName}
                  </TableCell>
                  <TableCell align="left">{user?.address?.suburb}</TableCell>
                  <TableCell align="center">
                    {user?.address?.postcode}
                  </TableCell>
                  <TableCell align="left">
                    {stateMapObject[user?.address?.state]}
                  </TableCell>
                  <TableCell align="left">{user?.address?.country}</TableCell>
                  <TableCell align="left">{user?.note}</TableCell>
                  <TableCell align="center" sx={{ pr: 3 }}>
                    <ActionButton attendee={user} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Formik
              initialValues={{
                note: '',
              }}
              validationSchema={Yup.object().shape({
                note: Yup.string().required(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                const input = {
                  ...values,
                };

                setSubmitting(true);

                handleAttendeeStatus(
                  selectedId,
                  BookingStatusEnum.CANCELLED,
                  input,
                  cancelWithRefund
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{ position: 'absolute', top: '20px', right: '15px' }}
                  >
                    <IconButton onClick={() => setOpen(false)}>
                      <ClearIcon sx={{ fontSize: '1.5rem', color: 'red' }} />
                    </IconButton>
                  </div>
                  <Typography
                    variant="h3"
                    component="h1"
                    sx={{
                      marginBottom: '1.5rem',
                    }}
                  >
                    Reason For Cancel
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ maxWidth: '697px' }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Cancellation Note"
                        value={values.note}
                        name="note"
                        label="Cancellation Note"
                        multiline
                        minRows={3}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.note && errors.note && (
                        <FormHelperText error id="note-error">
                          {errors.note}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Box
                    sx={{ marginTop: '2em', display: 'flex', gap: '20px' }}
                    justifyContent="flex-end"
                  >
                    <Button
                      sx={{ color: 'white' }}
                      variant="contained"
                      disabled={isSubmitting ? true : false}
                      type="submit"
                      onClick={() => {
                        setCancelWithRefund(false);
                      }}
                    >
                      {isSubmitting ? 'Please wait...' : 'Cancel'}
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ color: 'white' }}
                      type="submit"
                      disabled={isSubmitting ? true : false}
                      onClick={() => {
                        setCancelWithRefund(true);
                      }}
                    >
                      {isSubmitting ? 'Please wait...' : 'Cancel With Refund'}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default AttendiesTable;
