/* eslint-disable import/prefer-default-export */

import { HeadCell } from 'types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    label: 'Event Name',
    align: 'left',
  },

  {
    id: 'startDate',
    numeric: false,
    label: 'Start Date',
    align: 'left',
  },
  {
    id: 'endDate',
    numeric: false,
    label: 'End Date',
    align: 'left',
  },
  {
    id: 'eventType',
    numeric: false,
    label: 'Type',
    align: 'left',
  },

  {
    id: 'status',
    numeric: false,
    label: 'Status',
    align: 'left',
  },
  {
    id: 'location',
    numeric: false,
    label: 'Location',
    align: 'center',
  },
  {
    id: 'Confirmedusers',
    numeric: false,
    label: ' Confirmed Users',
    align: 'center',
  },
  {
    id: 'Cancelledusers',
    numeric: false,
    label: 'Cancelled Users',
    align: 'center',
  },
];

export const defaultAdmin = {
  _id: '',
  eventType: '',
  startDate: '',
  endDate: '',
  status: '',
  location: '',
  Confirmedusers: '',
  Cancelledusers: '',
};

export const title = 'Delete Event';
export const description = 'Are you sure you want to delete ?';
