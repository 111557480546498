import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import { useLazyQuery } from "@apollo/client";
import { SEARCH } from "grapqhl/user/queries";
import { CircularProgress } from "@mui/material";
import {
  PersonTwoTone,
  ArticleTwoTone,
  EventTwoTone,
  Search,
  SearchOutlined,
} from "@mui/icons-material";
import { Link, NavLink, useLocation } from "react-router-dom";

interface searchResult {
  firstName: string;
  lastName: string;
  title: string;
  _id: string;
  type: string;
  membershipType: string;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
export default function Grouped() {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<[searchResult] | []>();
  const [query, setSearchQuery] = React.useState<string | null>(null);
  const [page, setPage] = React.useState(1);
  const params = new URLSearchParams(window.location.search);
  const searchParam = params.get("searchQuery") as string;
  const { pathname } = useLocation();

  const [searchRequest, { loading }] = useLazyQuery(SEARCH, {
    variables: {
      searchInput: { searchText: query, skip: (page - 1) * 3, limit: 3 },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      let searchResult = data.search.data.map((s) => ({
        ...s,
        type: s.type.charAt(0).toUpperCase() + s.type.slice(1),
      }));
      setOptions(searchResult);
    },
  });

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e5); // For demo purposes.

      if (active) {
        setOptions(options);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      setOptions(options);
    }
  }, [open]);

  React.useEffect(() => {
    searchRequest();
  }, [query]);

  React.useEffect(() => {
    if (!searchParam) {
      setSearchQuery(null);
    }
  }, [searchParam]);

  const initialValue = {
    firstName: "",
    lastName: "",
    title: query,
    _id: "",
    type: "",
    membershipType: "",
  };
  return (
    <Autocomplete
      id="grouped-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      options={options ? options : []}
      loading={loading}
      filterOptions={(x) => x}
      groupBy={(option) => option.type}
      getOptionLabel={(option) =>
        option.title
          ? option.title
          : "" + [option?.firstName, option?.lastName].join(" ")
      }
      value={query ? initialValue : null}
      popupIcon={false}
      onInputChange={(_, value) => {
        if (value.length === 0) {
          if (open) setOpen(false);
          setSearchQuery(null);
        } else {
          if (!open) setOpen(true);
          setSearchQuery(value);
        }
      }}
      sx={{ width: 350 }}
      renderOption={(props, option) => (
        <NavLink
          to={
            option.type === "Users"
              ? "individualUser/add?UserId=" +
                option._id +
                "&searchQuery=" +
                query
              : option.type === "Events"
              ? "events?eventId=" + option._id + "&searchQuery=" + query
              : "resources/view?ResourceId=" +
                option._id +
                "&searchQuery=" +
                query
          }
          style={{ textDecoration: "none", color: "#616161" }}
        >
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            key={option._id}
          >
            {option.type === "Users" && (
              <PersonTwoTone sx={{ width: "15px", marginRight: "10px" }} />
            )}
            {option.type === "Events" && (
              <EventTwoTone sx={{ width: "15px", marginRight: "10px" }} />
            )}
            {option.type === "Resources" && (
              <ArticleTwoTone sx={{ width: "15px", marginRight: "10px" }} />
            )}{" "}
            {option?.title}
            {option?.firstName} {option?.lastName}
          </Box>
        </NavLink>
      )}
      renderInput={(params) => (
        <TextField
          className="header-search"
          {...params}
          label="Search"
          // value={query}

          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                <SearchOutlined />
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            maxLength: 500, // Maximum character limit, including spaces
            onInput: (event: any) => {
              const inputValue = event.target.value;
              const wordCount = inputValue.trim().split(" ").length;
              if (wordCount > 100) {
                event.target.value = inputValue
                  .trim()
                  .split(" ")
                  .slice(0, 100)
                  .join(" ");
              }
            },
          }}
        />
      )}
      noOptionsText={"No Results Found"}
    />
  );
}
