import { useLazyQuery } from '@apollo/client';
import axios from 'axios';
import { GENERATE_SIGNED_URL_FOR_IMAGE } from 'grapqhl/event/mutation';
import { QUERY_GET_IMAGE_FROM_KEY_FETCH } from 'grapqhl/event/query';
import { GenerateSignedUrlInputDto } from 'types/event';
import { useGQL } from 'views/event/hooks/useGQL';
import useSnackbar from 'hooks/common/useSnackbar';

const useImageUpload = () => {
  const { handleOpenSnackbar } = useSnackbar();

  const { GENERATE_SIGNED_URL } = useGQL();

  const [handleFileUploadMutation] = GENERATE_SIGNED_URL();
  const [getUrlFromKeyOfImage] = useLazyQuery(QUERY_GET_IMAGE_FROM_KEY_FETCH);

  const handleFileUpload = async (
    file,
    key,
    fileType,
    thumb: boolean,
    thumbLarge?: boolean
  ) => {
    const bucketParams: GenerateSignedUrlInputDto = {
      key,
      contentType: fileType,
    };
    const { data: presignedUrlData } = await handleFileUploadMutation({
      variables: {
        input: bucketParams,
      },
    });
    if (!presignedUrlData?.generateSignedUrl) {
      handleOpenSnackbar({
        message: 'file upload failed',
        alertType: 'error',
      });
      return;
    }
    const response = await axios.put(presignedUrlData.generateSignedUrl, file, {
      headers: {
        'Content-Type': `${fileType}`,
      },
    });
    if (response.status !== 200) {
      handleOpenSnackbar({
        message: 'file upload failed',
        alertType: 'error',
      });
    }

    if (thumb) {
      const baseUrl =
        response.status === 200
          ? `${process.env.REACT_APP_AWS_BASE_URL_THUMB}/${key}`
          : null;
      return baseUrl;
    } else if (thumbLarge) {
      const baseUrl =
        response.status === 200
          ? `${process.env.REACT_APP_AWS_BASE_URL_LARGE_THUMB}/${key}`
          : null;
      return baseUrl;
    } else {
      const baseUrl =
        response.status === 200
          ? `${process.env.REACT_APP_AWS_BASE_URL}/${key}`
          : null;
      return baseUrl;
    }
  };

  return {
    handleFileUpload,
  };
};

export default useImageUpload;
