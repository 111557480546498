import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Spinner from "components/spinner";
import lang from "constants/language";
import { Formik } from "formik";
import useSnackbar from "hooks/common/useSnackbar";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CorporateMembership, NotesInputDto } from "types/event";
import { useGQL } from "views/individual-user/hooks/useGQL";
import * as Yup from "yup";
const NotesManagement = ({ membershipInformation, handleRefetch }) => {
  const { handleOpenSnackbar } = useSnackbar();
  const { CREATE_NOTE } = useGQL();
  const [handleNoteCreation, { data: update, loading }] = CREATE_NOTE();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("UserId") as string;
  useEffect(() => {
    if (update?.addUserNote) {
      handleOpenSnackbar({
        message: lang.NOTES.NOTES_CREATION,
        alertType: "success",
      });
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }
  const handleNoteCreate = async (
    values: Partial<NotesInputDto>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await handleNoteCreation({
        variables: {
          addUserNoteInput: {
            userId,
            ...values,
          },
        },
      });
      handleRefetch();
      setSubmitting(false);
    } catch (err: any) {
      handleOpenSnackbar({
        message: err,
        alertType: "error",
      });
      setSubmitting(false);
    }
  };
  return (
    <>
      <FormWrapper>
        <Formik
          initialValues={{
            description: "",
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string().required("Note is required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const input = {
              ...values,
            };
            setSubmitting(true);
            handleNoteCreate(input, setSubmitting);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            getFieldProps,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={9}>
                  <TextField
                    fullWidth
                    multiline
                    placeholder="Write note"
                    label="Note"
                    {...getFieldProps("description")}
                  />
                  {touched?.description && errors?.description && (
                    <FormHelperText error id="lastName-error">
                      {errors?.description}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={3} marginTop={"0.3rem"}>
                  <Button
                    variant="contained"
                    sx={{ color: "white", marginLeft: "1rem" }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Add note"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
};
const FormWrapper = styled.div`
  padding: 1rem;
`;
export default NotesManagement;
