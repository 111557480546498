import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useGQL from 'hooks/useGQL';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { GET_ADMIN_PROFILE } = useGQL();
  const { error, loading, data: adminData, refetch } = GET_ADMIN_PROFILE();

  const isAdminSuperAdmin = adminData?.getUserProfile?.role === 'SUPERADMIN';

  const filteredMenuItems = isAdminSuperAdmin
    ? menuItem.items // If SUPERADMIN, show all menu items
    : menuItem.items.map((menu) => {
        if (menu.id === 'adminsAndAccounts') {
          // Filter out "Payment Setting" from children
          return {
            ...menu,
            children: menu.children.filter(
              (child) => child.id !== 'paymentSetting'
            ),
          };
        } else {
          // For other parent menus, keep all children as is
          return menu;
        }
      });

  const navItems = filteredMenuItems?.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
