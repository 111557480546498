import * as Yup from 'yup';
export const furtherDetailValidation = Yup.object().shape({
  furtherDetails: Yup.object().shape({
    detailLeft: Yup.string().nullable(),
    detailRight: Yup.string().nullable(),
    singleDetail: Yup.string().nullable(),
  }),
  speaker: Yup.object().shape({
    speakers: Yup.array().of(Yup.string().required('Speaker is required')),
    term: Yup.string().nullable(),
  }),
});
