import { gql } from '@apollo/client';

export const UPDATE_ADMIN = gql`
  mutation UpdateAdmin($updateAdminId: String, $input: UpdateAdminDTO!) {
    updateAdmin(id: $updateAdminId, input: $input) {
      admin {
        email
        familyName
        firstName
        role
        status
        phone
        _id
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        company
        industry
        position
      }
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation RemoveAdmin($removeAdminId: String!, $status: String!) {
    removeAdmin(id: $removeAdminId, status: $status) {
      message
      admin {
        _id
      }
    }
  }
`;

export const CREATE_OR_UPDATE_SURCHARGE_VALUE = gql`
  mutation CreateAndUpdateSurcharge($input: UpdateSurchargeValue!) {
    createAndUpdateSurcharge(input: $input) {
      _id
      settingType
      paymentType {
        method
        value
        rate
      }
    }
  }
`;
