import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const validateFileSize = (event: any) => {
  if (event?.target?.files[0]) {
    const fileSize = event.target.files[0].size; // File size in bytes
    const maxSizeInBytes = 5 * 1024 * 1024; // 200KB = 200 * 1024 bytes

    if (fileSize > maxSizeInBytes) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'File size exceeds , please upload file size of 5 Mb max',
          variant: 'alert',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },

          alert: {
            color: 'error',
          },
          close: false,
        })
      );

      return false;
    }
    return true;
  }
};

export default validateFileSize;
