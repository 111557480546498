import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ANALYTICS_DATA } from "grapqhl/analytics/query";
import {
  ADD_ATTENDEE,
  CANCEL_EVENT_DATA,
  GENERATE_SIGNED_URL_FOR_IMAGE,
  PRICING_AND_BOOKING,
  UPDATE_ATTENDEE_STATUS,
  UPDATE_EVENT_INFORMATION,
  UPDATE_FURTHER_DETAILS,
} from "grapqhl/event/mutation";
import {
  GET_ACTION_ITEMS,
  GET_ACTION_ITEMS_LIST,
  GET_ALL_ATTENDIES_USER,
  GET_ALL_EVENTS,
  GET_ALL_INDIVIDUAL_USER_LIST,
  GET_ALL_SPEAKERS,
  GET_EVENT_INFORMATION,
  GET_UPCOMING_EVENTS,
  QUERY_GET_IMAGE_FROM_KEY_FETCH,
} from "grapqhl/event/query";
import {
  ActionItemsResponse,
  AddAttendeeUserInputDto,
  AttendeeAddOutputDto,
  CancelEventResponse,
  EventInformation,
  EventResponse,
  FurtherDetailResponse,
  GenerateSignedUrlInputDto,
  GetAttendiesList,
  GetEventListDto,
  getEventResponse,
  GetImageFromKey,
  GetSpeakersType,
  PricingAndBooking,
  PricingAndBookingResponse,
  SignedUrlResponse,
  SpeakerResponse,
  sysUserInputDto,
  UpdateFurtherDetails,
  updateStatusOfAttendee,
  UsersListResponse,
} from "types/event";
import {
  GetGoogleAnalyticsDTO,
  GoogleAnalyticsResponse,
} from "types/gql/analytics";
import { GetActionItemsDTO } from "views/actionItemList/constants/types";

export const useGQL = () => {
  const UPDATE_EVENT_INFORMATIONS = () =>
    useMutation<
      EventResponse,
      { eventId: string; input: Partial<EventInformation> }
    >(UPDATE_EVENT_INFORMATION);

  const GET_EVENT_INFORMATION_DETAILS = (docId: string) =>
    useQuery<getEventResponse, { docId: string }>(GET_EVENT_INFORMATION, {
      variables: { docId },
    });

  const GET_ALL_EVENT_LIST = (input: GetEventListDto = {}) =>
    useQuery(GET_ALL_EVENTS, { variables: { input } });

  const GET_UPCOMING_EVENT_LIST = (input: GetEventListDto = {}) =>
    useQuery(GET_UPCOMING_EVENTS, { variables: { input } });

  const GET_ALL_EVENTS_LAZILY = (input: GetEventListDto = {}) =>
    useLazyQuery(GET_ALL_EVENTS, { variables: { input } });

  const CREATE_AND_UPDATE_PURCHASE_AND_BOOKINGS = () =>
    useMutation<
      PricingAndBookingResponse,
      { eventId: string; input: Partial<PricingAndBooking> }
    >(PRICING_AND_BOOKING);

  const GET_SPEAKERS = (input: GetSpeakersType) =>
    useQuery<SpeakerResponse>(GET_ALL_SPEAKERS, {});

  const UPDATE_FURTHER_DETAIL = () =>
    useMutation<
      FurtherDetailResponse,
      { eventId: string; input: Partial<UpdateFurtherDetails> }
    >(UPDATE_FURTHER_DETAILS);

  const GENERATE_SIGNED_URL = () =>
    useMutation<
      SignedUrlResponse,
      { input: Partial<GenerateSignedUrlInputDto> }
    >(GENERATE_SIGNED_URL_FOR_IMAGE);

  const QUERY_GET_IMAGE_FROM_KEY = (key: string) =>
    useQuery<GetImageFromKey, { key: string }>(QUERY_GET_IMAGE_FROM_KEY_FETCH, {
      variables: {
        key,
      },
    });

  const CANCEL_EVENT = () => {
    useMutation<CancelEventResponse, { eventId: string }>(CANCEL_EVENT_DATA);
  };

  const GET_ALL_ATTENDIES_LIST = (
    eventId: string,
    input: GetAttendiesList = {}
  ) =>
    useQuery(GET_ALL_ATTENDIES_USER, { variables: { docId: eventId, input } });

  const GET_INDIVIDUAL_USERS_LIST = (input: sysUserInputDto = {}) =>
    useQuery<UsersListResponse>(GET_ALL_INDIVIDUAL_USER_LIST, {
      variables: { input },
    });

  const ADD_ATTENDEE_USER = () =>
    useMutation<
      AttendeeAddOutputDto,
      { docId: string; input: Partial<AddAttendeeUserInputDto> }
    >(ADD_ATTENDEE);

  const GET_ANALYTICS = (input: GetGoogleAnalyticsDTO) =>
    useQuery<
      GoogleAnalyticsResponse,
      { input: Partial<GetGoogleAnalyticsDTO> }
    >(GET_ANALYTICS_DATA, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });

  const UPDATE_STATUS_OF_EVENT_ATTENDEE = () =>
    useMutation<{
      eventBookingUpdate: Partial<updateStatusOfAttendee>;
    }>(UPDATE_ATTENDEE_STATUS);

  const GET_ACTION_ITEM = () =>
    useQuery<ActionItemsResponse>(GET_ACTION_ITEMS, {});

  const GET_ACTION_ITEM_LIST = () =>
    useQuery<ActionItemsResponse, { input: Partial<GetActionItemsDTO> }>(
      GET_ACTION_ITEMS_LIST,
      { variables: { input: {} }, notifyOnNetworkStatusChange: true }
    );

  return {
    UPDATE_EVENT_INFORMATIONS,
    CREATE_AND_UPDATE_PURCHASE_AND_BOOKINGS,
    GET_EVENT_INFORMATION_DETAILS,
    GET_SPEAKERS,
    UPDATE_FURTHER_DETAIL,
    GET_ALL_EVENT_LIST,
    GENERATE_SIGNED_URL,
    QUERY_GET_IMAGE_FROM_KEY,
    CANCEL_EVENT,
    GET_ALL_ATTENDIES_LIST,
    GET_INDIVIDUAL_USERS_LIST,
    ADD_ATTENDEE_USER,
    GET_ANALYTICS,
    GET_ALL_EVENTS_LAZILY,
    UPDATE_STATUS_OF_EVENT_ATTENDEE,
    GET_UPCOMING_EVENT_LIST,
    GET_ACTION_ITEM,
    GET_ACTION_ITEM_LIST,
  };
};
