import dayjs from 'dayjs';
import React from 'react';

export const formatDate = (date) => {
  // Current date
  const currentDate = new Date(date);

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const newDateTimeString = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}T00:00:00.000Z`;

  return newDateTimeString;
};
