/* eslint no-underscore-dangle: 0 */
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Admin, AdminRoleMap, AdminRoles, status } from '../constants/types';

import { headCells } from '../constants/variables';
import useGQL from 'hooks/useGQL';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from '../hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import Spinner from 'components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSort } from 'store/slices/table';
import { firstLetterUppercase } from 'utils/commonHelpers';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import TableErrorMessage from 'common/tableErrorMessage';

type Sort = { order: string; orderBy: string } | null;
type AdminTableProps = {
  admins: Admin[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

type Order = 'asc' | 'desc';
const AdminTable = ({ admins, handleEdit, handleRefetch }: AdminTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);

  const { GET_ADMIN_PROFILE, DELETE_ADMIN, FORGOT_PASS } = useGQL();
  const { getConfirmation } = useAlertDialog();
  const { handleOpenSnackbar } = useSnackbar();
  const [handleForgotPass, { loading, error, data }] = FORGOT_PASS();

  const adminStatus = {
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
  };

  useEffect(() => {
    if (data?.forgotPassword) {
      handleOpenSnackbar({
        message: `Reset password link send successfully`,
        alertType: 'success',
      });
      handleRefetch();
    }
  }, [data]);

  const {
    error: adminProfileError,
    loading: adminProfileLoading,
    data: adminProfileData,
  } = GET_ADMIN_PROFILE();

  const [
    handleAdminDelete,
    {
      error: adminDeleteError,
      loading: adminDeleteLoading,
      data: adminDeleteData,
    },
  ] = DELETE_ADMIN();

  useEffect(() => {
    handleRefetch();
  }, [order, orderBy]);

  const handleInactive = async (
    event: React.MouseEvent,
    id: string,
    status: string,
    userRole: string
  ) => {
    const isConfirm = await getConfirmation({
      title: ` Inactivate Admin`,
      message: `Are you sure you want to inactivate admin ?`,
    });
    if (isConfirm) {
      try {
        await handleAdminDelete({
          variables: {
            removeAdminId: id,
            status: 'inactive',
          },
        });
        handleOpenSnackbar({
          message: 'Inactivated successfully',
          alertType: 'success',
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };
  const handleActive = async (
    event: React.MouseEvent,
    id: string,
    status: string,
    userRole: string
  ) => {
    const isConfirm = await getConfirmation({
      title: ` Activate Admin`,
      message: `Are you sure you want to activate admin ?`,
    });
    if (isConfirm) {
      try {
        await handleAdminDelete({
          variables: {
            removeAdminId: id,
            status: 'active',
          },
        });
        handleOpenSnackbar({
          message: 'Activated successfully',
          alertType: 'success',
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  const handleSendPasswordReset = async (adminEmail) => {
    try {
      await handleForgotPass({ variables: { input: adminEmail } });
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: 'error' });
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const setOrder = isAsc ? 'desc' : 'asc';
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  const ActionButton = ({
    adminId,
    role,
    adminProfileData,
    adminEmail,
    status,
  }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState('');

    const handleMenuActionClick = (event: any, adminId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(adminId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };
    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, adminId)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {adminProfileData?.getUserProfile.role === 'SUPERADMIN' ||
            adminProfileData?.getUserProfile._id === adminId}{' '}
          {
            <MenuItem onClick={(event) => handleEdit(event, selected)}>
              {' '}
              Edit Details
            </MenuItem>
          }
          {(adminProfileData?.getUserProfile.role === 'SUPERADMIN' ||
            adminProfileData?.getUserProfile._id === adminId) &&
            (status === 'active' || status === 'pending') && (
              <MenuItem
                onClick={(event) =>
                  handleInactive(event, selected, role, status)
                }
              >
                InActive Admin
              </MenuItem>
            )}
          {(adminProfileData?.getUserProfile.role === 'SUPERADMIN' ||
            adminProfileData?.getUserProfile._id === adminId) &&
            (status === 'inactive' || status === 'pending') && (
              <MenuItem
                onClick={(event) => handleActive(event, selected, role, status)}
              >
                Active Admin
              </MenuItem>
            )}
          {adminProfileData?.getUserProfile.role === 'SUPERADMIN' ||
            adminProfileData?.getUserProfile._id === adminId}{' '}
          {
            <MenuItem onClick={(event) => handleSendPasswordReset(adminEmail)}>
              {' '}
              Send Password Reset
            </MenuItem>
          }
        </Menu>
      </>
    );
  };

  if (adminProfileLoading) {
    return <Spinner />;
  }

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner />
        </Box>
      )}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if ('action' === headCell.id) {
                  if (adminProfileData?.getUserProfile?.role === 'SUPERADMIN') {
                    return (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                      </TableCell>
                    );
                  }
                } else {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={(event) =>
                          handleRequestSort(event, headCell.id)
                        }
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {admins?.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              <>
                {admins.map((admin) => (
                  <TableRow key={admin._id}>
                    <TableCell align="left">{`${admin.firstName} ${admin.familyName}`}</TableCell>
                    <TableCell align="left">{admin.email}</TableCell>
                    <TableCell align="left">{admin.phone}</TableCell>
                    <TableCell align="left">
                      {admin.status === 'active' && (
                        <Chip
                          label={adminStatus[admin.status]}
                          size="medium"
                          chipcolor="success"
                        />
                      )}
                      {admin.status === 'inactive' && (
                        <Chip
                          label={adminStatus[admin.status]}
                          size="small"
                          chipcolor="error"
                        />
                      )}
                      {admin.status === 'pending' && (
                        <Chip
                          label={adminStatus[admin.status]}
                          size="medium"
                          chipcolor="warning"
                        />
                      )}
                    </TableCell>

                    {(adminProfileData?.getUserProfile?.role === 'SUPERADMIN' ||
                      adminProfileData?.getUserProfile?._id === admin._id) && (
                      <TableCell align="center" sx={{ pr: 3 }}>
                        <ActionButton
                          adminId={admin._id}
                          role={admin?.role}
                          adminProfileData={adminProfileData}
                          adminEmail={admin?.email}
                          status={admin?.status}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminTable;
