import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useTheme } from "styled-components";
import {
  EventStatus,
  EventTypes,
  eventTypeMappingObj,
  statusTypeMappingObj,
} from "./constants/types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import { DeliveryMode, EventType } from "store/constant";
import { Box, Stack } from "@mui/system";
import { useLazyQuery } from "@apollo/client";
import { GET_EVENT_REPORTS } from "grapqhl/eventReport/query";
import csvDownload from "json-to-csv-export";
import moment from "moment";
import { EventReportListOutput } from "types/event";
import { headCells } from "./constants/variables";
import Spinner from "components/spinner";
import exportFromJSON from "export-from-json";
import { Record } from "immutable";
import { useState } from "react";

const FilteModal = ({
  handleRefetch,
  total,
  loading,
  setFiltersInputData,
  filterInputData,
}) => {
  const theme = useTheme();
  const [exportClicked, setExportClicked] = useState(false);

  const formik = useFormik({
    initialValues: {
      // Define your initial form field values here
      searchText: "",
      eventType: "",
      status: "",
      from: "",
      to: "",
      modality: "",
    },

    onSubmit: (values, { resetForm }) => {
      // Handle form submission here
      const { eventType, status, searchText, modality, from, to } = values;
      setFiltersInputData({ ...values });
      handleRefetch();
      // resetForm(); // Manually reset the form after handling submission
    },
    enableReinitialize: true,
  });

  const handleResetEvent = () => {
    formik.resetForm(); // Reset the form fields
  };
  const formatDataForExport = (data: EventReportListOutput[]) => {
    const eventReportsdata = data.map((event) => {
      return {
        title: event?.title,
        startDate:
          (event.sessions.length &&
            moment(event?.sessions[0]?.startDate).format("DD/MM/YY")) ||
          undefined,
        endDate:
          (event.sessions.length &&
            moment(event.sessions[event.sessions.length - 1].endDate).format(
              "DD/MM/YY"
            )) ||
          undefined,
        eventType: eventTypeMappingObj[event?.eventType],
        status: statusTypeMappingObj[event?.status],
        location: event?.mainLocation ? event.mainLocation : undefined,

        confirmedUsers: event?.noOfConfirmedUsers,
        cancelledUsers: event?.noOfCancelledUsers,
      };
    });
    return eventReportsdata;
  };
  const [getEventReport] = useLazyQuery(GET_EVENT_REPORTS, {
    variables: {
      eventReportInput: {
        limit: total,
        skip: 0,
        searchText: filterInputData.searchText
          ? filterInputData.searchText
          : "",
        eventType:
          filterInputData.eventType === "" ? null : filterInputData.eventType,
        status: filterInputData.status === "" ? null : filterInputData.status,
        from: filterInputData.from === "" ? null : filterInputData.from,
        to: filterInputData.to === "" ? null : filterInputData.to,
        modality:
          filterInputData.modality === "" ? null : filterInputData.modality,
      },
    },
    onCompleted(data) {
      const eventReportsData = formatDataForExport(
        data?.getEventReport?.eventReports
      );

      const dataToExport = {
        data: eventReportsData,
        fileName: "Event Report",
        fields: {
          title: "Event Name",
          startDate: "Start Date",
          endDate: "End Date",
          eventType: "Event Type",
          status: "Status",
          location: "Location",
          confirmedUsers: "Confirmed",
          cancelledUsers: "Cancelled",
        },
        exportType: exportFromJSON.types.xls,
      };
      if (exportClicked) {
        exportFromJSON(dataToExport);
      }
      setExportClicked(false);
    },
    onError(error) {
      setExportClicked(false);
      console.log("error", error);
    },
  });

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner />
        </Box>
      )}
      {/* Formik form */}
      <form onSubmit={formik.handleSubmit}>
        {/* Form fields */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="searchText"
              name="searchText"
              fullWidth
              label="Event Name"
              value={formik.values.searchText}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 100, // Maximum character limit, including spaces
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="eventType"
              label="Event Type"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("eventType")}
            >
              {EventTypes.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="status"
              label="Event Status"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("status")}
            >
              {EventStatus.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {/* Add more fields as needed */}
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                value={formik.values.from ? new Date(formik.values.from) : null}
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "from",
                    newValue ? dayjs(newValue).toJSON() : null
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                minDate={new Date(formik.values.from)}
                disabled={formik?.values?.from ? false : true}
                value={formik.values.to ? new Date(formik.values.to) : null}
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "to",
                    newValue ? dayjs(newValue).toJSON() : null
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="modality"
              label="Delivery Mode"
              select
              fullWidth
              sx={{ width: "100%" }}
              {...formik.getFieldProps("modality")}
            >
              {DeliveryMode.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    padding: option.label === "" ? "15px" : undefined,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {/* Submit button */}
        <Grid container justifyContent="flex-end">
          <Stack
            direction="row"
            spacing={2}
            alignItems="right"
            sx={{ mt: "1rem" }}
          >
            {" "}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={(e) => {
                setExportClicked(false);
                e.stopPropagation(); // Prevent event propagation
                handleResetEvent();
              }}
            >
              Clear Filter
            </Button>
            <Button
              disabled={exportClicked}
              color="primary"
              onClick={(e) => {
                setExportClicked(true);
                e.stopPropagation(); // Prevent event propagation
                getEventReport();
              }}
            >
              Export{" "}
              {exportClicked && (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  sx={{ marginLeft: "0.5rem" }}
                />
              )}
            </Button>
            <Button
              // disabled={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {
                setExportClicked(false);
              }}
            >
              View
            </Button>
          </Stack>
        </Grid>
      </form>
    </>
  );
};
export default FilteModal;
