const initialState = {
  data: [],
};

export const pageDragIndexReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA": {
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    }
    case "UPDATE_WHOLE_ARRAY": {
      return {
        data: action.payload,
      };
    }
    case "DELETE_DATA":
      return {
        ...state,
        data: state.data.filter((item: any) => item.uuid !== action.payload),
      };

    case "RESET_PROMISE": {
      return { data: [] };
    }

    default:
      return state;
  }
};
