// third-party
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LoginState } from 'types';

type RegisterState = {
    isRegister: boolean;
};

type AuthState = LoginState & RegisterState;

const initialState: AuthState = {
    isLoggedIn: false,
    isRegister: false
};
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginState(state, action: PayloadAction<LoginState>) {
            const { isLoggedIn, accessToken, refreshToken } = action.payload;
            state.isLoggedIn = isLoggedIn;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            // state = { ...action.payload };
        },
        setRegisterState(state, action: PayloadAction<RegisterState>) {
            const { isRegister } = action.payload;
            state.isRegister = isRegister;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const { setLoginState, setRegisterState } = slice.actions;
