import { HeadCell } from "./types";

// table header options
export const headCells: HeadCell[] = [
  {
    id: "title",
    numeric: false,
    label: "Title",
    align: "left",
    sort: true,
  },
  {
    id: "author",
    numeric: false,
    label: "Author",
    align: "left",
    sort: true,
  },
  {
    id: "date",
    numeric: false,
    label: "Date Published",
    align: "left",
    sort: true,
  },

  {
    id: "status",
    numeric: false,
    label: "Status",
    align: "left",
    sort: true,
  },
  {
    id: "action",
    numeric: false,
    label: "Action ",
    align: "center",
    sort: false,
  },
];

export const initialValues = {
  Title: "",
  Author: "",
  DatePublished: "",
  status: "",
};
