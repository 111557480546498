/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Spinner from "components/spinner";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "hooks/common/useSnackbar";
import {
  AmlCertificates,
  country,
  CountryStates,
  CrmCertificates,
  Industries,
} from "store/constant";
import { CompanyInformation } from "types/company";
import lang from "constants/language";
import { useNavigate } from "react-router-dom";
import { individualUserSchena } from "./individualUserValidationSchema";
import { useGQL } from "./hooks/useGQL";
import { UserInformation } from "./types";

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const IndividualUserInformation = ({
  userInformation,
  refetchUserInformation,
}) => {
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();
  const { UPDATE_INDIVIDUAL_USER_INFORMATIONS, GET_END_DATE } = useGQL();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const userId = params.get("UserId") as string;

  refetchUserInformation();

  const [handleUpdate, { data: update, loading, error }] =
    UPDATE_INDIVIDUAL_USER_INFORMATIONS();
  useEffect(() => {
    if (update?.updateUserInformation) {
      handleOpenSnackbar({
        message: userId
          ? lang.INDIVIDUAL_USER.UPDATE
          : lang.INDIVIDUAL_USER.CREATE,
        alertType: "success",
      });

      navigate(
        `/individualUser/add?UserId=${update?.updateUserInformation?.user?._id}`
      );
    }
  }, [update]);

  const handleUserInformation = async (
    values: Partial<UserInformation>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const input = { ...values };
      delete input._id;
      await handleUpdate({
        variables: {
          updateUserInformationId: userId,
          input: {
            ...input,
            email: values?.email?.toLowerCase(),
            phone: values?.phone?.toString(),
            timeInProfession: values?.timeInProfession?.toString(),
          },
        },
      });
      refetchUserInformation();
      setSubmitting(false);
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };

  return (
    <>
      <FormWrapper>
        <Formik
          enableReinitialize
          initialValues={{
            company: userInformation?.company || "",
            firstName: userInformation?.firstName || "",
            lastName: userInformation?.lastName || "",
            phone: userInformation?.phone || "",
            email: userInformation?.email || "",
            isGuestUser: userInformation?.isGuestUser || false,
            position: userInformation?.position || "",
            industry: userInformation?.industry || "",
            timeInProfession: userInformation?.timeInProfession || "",
            certification: {
              crm: userInformation?.certification?.crm || "",
              aml: userInformation?.certification?.aml || "",
            },
            address: {
              flatUnit: userInformation?.address?.flatUnit || null,
              streetName: userInformation?.address?.streetName || "",
              streetNumber: userInformation?.address?.streetNumber || "",
              postcode: userInformation?.address?.postcode || "",
              suburb: userInformation?.address?.suburb || "",
              country: userInformation?.address?.country || "",
              state: userInformation?.address?.state || "",
            },
          }}
          onSubmit={(values, { setSubmitting }) => {
            /* make data sync with update userDTO */
            const input = {
              ...values,
            };
            setSubmitting(true);
            handleUserInformation(input, setSubmitting);
          }}
          validationSchema={individualUserSchena}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            handleChange,
            getFieldProps,
            setFieldValue,

            /* and other goodies */
          }) => (
            <form onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="First Name"
                    label="First Name"
                    {...getFieldProps("firstName")}
                  />
                  {touched.firstName && errors.firstName && (
                    <FormHelperText error id="firstName-error">
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="Family Name"
                    label="Family Name"
                    {...getFieldProps("lastName")}
                  />
                  {touched.lastName && errors.lastName && (
                    <FormHelperText error id="lastName-error">
                      {errors.lastName}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="Mobile"
                    label="Mobile"
                    {...getFieldProps(`phone`)}
                  />
                  {touched.phone && errors.phone && (
                    <FormHelperText error id="phone-error">
                      {errors.phone}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    label="Email"
                    {...getFieldProps("email")}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="email-error">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Company Name"
                    label="Company Name"
                    {...getFieldProps("company")}
                  />
                  {touched.company && errors.company && (
                    <FormHelperText error id="company-error">
                      {errors.company}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="position"
                    label="Position"
                    fullWidth
                    sx={{ width: "100%" }}
                    {...getFieldProps("position")}
                  ></TextField>
                  {touched.position && errors.position && (
                    <FormHelperText error id="position-error">
                      {errors.position}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="industry"
                    label="Industry"
                    select
                    fullWidth
                    sx={{ width: "100%" }}
                    {...getFieldProps("industry")}
                  >
                    {Industries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {touched.industry && errors.industry && (
                    <FormHelperText error id="industry-error">
                      {errors.industry}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    InputProps={{
                      inputProps: { step: 0.01 },
                      endAdornment: (
                        <InputAdornment position="end">Years</InputAdornment>
                      ),
                    }}
                    placeholder="Time in profession"
                    label="Time in profession"
                    {...getFieldProps("timeInProfession")}
                  />
                  {touched.timeInProfession && errors.timeInProfession && (
                    <FormHelperText error id="timeInProfession-error">
                      {errors.timeInProfession}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          userId && userInformation?.hasPaidJoiningFee
                            ? true
                            : false
                        }
                        checked={values.isGuestUser === true ? true : false}
                        {...getFieldProps(`isGuestUser`)}
                        onChange={(e) => {
                          setFieldValue(`isGuestUser`, e.target.checked);
                        }}
                        name="checked"
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="subtitle1">
                        Is Guest User?
                      </Typography>
                    }
                  />
                  <FormHelperText error id="isGuestUser-error">
                    {errors?.isGuestUser}
                  </FormHelperText>
                </Grid>

                <Grid sx={{ marginTop: "15px" }} item xs={12}>
                  <Typography variant="h4">Certifications</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="crm"
                    label="CRM"
                    select
                    fullWidth
                    sx={{ width: "100%" }}
                    {...getFieldProps("certification.crm")}
                  >
                    {CrmCertificates.map((option) => (
                      <MenuItem
                        sx={{
                          padding: option.label === "" ? "15px" : undefined,
                        }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  {touched.certification?.crm && errors?.certification?.crm && (
                    <FormHelperText error id="certification-crm-error">
                      {errors?.certification?.crm}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="aml"
                    label="AML"
                    select
                    fullWidth
                    sx={{ width: "100%" }}
                    {...getFieldProps("certification.aml")}
                  >
                    {AmlCertificates.map((option) => (
                      <MenuItem
                        sx={{
                          padding: option.label === "" ? "15px" : undefined,
                        }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  {touched.certification?.aml && errors?.certification?.aml && (
                    <FormHelperText error id="certification-aml-error">
                      {errors?.certification?.aml}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid sx={{ marginTop: "15px" }} item xs={12}>
                  <Typography variant="h4">Postal Address</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Enter Flat Unit"
                    label="Flat Unit"
                    {...getFieldProps("address.flatUnit")}
                  />
                  {touched.address?.flatUnit && errors.address?.flatUnit && (
                    <FormHelperText error id="flatUnit-error">
                      {errors.address?.flatUnit}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Enter Street Number"
                    label="Street Number"
                    {...getFieldProps("address.streetNumber")}
                  />
                  {touched.address?.streetNumber &&
                    errors.address?.streetNumber && (
                      <FormHelperText error id="streetNumber-error">
                        {errors.address?.streetNumber}
                      </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Enter Street Name"
                    label="Street Name"
                    {...getFieldProps("address.streetName")}
                  />
                  {touched.address?.streetName &&
                    errors.address?.streetName && (
                      <FormHelperText error id="streetName-error">
                        {errors.address.streetName}
                      </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Enter Suburb"
                    label="Suburb"
                    {...getFieldProps("address.suburb")}
                  />
                  {touched.address?.suburb && errors.address?.suburb && (
                    <FormHelperText error id="suburb-error">
                      {errors.address?.suburb}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    // type={'number'}
                    placeholder="Enter Post Code"
                    // InputProps={{ inputProps: { min: 0, maxLength: 5 } }}
                    label="Postcode"
                    {...getFieldProps("address.postcode")}
                  />
                  {touched.address?.postcode && errors.address?.postcode && (
                    <FormHelperText error id="postcode-error">
                      {errors.address?.postcode}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="state"
                    label="State"
                    select
                    fullWidth
                    sx={{ width: "100%" }}
                    {...getFieldProps("address.state")}
                  >
                    {CountryStates.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  {touched?.address?.state && errors?.address?.state && (
                    <FormHelperText error id="industry-error">
                      {errors?.address?.state}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {" "}
                  <TextField
                    id="country"
                    label="Country"
                    select
                    fullWidth
                    {...getFieldProps("address.country")}
                  >
                    {country.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {touched.address?.country && errors.address?.country && (
                    <FormHelperText error id="country-error">
                      {errors.address?.country}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              {/**session end */}

              <Box sx={{ mt: "2rem" }}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{ color: "white" }}
                >
                  {isSubmitting ? "Please wait..." : "Save Changes"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default IndividualUserInformation;
