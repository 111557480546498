/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

import { headCells } from '../constants/variables';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from '../hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSort } from 'store/slices/table';
import Spinner from 'components/spinner';
import { useNavigate } from 'react-router-dom';
import { resourceInformation } from 'types/resource';
import moment from 'moment';
import { useGQL } from 'views/resources/hooks/useGQL';
import lang from 'constants/language';
import Chip from 'ui-component/extended/Chip';
import TableErrorMessage from 'common/tableErrorMessage';

type Sort = { order: string; orderBy: string } | null;
type ResourceTableProps = {
  resourceInformationData: resourceInformation[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

type Order = 'asc' | 'desc';
const ResourceTable = ({
  resourceInformationData,
  handleEdit,
  handleRefetch,
}: ResourceTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();

  const { getConfirmation } = useAlertDialog();

  const { UPDATE_RESOURCE_INFO, DUPLICATE_RESOURCE } = useGQL();
  const [handleDeleteUser, { data: update, loading }] = UPDATE_RESOURCE_INFO();
  const [handleDuplicateResource] = DUPLICATE_RESOURCE();

  const [resourceData, setResourceData] = useState<resourceInformation[]>(
    resourceInformationData
  );

  const handleDelete = async (event: React.MouseEvent, id: string) => {
    const isConfirm = await getConfirmation({
      title: 'Delete Resource Information',
      message: 'Are you sure you want to delete resource information ?',
    });
    if (isConfirm) {
      try {
        await handleDeleteUser({
          variables: {
            resourceId: id,
            input: {
              isDeleted: true,
            },
          },
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  const params = new URLSearchParams(window.location.search);
  const userId = params.get('UserId') as string;

  const statusMappingObj = {
    PUBLISHED: 'Published',
    DRAFT: 'Draft',
  };

  useEffect(() => {
    if (update?.updateResource) {
      handleOpenSnackbar({
        message: lang.RESOURCE.DELETE,
        alertType: 'success',
      });
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const setOrder = isAsc ? 'desc' : 'asc';
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  const ActionButton = ({ resourceId, onDuplicate }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState('');
    const [data, setData] = useState('');

    const handleMenuActionClick = (event: any, resourceId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(resourceId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };

    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, resourceId)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            onClick={(e) =>
              navigate(`/resources/view?ResourceId=${resourceId}`)
            }
          >
            {' '}
            View
          </MenuItem>
          <MenuItem onClick={(e) => onDuplicate(resourceId)}>
            {' '}
            Duplicate
          </MenuItem>
          <MenuItem onClick={(e) => handleEdit(e, selected)}> Edit</MenuItem>
          <MenuItem onClick={(e) => handleDelete(e, selected)}>
            {' '}
            Delete
          </MenuItem>
        </Menu>
      </>
    );
  };

  //insert duplicated data from frontend
  const handleDuplicate = async (resourceId) => {
    const isConfirm = await getConfirmation({
      title: 'Duplicate Resource Information',
      message: 'Are you sure you want to duplicate resource information',
    });
    if (isConfirm) {
      const resourceIndex = resourceData.map((r) => r._id).indexOf(resourceId);
      const data = [...resourceData];
      data.splice(resourceIndex + 1, 0, data[resourceIndex]);
      setResourceData(data);

      try {
        const response: any = await handleDuplicateResource({
          variables: {
            resourceId,
          },
        });
        handleRefetch();

        handleOpenSnackbar({
          message: response?.data?.duplicateResource?.message,
          alertType: 'success',
        });
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };
  if (!resourceData) {
    return null;
  }
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === 'action') {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {resourceInformationData.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              resourceInformationData.map((resource) => (
                <TableRow key={resource._id}>
                  <TableCell align="left">{resource?.title}</TableCell>
                  <TableCell align="left">{resource?.author}</TableCell>
                  <TableCell align="left">
                    {moment(resource?.date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="left">
                    {resource?.isDeleted !== true ? (
                      resource?.status && statusMappingObj[resource?.status]
                    ) : (
                      <Chip label="Removed" size="medium" chipcolor="error" />
                    )}
                  </TableCell>

                  <TableCell align="center" sx={{ pr: 3 }}>
                    <ActionButton
                      resourceId={resource?._id}
                      onDuplicate={(resourceId) => handleDuplicate(resourceId)}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResourceTable;
