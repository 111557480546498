/* eslint-disable import/prefer-default-export */

import { HeadCell } from 'types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    label: 'Event Name',
    align: 'left',
  },
  {
    id: 'eventType',
    numeric: false,
    label: 'Type',
    align: 'left',
  },
  {
    id: 'startDate',
    numeric: false,
    label: 'Start Date',
    align: 'left',
  },
  {
    id: 'endDate',
    numeric: false,
    label: 'End Date',
    align: 'left',
  },
  {
    id: 'time',
    numeric: false,
    label: 'Time',
    align: 'center',
  },
  {
    id: 'seats',
    numeric: true,
    label: 'Seats',
    align: 'center',
  },
  {
    id: 'cpdPoints',
    numeric: true,
    label: 'CPD Points',
    align: 'center',
  },
  {
    id: 'availability',
    numeric: false,
    label: 'Availability',
    align: 'left',
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    align: 'left',
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action ',
    align: 'center',
  },
];

export const defaultAdmin = {
  _id: '',
  eventType: '',
  startDate: '',
  endDate: '',
  time: '',
  seats: '',
  cpdPoints: '',
  availability: '',
  status: '',
};

export const title = 'Delete Event';
export const description = 'Are you sure you want to delete ?';
