import { HeadCell } from './types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'note',
    numeric: true,
    label: 'Note',
    align: 'left',
    sort: false,
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Date Of Note',
    align: 'center',
    sort: false,
  },
  {
    id: 'status',
    numeric: false,
    label: 'Member Status',
    align: 'right',
    sort: true,
  },
];

export const initialValues = {
  note: '',
  createdAt: '',
  status: '',
};
