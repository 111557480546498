export type Admin = {
  _id: string;
  firstName: string;
  familyName: string;
  phone: string;
  email: string;
  role: string;
  status: string;
};

export type HeadCell = {
  id: string;
  numeric: boolean;
  label: string;
  disablePadding?: string | boolean | undefined;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

export enum AdminRoles {
  SUPER_ADMIN = 'superAdmin',
  ADMIN = 'admin',
  EDITOR = 'editor',
}

export const AdminRoleMap = {
  EDITOR: 'Editor',
  ADMIN: 'Admin',
};

export type RegisterType = {
  firstName: string;
  familyName: string;
  email: string;
  phone: string;
  role: string;
};

export const adminRoleMap = {
  admin: 'ADMIN',
  editor: 'EDITOR',
};

export const userRole = [
  {
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    value: 'EDITOR',
    label: 'Editor',
  },
];

// review state options
export const status = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
];
