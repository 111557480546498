import { useMutation, useQuery } from "@apollo/client";
import { GET_EVENT_REPORTS } from "grapqhl/eventReport/query";
import {
  GET_MEMBERS_REPORT,
  GET_MEMBERS_REPORT_FOR_EXPORT,
} from "grapqhl/membersReport/query";
import { EventReportInputDto } from "types/event";
import {
  memberReportInputDto,
  memberReportInputForExportDto,
} from "types/members";

export const useGQL = () => {
  const GET_ALL_MEMBERS_REPORT = (
    getMemberListReportInput: memberReportInputDto = {}
  ) =>
    useQuery(GET_MEMBERS_REPORT, { variables: { getMemberListReportInput } });

  const GET_ALL_MEMBERS_REPORT_FOR_EXPORTS = (
    getMemberListReportInputForExport: memberReportInputForExportDto = {}
  ) =>
    useQuery(GET_MEMBERS_REPORT_FOR_EXPORT, {
      variables: {},
    });
  return {
    GET_ALL_MEMBERS_REPORT,
    GET_ALL_MEMBERS_REPORT_FOR_EXPORTS,
  };
};
