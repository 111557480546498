/* eslint-disable import/prefer-default-export */

import { HeadCell } from 'types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'memberNumber',
    numeric: false,
    label: 'Member Number',
    align: 'left',
  },

  {
    id: 'firstName',
    numeric: false,
    label: 'First Name',
    align: 'left',
  },
  {
    id: 'lastName',
    numeric: false,
    label: 'Last Name',
    align: 'left',
  },
  {
    id: 'address.fullAddress',
    numeric: false,
    label: 'Full Address',
    align: 'left',
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    align: 'left',
  },
  {
    id: 'membershipType',
    numeric: false,
    label: 'Membership Type',
    align: 'left',
  },

  {
    id: 'position',
    numeric: false,
    label: 'Position',
    align: 'left',
  },
  {
    id: 'company',
    numeric: false,
    label: 'Company',
    align: 'left',
  },
];

export const defaultAdmin = {
  _id: '',
  memberNumber: '',
  firstName: '',
  lastName: '',
  status: '',
  membershipType: '',
  position: '',
  fullAddress: '',
  company: '',
};

export const title = 'Delete Event';
export const description = 'Are you sure you want to delete ?';
