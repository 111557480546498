import React, { useEffect, useState } from 'react';
// material-ui
import {
  // Checkbox,
  Divider,
  Grid,
  TextField,
  // FormGroup,
  // FormControlLabel,
  FormHelperText,
  // Typography,
  MenuItem,
  Box,
  Button,
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
// import { gridSpacing } from 'store/constant';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import { useNavigate } from 'react-router-dom';
import useGQL from 'hooks/useGQL';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import Snackbar from 'ui-component/extended/Snackbar';
import { RootState } from 'store';
import { RegisterType, userRole as role } from '../constants/types';
import styled from 'styled-components';
import { firstLetterUppercase } from 'utils/commonHelpers';
import Spinner from 'components/spinner';
import useSnackbar from 'hooks/common/useSnackbar';

const Resetpassword = ({ adminDataInformation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector((state: RootState) => state.snackbar);

  const { FORGOT_PASS } = useGQL();

  const params = new URLSearchParams(window.location.search);
  const AdminId = params.get('AdminId') as string;

  const { handleOpenSnackbar } = useSnackbar();
  const [handleForgotPass, { loading, error, data }] = FORGOT_PASS();

  useEffect(() => {
    if (data?.forgotPassword) {
      handleOpenSnackbar({
        message: `Reset password link send successfully`,
        alertType: 'success',
      });
      navigate('/admins/list');
    }
  }, [data]);

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner />
        </Box>
      )}
      {open && <Snackbar />}
      <Formik
        enableReinitialize
        initialValues={{
          email: adminDataInformation?.email,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required('Email is required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            try {
              await handleForgotPass({ variables: { input: values?.email } });
            } catch (err: any) {
              openSnackbar({ message: err.message, alertType: 'error' });
            }

            setSubmitting(false);
          } catch (err: any) {
            dispatch(
              openSnackbar({
                open: true,
                message: err?.message,
                anchorOrigin: { horizontal: 'center' },
                variant: 'alert',
                alert: {
                  color: 'error',
                },
              })
            );

            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <MainCard title={'Send Password Reset Link'}>
                <GridContainer container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter email address"
                      label="Email"
                      disabled
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="email-error">
                        {firstLetterUppercase(errors.email)}
                      </FormHelperText>
                    )}
                  </Grid>
                </GridContainer>
              </MainCard>
              <Box sx={{ mt: '1rem' }}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Send Link
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export const GridContainer = styled(({ ...props }) => <Grid {...props} />)`
  width: 100%;
  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 1440px) {
    width: 40%;
  }
`;
export default Resetpassword;
