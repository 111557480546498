export type Admin = {
  _id: string;
  name: string;
  email: string;
  phone: string;
  role: string;
  status: string;
};

export type HeadCell = {
  id: string;
  numeric: boolean;
  label: string;
  disablePadding?: string | boolean | undefined;
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
};

export enum role {
  SUPER_ADMIN = "superAdmin",
}

export type RegisterType = {
  name: string;
  email: string;
  phone: string;
  role: string;
};

export const userRole = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "editor",
    label: "Editor",
  },
];

// review state options
export const status = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "pending",
    label: "Pending",
  },
];

export const EventType = [
  {
    value: "EVENT",
    label: "Event",
  },
  {
    value: "COURSE",
    label: "Course",
  },
  {
    value: "DISCUSSION_GROUP",
    label: "Discussion Group",
  },
];

export const eventTypeMappingObj: any = {
  EVENT: "Event",
  COURSE: "Course",
  DISCUSSION_GROUP: "Discussion Group",
};

export const statusTypeMappingObj: any = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  REMOVED: "Removed",
};

export const statusAttendeesMappingObj: any = {
  AWAITING_PAYMENT: "Awaiting Payment",
  PAID: "Paid",
  CANCELLED: "Cancelled",
  FREE: "Free",
  REFUNDED: "Refunded",
};
