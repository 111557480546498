/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* gql hooks */

// project imports
import MainCard from "ui-component/cards/MainCard";
import { RootState, useDispatch, useSelector } from "store";
import { setPage, setRowsPerPage, setSearchText } from "store/slices/table";

/* custom hooks */
import { useDialog } from "./hooks/useDialogs";
import _debounce from "lodash.debounce";

// assets
import SearchIcon from "@mui/icons-material/Search";

/* variables imports */
import Spinner from "components/spinner";
import useDebouncedSearch from "./hooks/useDebounceSearch";
import { gridSpacing } from "store/constant";
import { Stack, width } from "@mui/system";
import { useGQL } from "views/individual-user/hooks/useGQL";
import { getCpdListResponse } from "views/individual-user/types";
import moment from "moment";
import PopModalRejectionReason from "common/reason-popmodal";
import AddCpdModal from "../Add-cpd-modal";
import { useLazyQuery } from "@apollo/client";
import { GET_CPD_INFORMATION } from "grapqhl/individualUser/query";
import CPDListTable from "./tables";
import DateRangePicker from "rsuite/DateRangePicker";
import dayjs from "dayjs";
import useSnackbar from "hooks/common/useSnackbar";
import lang from "constants/language";
import useAlertDialog from "hooks/common/useAlertDialog";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputLabel from "ui-component/extended/Form/InputLabel";
const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 25, label: 25 },
];
const INDIVIDUAL_USER_STATUS = [
  { label: "Valid", value: "Valid" },
  { label: "Awaiting Payment", value: "Awaiting Payment" },
  { label: "Grace", value: "Grace" },
  { label: "Resigned", value: "Resigned" },
  { label: "Corporate", value: "Corporate" },
];
const cancelButtonStyle = {
  background: "none",
  color: "#212121",
  border: "1px solid #212121",
  borderRadius: "5",
  minWidth: "0px",
  width: "120px",
  maxHeight: "44px",
};

// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const CPDViewAndList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [openCpdModal, setCpdModal] = useState<boolean>(false);
  const [searchTextInput, setSearchTextInput] = useState<string>("");
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("UserId") as string;
  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const styles = { width: 260, display: "block", marginBottom: 10 };

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [debouncedSearch] = useDebouncedSearch(
    (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | undefined
    ) => {
      if (event?.target) {
        setSearchTextInput(event.target.value);
      }
    }
  );

  const dispatch = useDispatch();

  /* queries & mutations */
  const { GET_CPD_POINTS, UPDATE_CPD_REQ_STATUS } = useGQL();
  const { error, loading, data, refetch } = GET_CPD_POINTS({
    skip: 0,
    searchText: "",
    limit: 15,
    userId,
  });

  const { searchText, page, rowsPerPage, sort } = useSelector(
    (state: RootState) => state.table
  );

  /* local states */
  const [cpdList, setCpdList] = useState<getCpdListResponse[]>([]);
  const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();
  const [meta, setMeta] = useState<Meta>();
  const [mode, setMode] = useState("create");
  const [cpdId, setCpdId] = useState("");
  const [cpdInfo, setCpdInfo] = useState<getCpdListResponse[]>();
  const [handleUpdate, { data: updatedData, loading: updateLoading }] =
    UPDATE_CPD_REQ_STATUS();
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();

  const [getCpdInformation, { refetch: refetchCpdInfo }] =
    useLazyQuery(GET_CPD_INFORMATION);

  useEffect(() => {
    if (data?.getAllCpdPoints?.cpdList) {
      setCpdList(data?.getAllCpdPoints?.cpdList);
    }
  }, [data?.getAllCpdPoints?.cpdList]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });

    refetch({
      input: {
        limit,
        skip,
        userId,
        from: startDate === "" ? null : startDate,
        to: endDate === "" ? null : endDate,
        searchText: searchTextInput,
      },
    });
  }, [rowsPerPage, page, searchTextInput, startDate, endDate]);

  const handleRefetch = () => {
    const limit = rowsPerPage;
    dispatch(setPage(0));
    const skip = page > 0 ? limit * page : 0;
    refetch({
      input: {
        limit: meta?.limit,
        skip,
        searchText: searchTextInput,
        userId,
      },
    });
  };

  const [anchor, setAnchor] = useState(null);

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };

  useEffect(() => {
    handleRefetch();
  }, [searchTextInput]);

  const handleChangePage = (event, newPage: number) => {
    dispatch(setPage(newPage - 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    }
  };

  const pendngData = cpdList.filter(
    (item) => item.status === "PENDING" && item.eventId === null
  );

  const handleEdit = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => {
    const selection = cpdList.find((event) => event._id === id);
    setMode("update");
    if (selection) {
      setCpdId(selection._id);
      const { data, loading } = await getCpdInformation({
        variables: { cpdId: selection._id },
      });
      if (data?.getCpdPointDetail) {
        setCpdInfo(data?.getCpdPointDetail);
        setCpdModal(true);
      }
    }
  };

  const handleCpdApproval = async (cpdId: string) => {
    const isConfirm = await getConfirmation({
      title: "Update CPD Status",
      message: "Are you sure you want to approve the CPD request?",
    });
    if (isConfirm) {
      await handleUpdate({
        variables: {
          updateCpdStatusInput: {
            cpdId: cpdId,
            status: "APPROVED",
          },
        },
      });
      refetch();
    }
  };
  useEffect(() => {
    if (updatedData?.updateCpdStatus) {
      handleOpenSnackbar({
        message: lang.CPD.CREATED,
        alertType: "success",
      });
      setCpdModal(false);
    }
  }, [updatedData]);
  if (loading) {
    <Spinner />;
  }

  return (
    <>
      {pendngData.length > 0 && (
        <MainCard
          title="CPD Requests"
          content={false}
          sx={{
            position: "relative",
            backgroundColor: "#F4F0EC",
            marginBottom: "20px",
          }}
        >
          <Grid item xs={12}>
            <Stack direction="column" marginLeft="1.5rem">
              <Typography
                sx={{ marginBottom: "0.5rem" }}
                variant="h5"
                component="h1"
              >
                {moment(new Date()).format("DD/MM/YY")}
              </Typography>
              <Typography
                sx={{ marginBottom: "0.5rem" }}
                variant="h5"
                component="h1"
              >
                Event Name: {pendngData[0]?.eventName}
              </Typography>
              <Typography
                sx={{ marginBottom: "0.5rem" }}
                variant="h5"
                component="h1"
              >
                Event Description: {pendngData[0]?.description}
              </Typography>
              <Typography
                sx={{ marginBottom: "0.5rem" }}
                variant="h5"
                component="h1"
              >
                Date Of Event:{" "}
                {moment(pendngData[0]?.eventDate).format("DD/MM/YY")}
              </Typography>
              <Typography
                sx={{ marginBottom: "0.5rem" }}
                variant="h5"
                component="h1"
              >
                No Of Units Event Hours: {pendngData[0]?.units}
              </Typography>
              <Typography
                sx={{ marginBottom: "0.5rem" }}
                variant="h5"
                component="h1"
              >
                Proof To Be Supplied:
              </Typography>
            </Stack>

            <Grid container justifyContent="flex-end" marginBottom="1rem">
              <Stack direction="row" spacing={2} marginRight="1rem">
                <Button
                  color="primary"
                  // onClick={handleCancel}
                  sx={cancelButtonStyle}
                  onClick={(e) => {
                    setOpen(true);
                  }}
                >
                  Non Approval
                </Button>
                <Button
                  // disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleCpdApproval(pendngData[0]?._id);
                  }}
                >
                  Approve Request
                </Button>
              </Stack>
              <PopModalRejectionReason
                open={open}
                setOpen={setOpen}
                cpdInformation={pendngData[0]?._id}
                refetch={refetch}
              />
            </Grid>
          </Grid>
        </MainCard>
      )}
      <MainCard content={false}>
        <CardContent>
          <Stack direction="row" flexWrap="wrap" gap={2}>
            <TextField
              sx={{ width: "800px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#004C8F" }}>
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 100, // Maximum character limit, including spaces
              }}
              onChange={debouncedSearch}
              placeholder="Search "
            />

            <Button
              sx={{ flexGrow: 1 }}
              variant="outlined"
              onClick={(e) => {
                setMode("create");
                setCpdModal(true);
                setCpdInfo([]);
              }}
            >
              Add CPD
            </Button>
          </Stack>
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={2}
            sx={{ marginTop: "10px" }}
          >
            <Grid item xs={3}>
              <InputLabel>From:</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format="yyyy-MM-dd"
                  value={startDate ? new Date(startDate) : null}
                  sx={{ width: "100%" }}
                  onChange={(newValue) => {
                    if (newValue) {
                      const originalDate = new Date(newValue);
                      const utcDate = new Date(
                        Date.UTC(
                          originalDate.getFullYear(),
                          originalDate.getMonth(),
                          originalDate.getDate(),
                          originalDate.getHours(),
                          originalDate.getMinutes(),
                          originalDate.getSeconds(),
                          originalDate.getMilliseconds()
                        )
                      );
                      setStartDate(utcDate.toISOString());
                    } else {
                      setStartDate("");
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <InputLabel>To:</InputLabel>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format="yyyy-MM-dd"
                  minDate={new Date(startDate)}
                  disabled={startDate ? false : true}
                  value={endDate ? new Date(endDate) : null}
                  sx={{ width: "100%" }}
                  onChange={(newValue) => {
                    if (newValue) {
                      const originalDate = new Date(newValue);
                      const utcDate = new Date(
                        Date.UTC(
                          originalDate.getFullYear(),
                          originalDate.getMonth(),
                          originalDate.getDate(),
                          originalDate.getHours(),
                          originalDate.getMinutes(),
                          originalDate.getSeconds(),
                          originalDate.getMilliseconds()
                        )
                      );
                      setEndDate(utcDate.toISOString());
                    } else {
                      setEndDate("");
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Button
              sx={{ height: " 50px", marginTop: "28px" }}
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {
                setStartDate("");
                setEndDate("");
              }}
            >
              Clear
            </Button>
          </Stack>
          <AddCpdModal
            openCpdModal={openCpdModal}
            setCpdModal={setCpdModal}
            cpdInfo={cpdInfo}
            refetch={refetch}
            mode={mode}
            cpdId={cpdId}
            refetchCpdInfo={refetchCpdInfo}
          />
        </CardContent>

        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <CPDListTable {...{ cpdList, handleEdit, handleRefetch }} />
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getAllCpdPoints?.pagination.total / rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </>
  );
};

export default CPDViewAndList;
