import * as Yup from 'yup';

export const PricingAndBookingValidationSchema = Yup.object().shape({
  bookingOpenAnytime: Yup.boolean(),
  booking: Yup.object().when('bookingOpenAnytime', {
    is: false,
    then: Yup.object().shape({
      startDate: Yup.date().required('Start date is required').nullable(),
      startTime: Yup.date().required('Start Time is required').nullable(),
      endDate: Yup.date().required('End date is required').nullable(),
      endTime: Yup.date().required('End Time is required').nullable(),
      seats: Yup.number()
        .integer('Seats must be an integer')
        .required('Seats is required')
        .min(1, 'Seats must be greater than or equal to 1')
        .nullable(),
    }),
    otherwise: Yup.object().shape({
      startDate: Yup.date().nullable(),
      startTime: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
      endTime: Yup.date().nullable(),
      seats: Yup.number()
        .integer('Seats must be an integer')
        .required('Seats is required')
        .min(1, 'Seats must be greater than or equal to 1')
        .nullable(),
    }),
  }),
  pricing: Yup.object().shape({
    members: Yup.number()
      .required('Pricing for members is required')
      .min(0, 'Pricing for members must be greater than or equal to 0'),

    nonMembers: Yup.number()
      .required('Pricing for non members is required')
      .min(0, 'Pricing for non members must be greater than or equal to 0')
      .test(
        'nonMembers',
        'Pricing for non members must not be less than members',
        function (value: any) {
          const membersValue: any = this.resolve(Yup.ref('members'));
          return value >= membersValue;
        }
      ),

    discount: Yup.number(),
  }),
});
