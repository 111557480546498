import { HeadCell1 } from 'types';

// table header options
export const headCells: HeadCell1[] = [
  {
    id: 'title',
    numeric: false,
    label: 'Title',
    align: 'left',
    sort: false,
  },
  {
    id: 'message',
    numeric: false,
    label: 'Message',
    align: 'left',
    sort: false,
  },
  {
    id: 'date',
    numeric: false,
    label: 'Date Published',
    align: 'left',
    sort: false,
  }
];
