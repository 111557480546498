import { HeadCell } from './types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Membership Name',
    align: 'left',
    sort: false,
  },
  {
    id: 'startDate',
    numeric: false,
    label: 'Start Date',
    align: 'left',
    sort: true,
  },
  {
    id: 'endDate',
    numeric: false,
    label: 'End Date',
    align: 'left',
    sort: true,
  },

  {
    id: 'createdBy',
    numeric: false,
    label: 'Created By',
    align: 'left',
    sort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    label: 'Last Modified',
    align: 'left',
    sort: true,
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    align: 'left',
    sort: true,
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action ',
    align: 'center',
    sort: false,
  },
];

export const initialValues = {
  membeshipName: '',
  startDate: '',
  endDate: '',
  createdby: '',
  lastModified: '',
  status: '',
  action: '',
};
