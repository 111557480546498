import { gql } from "@apollo/client";

export const GET_ALL_TRANSACTIONS_LIST = gql`
  query PaymentList($input: GetPaymentReportsInputDto!) {
    getPaymentReportList(input: $input) {
      paymentList {
        _id
        transactionId
        paymentDate
        snNumber
        userDetails {
          userId
          familyName
          firstName
          companyName
          companyCode
          membershipType
          membershipStatus
          address {
            flatUnit
            streetNumber
            streetName
            postcode
            state
            suburb
            country
            fullAddress
          }
        }
        eventDetails {
          eventId
          title
          startDate
        }
        fee
        GST
        totalDue
        creditCardSurcharge
        paidAmount
        description
        dueDate
        paymentMethod
        receiptEmail
        isRefunded
      }
      pagination {
        total
        hasNextPage
      }
      message
    }
  }
`;

export const GET_TRANSACTIONS_FOR_EXPORT = gql`
  query GenerateReportOfPayments($input: GeneratePaymentReportInputDTO!) {
    generateReportOfPayments(input: $input) {
      paymentList {
        _id
        transactionId
        paymentDate
        snNumber
        userDetails {
          familyName
          firstName
          companyName
          companyCode
          membershipType
          membershipStatus
          address {
            flatUnit
            streetNumber
            streetName
            postcode
            state
            suburb
            country
            fullAddress
          }
          userId
        }
        eventDetails {
          title
          startDate
          eventId
        }
        fee
        GST
        totalDue
        creditCardSurcharge
        paidAmount
        description
        receiptEmail
        dueDate
        paymentMethod
        isRefunded
      }
    }
  }
`;
