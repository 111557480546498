import { gql } from '@apollo/client';

export const GET_ADMIN_LIST = gql`
  query GetAdminList($input: GetAdminListDTO!) {
    getAdminList(input: $input) {
      message
      adminList {
        _id
        firstName
        familyName
        email
        role
        status
        phone
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        industry
        company
        position
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_ADMIN = gql`
  query GetAdmin($getAdminId: String) {
    getAdmin(id: $getAdminId) {
      message
      admin {
        _id
        firstName
        familyName
        email
        role
        status
        phone
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        industry
        company
        position
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query {
    getUserProfile {
      _id
      firstName
      familyName
      company
      industry
      email
      role
      status
      phone
      position
      address {
        flatUnit
        streetNumber
        streetName
        postcode
        state
        suburb
        country
      }
    }
  }
`;
export const GET_SURCHARGE_VALUE = gql`
  query GetSurchargeValue {
    getSurchargeValue {
      _id
      settingType
      paymentType {
        method
        value
        rate
      }
    }
  }
`;
