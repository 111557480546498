import { gql } from "@apollo/client";

export const GET_EVENT_INFORMATION = gql`
  query ($docId: String) {
    getEvent(docId: $docId) {
      _id
      title
      isCompliance
      bannerColor
      eventType
      pageType
      suitableFor
      cpdPoints
      coverImageUrl
      relevantFiles
      mainLocation
      mainLink
      sessions {
        startDate
        endDate
        startTime
        endTime
        modality
        link
      }
      pricing {
        members
        nonMembers
        discount
      }
      booking {
        startDate
        endDate
        startTime
        endTime
        openAnytime
        seats
      }
      availability
      bookingOpenAnytime
      furtherDetails {
        singleDetail
        detailLeft
        detailRight
      }
      openAnytime
      speaker {
        term
        speakers {
          _id
          firstName
          lastName
        }
      }
      status
    }
  }
`;
export const GET_ALL_SPEAKERS = gql`
  query GetAllSpeaker {
    getAllSpeaker {
      message
      pagination {
        total
        hasNextPage
      }
      speakers {
        _id
        firstName
        lastName
        photo
        bio
      }
    }
  }
`;

export const GET_UPCOMING_EVENTS = gql`
  query GetUpcomingEvents($input: GetEventDto!) {
    getUpcomingEvents(input: $input) {
      message

      events {
        _id
        title
        mainLocation
        mainLink
        isCompliance
        bannerColor
        eventType
        pageType
        suitableFor
        cpdPoints
        coverImageUrl
        attendeesInEvent {
          count
        }
        relevantFiles
        sessions {
          startDate
          endDate
          startTime
          endTime
          modality
          link
        }
        status
        availability
        bookingOpenAnytime
        openAnytime
        booking {
          startDate
          endDate
          startTime
          endTime
          openAnytime
          seats
        }
      }
    }
  }
`;

export const GET_ALL_EVENTS = gql`
  query GetAllEvents($input: GetEventDto!) {
    getAllEvents(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      events {
        _id
        title
        mainLocation
        mainLink
        isCompliance
        bannerColor
        eventType
        pageType
        suitableFor
        cpdPoints
        coverImageUrl
        relevantFiles
        sessions {
          startDate
          endDate
          startTime
          endTime
          modality
          link
        }
        status
        availability
        bookingOpenAnytime
        openAnytime
        pricing {
          members
          nonMembers
          discount
        }
        booking {
          startDate
          endDate
          startTime
          endTime
          openAnytime
          seats
        }
        furtherDetails {
          singleDetail
          detailLeft
          detailRight
        }
        attendeesInEvent {
          count
        }
        speaker {
          term
          speakers {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const QUERY_GET_IMAGE_FROM_KEY_FETCH = gql`
  query Query($key: String!) {
    getImageFromKey(key: $key)
  }
`;

export const GET_ALL_ATTENDIES_USER = gql`
  query GetAllAttendiesList($docId: String!, $input: GetAttendiesInputDto!) {
    getAllAttendiesList(docId: $docId, input: $input) {
      attendies {
        _id
        firstName
        lastName
        email
        phone
        createdAt
        timeInProfession
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        eventId
        note
        status
        paidAmt
        userId
        position
        industry
        event
        company
        certification {
          crm
          aml
        }
        isGuestUser
        membership {
          membershipType
          code
          status
          validUptoDate
          paymentDate
        }
        memberNumber
      }
      message
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_ALL_INDIVIDUAL_USER_LIST = gql`
  query GetAllSystemUsers($input: GetSysUsersListDTO!) {
    getAllSystemUsers(input: $input) {
      message
      users {
        email
        firstName
        _id
        lastName
      }
    }
  }
`;

export const GET_TOTAL_USER_LIST = gql`
  query getTotalAppUsers {
    getTotalAppUsers {
      totalCount {
        count
        type
      }
    }
  }
`;

export const GET_ACTION_ITEMS = gql`
  query actionItem {
    actionItem {
      data {
        payLater
        status
        membership {
          status
        }
        title
        firstName
        lastName
        eventId
        type
        _id
        createdAt
        event {
          _id
          title
        }
        userInfo {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_ACTION_ITEMS_LIST = gql`
  query actionItemsList($input: GetActionItemsDTO!) {
    actionItemsList(input: $input) {
      data {
        payLater
        status
        membership {
          status
        }
        title
        firstName
        lastName
        eventId
        type
        _id
        createdAt
        event {
          _id
          title
        }
        userInfo {
          _id
          firstName
          lastName
        }
      }
      pagination {
        hasNextPage
        total
      }
    }
  }
`;
