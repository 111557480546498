import { useMutation, useQuery } from '@apollo/client';
import { Input } from '@mui/material';

import {
  EventHistoryListInput,
  ExpiryDateResponse,
  MembershipResponse,
  NoteListInputDto,
  NoteResponses,
  UpdateCpdResponse,
  UpdateCpdStatusResponse,
  UserInformation,
  UserResponse,
  cpdInput,
  cpdresponse,
  individualMembershipInputDto,
  individualUserInvoiceListInput,
  individualUserListInputDto,
  notesInput,
  resetPasswordResponseDto,
  updateCpdStatusInput,
  userCpdListInputDto,
} from '../types';
import {
  ACTIVATE_USER_STATUS,
  ADDCPD,
  ADD_INVOICE,
  CREATE_NOTES,
  DELETE_USER_PERMANENTLY_FROM_ALL_MODULES,
  RESET_USER_PASSWORD,
  UPDATE_CPD,
  UPDATE_CPD_STATUS,
  UPDATE_PAYMENT_DETAILS,
  UPDATE_USER_INFORMATION,
  UPDATE_USER_MEMBERSHIP,
} from 'grapqhl/individualUser/mutation';
import {
  GET_END_DATE_OF_INDIVIDUAL_USER,
  GET_EVENT_HISTORY,
  GET_INDIVIDUAL_USER_CPD_LIST,
  GET_INDIVIDUAL_USER_LIST,
  GET_INVOICE_HISTORY_OF_INDIVIDUAL_USER,
  GET_USER_INFO,
  GET_USER_NOTES_LIST,
} from 'grapqhl/individualUser/query';
import {
  AddIndividualInvoiceResponse,
  AddInvoiceInput,
  AddInvoiceInputDto,
  AddInvoiceResponse,
  CreateNoteResponse,
  NoteResponse,
  UpdateIndividualInvoiceResponse,
  updateInvoiceInput,
} from 'types/event';

export const useGQL = () => {
  const UPDATE_INDIVIDUAL_USER_INFORMATIONS = () =>
    useMutation<
      UserResponse,
      {
        updateUserInformationId?: string;
        input: Partial<UserInformation>;
      }
    >(UPDATE_USER_INFORMATION);

  const GET_USER_INFORMATION_DETAILS = (getUserId: string) =>
    useQuery(GET_USER_INFO, {
      variables: { getUserId },
    });
  const GET_USER_INFORMATION = () =>
    useQuery(GET_USER_INFO, {
      variables: {},
    });

  const UPDATE_INDIVIDUAL_MEMBERSHIP = () =>
    useMutation<
      MembershipResponse,
      { updateUserId: string; input: Partial<individualMembershipInputDto> }
    >(UPDATE_USER_MEMBERSHIP);

  const GET_ALL_INDIVIDUAL_USERS = (input: individualUserListInputDto = {}) =>
    useQuery(GET_INDIVIDUAL_USER_LIST, { variables: { input } });

  const RESET_PASSWORD = () =>
    useMutation<resetPasswordResponseDto, { userId: string }>(
      RESET_USER_PASSWORD
    );

  const GET_CPD_POINTS = (input: userCpdListInputDto = {}) =>
    useQuery(GET_INDIVIDUAL_USER_CPD_LIST, { variables: { input } });

  const CREATE_CPD = () =>
    useMutation<cpdresponse, { createCpdPoint: Partial<cpdInput> }>(ADDCPD);
  const UPDATE_CPD_DATA = () =>
    useMutation<UpdateCpdResponse, { updateCpdPoint: Partial<cpdInput> }>(
      UPDATE_CPD
    );

  const UPDATE_CPD_REQ_STATUS = () =>
    useMutation<
      UpdateCpdStatusResponse,
      { updateCpdStatusInput: Partial<updateCpdStatusInput> }
    >(UPDATE_CPD_STATUS);

  const CREATE_NOTE = () =>
    useMutation<NoteResponses, { addUserNoteInput: Partial<notesInput> }>(
      CREATE_NOTES
    );

  const GET_NOTES = (getUserNotesListInput: NoteListInputDto = {}) =>
    useQuery(GET_USER_NOTES_LIST, {
      variables: { getUserNotesListInput },
    });

  const GET_EVENT_HISTORY_LIST = (
    eventHistoryInput: EventHistoryListInput = {}
  ) =>
    useQuery(GET_EVENT_HISTORY, {
      variables: { eventHistoryInput },
    });

  const GET_END_DATE = () =>
    useQuery<ExpiryDateResponse>(GET_END_DATE_OF_INDIVIDUAL_USER);

  const GET_INDIVIDUAL_INVOICE_HISTORY = (
    getInvoicePaymentInput: individualUserInvoiceListInput = {}
  ) =>
    useQuery(GET_INVOICE_HISTORY_OF_INDIVIDUAL_USER, {
      variables: { getInvoicePaymentInput },
    });

  const ADD_INVOICE_DETAIL_OF_INDIVIDUAL = () =>
    useMutation<
      AddIndividualInvoiceResponse,
      { invoicePaymentInput: Partial<AddInvoiceInput> }
    >(ADD_INVOICE);

  const ACTIVATE_USER = () =>
    useMutation<
      UserResponse,
      {
        activateUsersdId?: string;
      }
    >(ACTIVATE_USER_STATUS);

  const DELETE_USER_PERMANENTLY = () =>
    useMutation<
      UserResponse,
      {
        deleteUserId?: string;
      }
    >(DELETE_USER_PERMANENTLY_FROM_ALL_MODULES);

  const UPDATE_INVOICE_DETAILS = () =>
    useMutation<UpdateIndividualInvoiceResponse, { input: any }>(
      UPDATE_PAYMENT_DETAILS
    );

  return {
    UPDATE_INDIVIDUAL_USER_INFORMATIONS,
    GET_USER_INFORMATION_DETAILS,
    UPDATE_INDIVIDUAL_MEMBERSHIP,
    GET_ALL_INDIVIDUAL_USERS,
    GET_USER_INFORMATION,
    RESET_PASSWORD,
    GET_CPD_POINTS,
    CREATE_CPD,
    UPDATE_CPD_DATA,
    UPDATE_CPD_REQ_STATUS,
    CREATE_NOTE,
    GET_NOTES,
    GET_EVENT_HISTORY_LIST,
    GET_END_DATE,
    GET_INDIVIDUAL_INVOICE_HISTORY,
    ADD_INVOICE_DETAIL_OF_INDIVIDUAL,
    ACTIVATE_USER,
    DELETE_USER_PERMANENTLY,
    UPDATE_INVOICE_DETAILS,
  };
};
