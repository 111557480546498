/* eslint no-underscore-dangle: 0 */

// material-ui
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  // DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  // Rating,
  TextField,
  // Typography
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import useGQL from 'hooks/useGQL';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
// import Snackbar from 'ui-component/extended/Snackbar';
import {
  Admin,
  status,
  userRole as role,
  adminRoleMap,
} from '../constants/types';
import { firstLetterUppercase } from 'utils/commonHelpers';
import Spinner from 'components/spinner';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

type AdminEditProps = {
  openDialog: boolean;
  handleDialogClose: () => void;
  adminSelected: Admin;
  listRefetch: any;
};

const AdminEditForm = ({
  openDialog,
  handleDialogClose,
  adminSelected,
  listRefetch,
}: AdminEditProps) => {
  const { UPDATE_ADMIN } = useGQL();
  const [handleUpdate, { loading, data }] = UPDATE_ADMIN();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.updateAdmin) {
      dispatch(
        openSnackbar({
          open: true,
          message: `${firstLetterUppercase(
            adminSelected.role
          )} updated successfully`,
          anchorOrigin: { horizontal: 'center' },
          variant: 'alert',
          alert: {
            color: 'success',
          },
        })
      );
      handleDialogClose();
      setTimeout(() => {
        listRefetch();
      }, 100);
    }
  }, [loading, data]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      sx={{
        '&>div:nth-of-type(3)': {
          '&>div': {
            maxWidth: 400,
          },
        },
      }}
    >
      {openDialog && (
        <>
          <DialogTitle>
            Edit {firstLetterUppercase(adminSelected.role)}
          </DialogTitle>

          <Formik
            initialValues={{
              firstName: adminSelected.firstName,
              familyName: adminSelected.familyName,
              phone: adminSelected.phone,
              role: adminSelected.role,
              status: adminSelected.status,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .min(1)
                .max(100)
                .required('Firstname is required'),
              familyName: Yup.string()
                .min(1)
                .max(100)
                .required('Familyname is required'),
              phone: Yup.string()
                .matches(/^[0-9]+$/, 'Phone Number must only contain numbers')
                .min(
                  8,
                  'Main contact phone number must be at least 8 digits long'
                )
                .required('Phone Number is required'),
              role: Yup.mixed()
                .oneOf(['ADMIN', 'EDITOR'])
                .required('Role is required'),
              status: Yup.mixed()
                .oneOf(['active', 'pending'])
                .required('Status is required'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await handleUpdate({
                  variables: {
                    id: adminSelected._id,
                    input: {
                      firstName: values.firstName,
                      familyName: values.familyName,
                      phone: values.phone.toString(),
                      role: values.role,
                      status: values.status,
                    },
                  },
                });
              } catch (err: any) {
                dispatch(
                  openSnackbar({
                    open: true,
                    message:
                      err.message ||
                      `${firstLetterUppercase(
                        adminSelected.role
                      )} update failed...`,
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                      color: 'error',
                    },
                  })
                );
                setSubmitting(false);
                handleDialogClose();
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container spacing={gridSpacing} sx={{ my: 0 }}>
                    <Grid item xs={12}>
                      <TextField
                        id="firstName"
                        fullWidth
                        type="text"
                        value={values.firstName}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="First Name"
                        inputProps={{}}
                      />
                      {touched.firstName && errors.firstName && (
                        <FormHelperText error id="firstName-error">
                          {firstLetterUppercase(errors.firstName)}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="familyName"
                        fullWidth
                        type="text"
                        value={values.familyName}
                        name="familyName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Family Name"
                        inputProps={{}}
                      />
                      {touched.familyName && errors.familyName && (
                        <FormHelperText error id="familyName-error">
                          {firstLetterUppercase(errors.familyName)}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="phone"
                        fullWidth
                        type="text"
                        value={values.phone}
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Phone No."
                        inputProps={{}}
                      />
                      {touched.phone && errors.phone && (
                        <FormHelperText error id="phone-error">
                          {firstLetterUppercase(errors.phone)}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="status"
                        name="status"
                        select
                        label="Status"
                        value={values.status}
                        fullWidth
                        onChange={handleChange}
                      >
                        {status.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="role"
                        name="role"
                        select
                        label="Role"
                        value={values.role}
                        fullWidth
                        onChange={handleChange}
                      >
                        {role.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: '1rem',
                      gap: '2rem',
                    }}
                  >
                    <AnimateButton>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          ' Update'
                        )}
                      </Button>
                    </AnimateButton>
                    <Button variant="text" onClick={handleDialogClose}>
                      Close
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
};

export default AdminEditForm;
