/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';

import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

import { headCells } from '../constants/variables';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from '../hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSort } from 'store/slices/table';
import {
  IndividualStatusMappingObj,
  MembershipTypesIndividualMappingObj,
  individualUserStatus,
} from '../constants/types';
import { getUserInformationDetailsResponse } from 'views/individual-user/types';
import { useGQL } from 'views/individual-user/hooks/useGQL';
import lang from 'constants/language';
import Spinner from 'components/spinner';
import { useNavigate } from 'react-router-dom';
import { GET_USER_INFO } from 'grapqhl/individualUser/query';
import { useLazyQuery, useQuery } from '@apollo/client';
import TableErrorMessage from 'common/tableErrorMessage';

type CompanyTableProps = {
  individualUser: getUserInformationDetailsResponse[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

type Order = 'asc' | 'desc';
const IndividualUserTable = ({
  individualUser,
  handleEdit,
  handleRefetch,
}: CompanyTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();

  const { getConfirmation } = useAlertDialog();
  const {
    UPDATE_INDIVIDUAL_MEMBERSHIP,
    UPDATE_INDIVIDUAL_USER_INFORMATIONS,
    RESET_PASSWORD,
    ACTIVATE_USER,
    DELETE_USER_PERMANENTLY,
  } = useGQL();
  const [handleUpdate, { data: update, loading }] =
    UPDATE_INDIVIDUAL_MEMBERSHIP();

  const [getUserInfo] = useLazyQuery(GET_USER_INFO);

  const [handleUserStratusUpdate, { data: updateUserInfo }] =
    UPDATE_INDIVIDUAL_USER_INFORMATIONS();

  const [handleResetPassword, { data: passwordReset }] = RESET_PASSWORD();

  const [handleActivateUser, { data: activeUser }] = ACTIVATE_USER();
  const [handleDeleteUser, { data: deleteUser }] = DELETE_USER_PERMANENTLY();

  const params = new URLSearchParams(window.location.search);
  const userId = params.get('UserId') as string;

  const membershipMappingObj = {
    CORPORATE: 'Corporate Member',
    INDIVIDUAL: 'Individual',
    CORPORATE_PRINCIPAL_MEMBER: 'Corporate Principal Member',
    INDIVIDUAL_STUDENT: 'Individual Student',
    INDIVIDUAL_RETIRED: 'Individual Retired',
  };

  useEffect(() => {
    handleRefetch();
  }, [order, orderBy]);

  //for user membership status
  useEffect(() => {
    if (update?.updateUser) {
      handleOpenSnackbar({
        message: userId
          ? lang.INDIVIDUAL_USER.MEMBERSHIP_UPDATE
          : lang.INDIVIDUAL_USER.MEMBERSHIP_CREATE,
        alertType: 'success',
      });
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }

  //for user status
  useEffect(() => {
    if (updateUserInfo?.updateUserInformation) {
      handleOpenSnackbar({
        message: userId
          ? lang.INDIVIDUAL_USER.UPDATE
          : lang.INDIVIDUAL_USER.CREATE,
        alertType: 'success',
      });
    }
  }, [update]);

  //for individual user status and membership status
  const updateIndividualUserStatus = async (
    event: React.MouseEvent,
    id: string,
    status: string
  ) => {
    const isConfirm = await getConfirmation({
      title: `${status === 'RESIGNED' ? 'Resign' : 'Delete'} User`,
      message: `Are you sure you want to ${
        status === 'RESIGNED' ? 'resign' : 'delete'
      } user?`,
    });
    if (isConfirm) {
      if (status === 'RESIGNED') {
        const input = {
          status: 'RESIGNED',
        };
        try {
          await handleUpdate({
            variables: {
              updateUserId: id,
              input: { ...input },
            },
          });
          handleOpenSnackbar({
            message: `Membership ${
              status === 'RESIGNED' ? 'resigned' : 'deleted'
            } successfully`,
            alertType: 'success',
          });
          handleRefetch();
        } catch (err: any) {
          handleOpenSnackbar({ message: err.message, alertType: 'error' });
        }
      } else {
        const { data } = await getUserInfo({
          variables: { getUserId: id },
        });
        const input = {
          ...data?.getUser?.user,
        };
        input.status = 'REMOVED';
        delete input.membership;
        delete input.certification;
        delete input.hasPaidJoiningFee;
        delete input._id;

        try {
          await handleUserStratusUpdate({
            variables: {
              updateUserInformationId: id,
              input: { ...input },
            },
          });
          handleOpenSnackbar({
            message: `User has been ${
              status === 'RESIGNED' ? 'resigned' : 'deleted'
            } successfully`,
            alertType: 'success',
          });
          handleRefetch();
        } catch (err: any) {
          handleOpenSnackbar({ message: err.message, alertType: 'error' });
        }
      }
    }
  };

  //to activate user
  const activateUser = async (event: React.MouseEvent, id: string) => {
    const isConfirm = await getConfirmation({
      title: `User Activation`,
      message: `Are you sure you want to activate user ?`,
    });
    if (isConfirm) {
      try {
        await handleActivateUser({
          variables: {
            activateUsersdId: id,
          },
        });
        handleOpenSnackbar({
          message: 'User Activated Successfully',
          alertType: 'success',
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  //to delete user permanently
  const deleteUserPermanently = async (event: React.MouseEvent, id: string) => {
    const isConfirm = await getConfirmation({
      title: lang.USER.DELETE_TITLE,
      message: lang.USER.DELETE_MESSAGE,
    });
    if (isConfirm) {
      try {
        await handleDeleteUser({
          variables: {
            deleteUserId: id,
          },
        });
        handleOpenSnackbar({
          message: lang.USER.RESPONSE_MESSAGE,
          alertType: 'success',
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const setOrder = isAsc ? 'desc' : 'asc';
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  //for password reset
  const handlePasswordChange = async (id: string) => {
    try {
      const { data } = await handleResetPassword({
        variables: {
          userId: id,
        },
      });
      handleOpenSnackbar({
        message:
          data?.resetUserPassword?.message ||
          'Password reset code sent successfully. ',
        alertType: 'success',
      });
      handleRefetch();
    } catch (error) {}
  };

  const ActionButton = ({ userId, status, userStatus }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState('');

    const handleMenuActionClick = (event: any, userId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(userId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };
    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, userId)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={(e) => handleEdit(e, selected)}>
            {' '}
            Edit User
          </MenuItem>
          <MenuItem onClick={(e) => handlePasswordChange(selected)}>
            {' '}
            Send Password Reset
          </MenuItem>
          {status !== 'RESIGNED' && userStatus !== 'REMOVED' && (
            <MenuItem
              onClick={(e) =>
                updateIndividualUserStatus(e, selected, 'RESIGNED')
              }
            >
              {' '}
              Resign User
            </MenuItem>
          )}
          {userStatus !== 'REMOVED' && (
            <MenuItem //for soft delete
              onClick={(e) =>
                updateIndividualUserStatus(e, selected, 'REMOVED')
              }
            >
              {' '}
              Delete User (Soft Delete)
            </MenuItem>
          )}
          {userStatus === 'REMOVED' && (
            <MenuItem onClick={(e) => activateUser(e, selected)}>
              {' '}
              Activate User
            </MenuItem>
          )}{' '}
          {userStatus === 'REMOVED' && (
            <MenuItem onClick={(e) => deleteUserPermanently(e, selected)}>
              {' '}
              Delete User (Hard Delete)
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === 'action') {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {individualUser.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              individualUser.map((individualUser) => (
                <TableRow key={individualUser._id}>
                  <TableCell align="left">
                    {individualUser?.memberNumber}
                  </TableCell>
                  <TableCell align="left">{`${individualUser?.firstName} ${individualUser?.lastName}`}</TableCell>
                  <TableCell align="left">{individualUser?.email}</TableCell>

                  <TableCell align="left">
                    {individualUser?.membership?.membershipType
                      ? membershipMappingObj[
                          individualUser?.membership?.membershipType
                        ]
                      : null}
                  </TableCell>
                  <TableCell align="left">
                    {individualUser?.isGuestUser === true ? 'Yes' : 'No'}
                  </TableCell>

                  <TableCell align="left">{individualUser?.company}</TableCell>
                  <TableCell align="left">
                    {individualUser?.membership &&
                      (individualUser?.membership.status === 'VALID' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              individualUser?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="success"
                        />
                      ) : individualUser?.membership.status === 'RESIGNED' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              individualUser?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="error"
                        />
                      ) : individualUser?.membership.status ===
                        'AWAITING_PAYMENT' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              individualUser?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="warning"
                        />
                      ) : individualUser?.membership.status === 'GRACE' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              individualUser?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="secondary"
                        />
                      ) : individualUser?.membership.status === 'CORPORATE' ? (
                        <Chip
                          label={
                            IndividualStatusMappingObj[
                              individualUser?.membership.status
                            ]
                          }
                          size="medium"
                          chipcolor="primary"
                        />
                      ) : null)}
                  </TableCell>
                  <TableCell align="left" sx={{ pr: 3 }}>
                    {individualUser?.status === 'ACTIVE' && (
                      <Chip
                        label={individualUserStatus[individualUser?.status]}
                        size="medium"
                        chipcolor="success"
                      />
                    )}
                    {individualUser?.status === 'REMOVED' && (
                      <Chip
                        label={individualUserStatus[individualUser?.status]}
                        size="medium"
                        chipcolor="warning"
                      />
                    )}
                  </TableCell>

                  <TableCell align="center" sx={{ pr: 3 }}>
                    <ActionButton
                      userId={individualUser?._id}
                      status={individualUser?.membership?.status}
                      userStatus={individualUser?.status}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default IndividualUserTable;
