import { createContext, useState } from 'react';

type DialogContext = {
    dialog: typeof initialState;
    openDialog: (config: DialogConfig) => void;
    resetDialog: () => void;
};

type DialogConfig = {
    title: string;
    message: string;
    actionCallback: any;
};

type PropTypes = {
    children: React.ReactElement[];
};

const initialState = {
    open: false,
    dialogConfig: {
        title: '',
        message: '',
        actionCallback: (res: boolean) => {}
    }
};

export const DialogContext = createContext<DialogContext | null>(null);

const AlertDialogProvider = ({ children }: PropTypes) => {
    const [dialog, setDialog] = useState<typeof initialState>(initialState);

    const openDialog = (config: DialogConfig) => {
        setDialog({ open: true, dialogConfig: { ...config } });
    };

    const resetDialog = () => {
        setDialog({ ...initialState });
    };

    return <DialogContext.Provider value={{ dialog, openDialog, resetDialog }}>{children}</DialogContext.Provider>;
};

export default AlertDialogProvider;
