/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Spinner from "components/spinner";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "hooks/common/useSnackbar";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import {
  AmlCertificates,
  Category,
  country,
  CountryStates,
  CrmCertificates,
  Industries,
} from "store/constant";
import { CompanyInformation } from "types/company";
import lang from "constants/language";
import { useNavigate } from "react-router-dom";
import MainCard from "ui-component/cards/MainCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import WysiwygEditor from "views/event/further-detail/Wysiwug/WysiwugEditor";
import { Stack } from "@mui/system";
import { resourceInformationSchema } from "./resource-information-validation";
import { useGQL } from "./hooks/useGQL";
import {
  resourceInformation,
  resourceInformationInputDto,
} from "types/resource";
import ValidateFileType from "common/validateFileType";
import { v4 as uuidv4 } from "uuid";
import useImageUpload from "common/handleFileUpload";
import { useLazyQuery } from "@apollo/client";

const cancelButtonStyle = {
  background: "none",
  color: "#212121",
  border: "1px solid #212121",
  borderRadius: "5",
  minWidth: "0px",
  width: "120px",
  maxHeight: "44px",
};
// import { UserInformation } from './types';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const ViewResourceInformation = () => {
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();
  const { GET_RESOURCE_INFORMATION } = useGQL();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const resourceId = params.get("ResourceId") as string;

  const [imageSrc, setImageSrc] = useState<string>("");

  const [resource, setResource] = useState<resourceInformation>({
    _id: "",
    title: "",
    isMemberOnly: false,
    permalink: "",
    date: new Date(),
    author: "",
    category: "",
    subCategories: [],
    description: "",
    status: "",
    slug: "",
  });

  const {
    error: resourceError,
    loading: resourceLoading,
    data: resourceInformationData,
    refetch,
  } = GET_RESOURCE_INFORMATION(resourceId);

  useEffect(() => {
    if (resourceInformationData?.getResource?.resource) {
      setResource(resourceInformationData?.getResource?.resource);
      setImageSrc(resourceInformationData?.getResource?.resource?.media);
    }
  }, [resourceInformationData]);

  return (
    <>
      <MainCard>
        <FormWrapper>
          <Formik
            enableReinitialize
            initialValues={{
              title: resource?.title || "",
              isMemberOnly: resource?.isMemberOnly || false,
              permalink: resource?.permalink || "",
              date: resource?.date || dayjs().toJSON(),
              author: resource?.author || "",
              category: resource?.category || "",
              subCategories: resource?.subCategories || [],
              description: resource?.description || "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              /* make data sync with update userDTO */
              const input = {
                ...values,
              };
              setSubmitting(true);
            }}
            validationSchema={resourceInformationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              handleChange,
              getFieldProps,
              setFieldValue,

              /* and other goodies */
            }) => (
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                {/* <Grid container spacing={3}> */}
                <Grid item xs={8} container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      component="h1"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                    >
                      View resource
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Title"
                      label="Title"
                      disabled
                      {...getFieldProps("title")}
                    />
                    {touched.title && errors.title && (
                      <FormHelperText error id="title-error">
                        {errors.title}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isMemberOnly}
                          disabled
                          {...getFieldProps(`isMemberOnly`)}
                          onChange={(e) => {
                            setFieldValue(`isMemberOnly`, e.target.checked);
                          }}
                          name="checked"
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="subtitle1">
                          Members only
                        </Typography>
                      }
                    />
                    {touched.isMemberOnly && errors.isMemberOnly && (
                      <FormHelperText error id="lastName-error">
                        {errors.isMemberOnly}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="permalink"
                      label="permalink"
                      disabled
                      {...getFieldProps(`permalink`)}
                    />
                    {touched.permalink && errors.permalink && (
                      <FormHelperText error id="permalink-error">
                        {errors.permalink}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Date"
                        value={new Date(values.date)}
                        onChange={(newValue) => {
                          setFieldValue(`date`, dayjs(newValue).toJSON());
                        }}
                        disabled
                      />
                    </LocalizationProvider>
                    {touched.date && errors.date && (
                      <FormHelperText error id="date">
                        {errors.date}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      placeholder="Author"
                      label="Author"
                      disabled
                      {...getFieldProps(`author`)}
                    />

                    {touched.author && errors.author && (
                      <FormHelperText error id="author-error">
                        {errors.author}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="Category"
                      label="Category"
                      select
                      fullWidth
                      disabled
                      sx={{ width: "100%" }}
                      {...getFieldProps("category")}
                    >
                      {Category.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    {touched?.category && errors?.category && (
                      <FormHelperText error id="category-error">
                        {errors?.category}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      disabled
                      options={[]}
                      value={values.subCategories ? values.subCategories : []}
                      onChange={(event, value) => {
                        setFieldValue("subCategories", value);
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub categories"
                          placeholder="Sub categories"
                        />
                      )}
                    />

                    {touched?.subCategories && errors?.subCategories && (
                      <FormHelperText error id="subCategories-error">
                        {errors?.subCategories}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                {/* </Grid> */}

                <Divider sx={{ height: 10, width: "100%" }} />

                <Grid>
                  <Grid item xs={12} sm={6} sx={{ marginTop: "1.5rem" }}>
                    <Avatar
                      alt="solicitor profile image"
                      src={imageSrc}
                      sx={{
                        height: 80,
                        width: 80,
                        marginBottom: "1.25rem",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} marginTop={"1.5rem"}>
                  <WysiwygEditor
                    disabled
                    value={values.description}
                    onChange={(e, value) => setFieldValue("description", e)}
                  />
                </Grid>

                <Divider sx={{ height: 10, width: "100%" }} />
                <Grid container justifyContent="flex-end">
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="right"
                    sx={{ mt: "1rem" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate("/resources/list");
                      }}
                    >
                      Back to list
                    </Button>
                  </Stack>
                </Grid>
              </form>
            )}
          </Formik>
        </FormWrapper>
      </MainCard>
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default ViewResourceInformation;
