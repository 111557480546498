import * as Yup from 'yup';
export const resourceInformationSchema = Yup.object({
  title: Yup.string().min(3).max(250).required('Title is required'),
  permalink: Yup.string().min(3).max(1000).nullable(),
  author: Yup.string().min(3).max(1000).required('Author is required'),
  category: Yup.string().min(3).max(1000).required('Category is required'),
  subCategories: Yup.array().min(1, 'At least one sub category is required'),
  date: Yup.date().required('Date is required').nullable(),
  description: Yup.string().required('Description is required'),
});
