import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/authentication/pages/Login3"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/authentication/pages/Register3"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/authentication/pages/ForgotPassword3"))
);
const AuthMailCheck = Loadable(
  lazy(() => import("views/authentication/pages/CheckMail3"))
);
const AuthCodeVerification = Loadable(
  lazy(() => import("views/authentication/pages/CodeVerification3"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("views/authentication/pages/ResetPassword3"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "/login",
      element: <AuthLogin />,
    },

    {
      path: "/forgot",
      element: <AuthForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <AuthResetPassword />,
    },
    {
      path: "/mail-check",
      element: <AuthMailCheck />,
    },
    {
      path: "/code-verification",
      element: <AuthCodeVerification />,
    },
  ],
};

export default LoginRoutes;
