import { HeadCell } from "./types";

// table header options
export const headCells: HeadCell[] = [
  {
    id: "invoiceNUmber",
    numeric: true,
    label: "Invoice No",
    align: "left",
    sort: false,
  },
  {
    id: "transactionId",
    numeric: true,
    label: "Transaction Id",
    align: "center",
    sort: false,
  },
  {
    id: "amount",
    numeric: true,
    label: "Amount",
    align: "left",
    sort: false,
  },
  {
    id: "Datepaid",
    numeric: false,
    label: "Date Paid",
    align: "left",
    sort: false,
  },
  {
    id: "paymentMethod",
    numeric: false,
    label: "Payment Method",
    align: "left",
    sort: false,
  },
  {
    id: "paymentStatus",
    numeric: false,
    label: "Payment Status",
    align: "left",
    sort: false,
  },
  {
    id: "description",
    numeric: false,
    label: "Description ",
    align: "left",
    sort: false,
  },
  {
    id: "action",
    numeric: false,
    label: "View ",
    align: "center",
    sort: false,
  },
];

export const initialValues = {
  invoiceNUmber: "",
  transactionId: "",
  amount: "",
  Datepaid: "",
  paymentMethod: "",
  description: "",
};
export const paymentStatusMap = {
  succeeded: "Succeeded",
  Canceled: "Canceled",
  Pending: "Pending",
  "": "-",
};
