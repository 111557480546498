import {
  Button,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import Spinner from 'components/spinner';
import lang from 'constants/language';
import { Formik } from 'formik';
import useSnackbar from 'hooks/common/useSnackbar';
import { useEffect, useState } from 'react';
import { useGQL } from 'views/individual-user/hooks/useGQL';
import * as Yup from 'yup';
const PopModalRejectionReason = ({
  open,
  setOpen,
  cpdInformation,
  refetch,
}) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      md: 700,
      xs: '90%',
    },
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
  };
  const { handleOpenSnackbar } = useSnackbar();
  const { UPDATE_CPD_REQ_STATUS } = useGQL();
  const [handleUpdate, { data: updatedData, loading: updateLoading }] =
    UPDATE_CPD_REQ_STATUS();

  useEffect(() => {
    if (updatedData?.updateCpdStatus) {
      handleOpenSnackbar({
        message: 'updated',
        alertType: 'success',
      });
      setOpen(false);
    }
  }, [updatedData]);

  const handleRejectionNote = async (
    values,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await handleUpdate({
        variables: {
          updateCpdStatusInput: {
            ...values,
            cpdId: cpdInformation,
            status: 'NOT_APPROVED',
          },
        },
      });
      refetch();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: 'error' });
      setSubmitting(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <Formik
            initialValues={{
              rejectionDescription: cpdInformation?.rejectionDescription || '',
            }}
            validationSchema={Yup.object().shape({
              rejectionDescription: Yup.string().required(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const input = {
                ...values,
              };

              setSubmitting(true);
              handleRejectionNote(input, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    marginBottom: '1.5rem',
                  }}
                >
                  Non Approval reason
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ maxWidth: '697px' }}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Rejection Note"
                      value={values.rejectionDescription}
                      name="rejectionDescription"
                      label="Rejection Note"
                      multiline
                      minRows={5}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.rejectionDescription &&
                      errors.rejectionDescription && (
                        <FormHelperText error id="rejectionDescription-error">
                          {errors.rejectionDescription}
                        </FormHelperText>
                      )}
                  </Grid>
                </Grid>

                <Box
                  sx={{ marginTop: '2em', display: 'flex', gap: '20px' }}
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{ color: 'white' }}
                    variant="contained"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ color: 'white' }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Please wait...' : 'Save Changes'}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </>
    </Modal>
  );
};
export default PopModalRejectionReason;
