import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const validateType = (event: any, fileType: string) => {
  const SUPPORTED_FORMATS_PDF = ['application/pdf'];
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  if (
    event.target.files[0]?.type && fileType === 'IMAGE'
      ? !SUPPORTED_FORMATS.includes(event.target.files[0].type)
      : !SUPPORTED_FORMATS_PDF.includes(event.target.files[0].type)
  ) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'File Type not supported',
        variant: 'alert',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },

        alert: {
          color: 'error',
        },
        close: false,
      })
    );
    return false;
  }
  return true;
};
export default validateType;
