import * as Yup from 'yup';
import BannerColors, {
  EventModality,
  EventType,
  PageType,
  StatusType,
} from './enum';

export const eventValidationSchema = Yup.object({
  title: Yup.string().min(3).max(1000).required('Event name is required'),
  isCompliance: Yup.boolean(),
  eventType: Yup.mixed()
    .oneOf(Object.values(EventType))
    .required('Event type is required'),
  pageType: Yup.mixed()
    .oneOf(Object.values(PageType))
    .required('Page type is required'),
  suitableFor: Yup.array()
    .of(Yup.string().required('Suitable for is required'))
    .min(1, 'Suitable for is required'),
  cpdPoints: Yup.number()
    .min(0, 'Cpd points must be greater than or equal to 0')
    .required('Cpd points is required')
    .nullable(),
  openAnytime: Yup.boolean(),
  sessions: Yup.array().when('openAnytime', {
    is: false,
    then: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.date().required('Start date is required').nullable(),
        endDate: Yup.date().required('End date is required').nullable(),
        startTime: Yup.date().required('Start Time is required').nullable(),
        endTime: Yup.date().required('End Time is required').nullable(),
        modality: Yup.string().required('Modality is required').nullable(),
        link: Yup.string(),
      })
    ),
    otherwise: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.date().nullable(),
        endDate: Yup.date().nullable(),
        startTime: Yup.date().nullable(),
        endTime: Yup.date().nullable(),
        modality: Yup.string().nullable(),
        link: Yup.string().nullable(),
      })
    ),
  }),
});
