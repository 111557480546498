import { HeadCell } from "./types";

// table header options
export const headCells: HeadCell[] = [
  {
    id: "Date",
    numeric: false,
    label: "Date",
    align: "left",
    sort: false,
  },
  {
    id: "Title",
    numeric: false,
    label: "Title",
    align: "left",
    sort: false,
  },
  {
    id: "Description",
    numeric: false,
    label: "Description",
    align: "left",
    sort: false,
  },

  {
    id: "Status",
    numeric: true,
    label: "Status",
    align: "left",
    sort: false,
  },

  {
    id: "Reason",
    numeric: false,
    label: "Reason",
    align: "center",
    sort: false,
  },
  {
    id: "Points",
    numeric: true,
    label: "Points",
    align: "center",
    sort: false,
  },
  {
    id: "action",
    numeric: false,
    label: "Action ",
    align: "center",
    sort: false,
  },
];

export const initialValues = {
  Date: "",
  Title: "",
  Description: "",
  status: "",
  Reason: "",
  Points: "",
};
