/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* gql hooks */
import { useGQL } from "../hooks/useGQL";

// project imports
import MainCard from "ui-component/cards/MainCard";
import { RootState, useDispatch, useSelector } from "store";

/* custom hooks */
import _debounce from "lodash.debounce";

// assets
import SearchIcon from "@mui/icons-material/Search";

/* variables imports */
import Spinner from "components/spinner";
import useDebouncedSearch from "./hooks/useDebounceSearch";
import { gridSpacing } from "store/constant";
import { resourceInformation } from "types/resource";
import ResourceTable from "./tables";

const rowsPerPageOptions = [
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 150, label: 150 },
  { value: 200, label: 200 },
];

// ==============================|| CUSTOMER LIST ||============================== //

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const ResourceList = () => {
  const navigate = useNavigate();
  const [searchTextInput, setSearchTextInput] = useState<string>("");
  const [debouncedSearch] = useDebouncedSearch(
    (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | undefined
    ) => {
      if (event?.target) {
        setSearchTextInput(event.target.value);
      }
    }
  );

  const dispatch = useDispatch();
  const { searchText, sort } = useSelector((state: RootState) => state.table);

  /* queries & mutations */
  const { GET_ALL_RESOURCES } = useGQL();
  const { error, loading, data, refetch } = GET_ALL_RESOURCES();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  /* local states */
  const [resourceInformationData, setResourceInformation] = useState<
    resourceInformation[]
  >([]);
  const [meta, setMeta] = useState<Meta>();

  const CATEGORIES = [
    { label: "Read", id: 1 },
    { label: "Listen", id: 2 },
    { label: "Watch", id: 3 },
  ];

  const [filtersInput, setFiltersInput] = useState("");
  const handleInputChange = (event: React.SyntheticEvent, newValue: string) => {
    setFiltersInput(newValue);
  };
  useEffect(() => {
    if (data?.getResourcesList?.resources) {
      setResourceInformation(data?.getResourcesList?.resources);
    }
  }, [data]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    setMeta({ limit, skip });
    refetch({
      input: {
        limit,
        skip,
        searchText: searchTextInput,
        category: filtersInput,
        order: sort.order,
        orderBy: sort.orderBy,
      },
    });
  }, [rowsPerPage, page]); //filterinput

  const handleRefetch = () => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * page : 0;
    refetch({
      input: {
        limit: meta?.limit,
        skip,
        searchText: searchTextInput,
        category: filtersInput,
        order: sort.order,
        orderBy: sort.orderBy,
      },
    });
  };

  const [anchor, setAnchor] = useState(null);

  const handleClickPage = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePage = () => {
    setAnchor(null);
  };

  useEffect(() => {
    setPage(0);
    handleRefetch();
  }, [searchTextInput, filtersInput, rowsPerPage, sort.order, sort.orderBy]); //filter input

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event?.target?.value) {
      setRowsPerPage(parseInt(event.target.value));
    }
  };
  const handlePageMenuItemClick = (event) => {
    handleChangeRowsPerPage(event);
    setAnchor(null);
  };
  const handleEdit = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => {
    const selection = resourceInformationData.find(
      (resource) => resource._id === id
    );
    if (selection) {
      // window.location.reload();
      navigate(`/resources/add?ResourcesId=${selection._id}`);
    }
  };

  return (
    <>
      <MainCard title="Resources" content={false} sx={{ position: "relative" }}>
        <Button
          variant="outlined"
          component={Link}
          to="/resources/add"
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
          }}
        >
          Add Resource
        </Button>
        <CardContent>
          <Grid container direction="row" spacing={gridSpacing}>
            <Grid item xs={12} sm={10}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#004C8F" }}>
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 500,
                }}
                fullWidth
                onChange={debouncedSearch}
                placeholder="Search Resources"
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Autocomplete
                options={CATEGORIES}
                inputValue={filtersInput}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                  <TextField {...params} label="Filter" />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>

        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <ResourceTable
              {...{ resourceInformationData, handleRefetch, handleEdit }}
            />
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    count={Math.ceil(
                      data?.getResourcesList?.pagination.total / rowsPerPage
                    )}
                    color="primary"
                    onChange={handleChangePage}
                    defaultPage={page + 1}
                    page={page + 1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    hidePrevButton={page === 0 ?? true}
                  />
                </Grid>
                <Grid item>
                  <span>Show</span>
                  <Button
                    size="large"
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickPage}
                    className="pagination-button"
                  >
                    {rowsPerPage} per page
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClosePage}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(event) => handlePageMenuItemClick(event)}
                      >
                        {option.label} rows
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </>
  );
};

export default ResourceList;
