// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

// assets
import { IconCalendarEvent, IconUser } from '@tabler/icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MARK_AS_READ } from 'grapqhl/notification/queries';
import { useState } from 'react';
import {
  CalendarTodayTwoTone,
  NoteTwoTone,
  PersonTwoTone,
} from '@mui/icons-material';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: '15px 16px 15px 16px',
  margin: '1px 0px 1px 0px',
  '&:hover': {
    background:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.primary.light,
  },
  '& .MuiListItem-root': {
    padding: 0,
  },
  '& .MuiListItemAvatar-root': {
    minWidth: '30px',
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({
  notifications,
  onClose,
  handleRefetch,
  onRead,
}) => {
  const theme = useTheme();
  const chipSX = {
    height: 24,
    padding: '0 6px',
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.orange.light,
    marginRight: '5px',
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.warning.light,
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.success.light,
    height: 28,
  };
  const navigate = useNavigate();
  const [notificationId, setNotificationId] = useState<string | null>();

  const handleRead = async (e, n) => {
    setNotificationId(n._id);

    if (n.type === 'event-fully-booked') {
      navigate('/events?eventId=' + n.eventId);
    }

    if (n.type === 'cpd-point-request') {
      navigate('/individualUser/add?tab=5&UserId=' + n.userId);
    }
    if (n.type === 'membership-expired') {
      navigate('/individualUser/add?tab=1&UserId=' + n.userId);
    }

    if (!n.isRead) {
      await onRead(n._id);
      handleRefetch();
    }

    onClose(e);
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300,
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22,
        },
        '& .MuiDivider-root': {
          my: 0,
        },
        '& .list-container': {
          pl: 4,
        },
        '& .unread': {
          background: theme.palette.primary.light,
        },
      }}
    >
      {notifications?.map((n) => (
        <>
          <ListItemWrapper
            className={n.isRead ? '' : 'unread'}
            onClick={(e) => handleRead(e, n)}
          >
            <ListItem alignItems="center">
              <ListItemAvatar>
                {n.type === 'event-fully-booked' ? (
                  <CalendarTodayTwoTone />
                ) : n.type === 'cpd-point-request' ? (
                  <NoteTwoTone />
                ) : (
                  <PersonTwoTone />
                )}
              </ListItemAvatar>
              <ListItemText primary={n.title} />
            </ListItem>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              ml={'30px'}
            >
              {moment(n.date).fromNow()}
            </Typography>
            <Grid container direction="column" className="list-container">
              <Grid item xs={12}>
                <Typography variant="subtitle2">{n.message}</Typography>
              </Grid>
            </Grid>
          </ListItemWrapper>
          <Divider />
        </>
      ))}
    </List>
  );
};

export default NotificationList;
