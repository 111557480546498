/* eslint-disable import/prefer-default-export */

import { HeadCell } from 'types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    align: 'left',
  },

  {
    id: 'email',
    numeric: false,
    label: 'Main Contact Email',
    align: 'left',
  },
  {
    id: 'phone',
    numeric: false,
    label: 'Main Contact Phone',
    align: 'left',
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    align: 'left',
  },

  {
    id: 'action',
    numeric: false,
    label: 'Action ',
    align: 'center',
    role: 'superAdmin',
  },
];

export const defaultAdmin = {
  _id: '',
  firstName: '',
  familyName: '',
  email: '',
  phone: '',
  role: '',
  status: '',
};

export const title = 'Delete Admin';
export const description = 'Are you sure you want to delete ?';
