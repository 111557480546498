import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useFormik } from "formik";
import { useTheme } from "styled-components";
import {
  TransactionsReport,
} from "./constants/types";

import { Stack } from "@mui/system";
import { useLazyQuery } from "@apollo/client";

import { IndividualStatusMappingObj } from "views/individual-user/individual-user-list/constants/types";
import exportFromJSON from "export-from-json";
import { useState } from "react";
import moment from "moment";
import { GET_TRANSACTIONS_FOR_EXPORT } from "grapqhl/transactionReport/query";
import { getMembershipDetails } from "common/helper/getMembershipDetails";

const FilterTransactionListModal = ({
  handleRefetch,
  total,
  setFilterTransactionData,
  filter,
}) => {
  const theme = useTheme();

  const [exportClicked, setExportClicked] = useState(false);

  const formik = useFormik({
    initialValues: {
      from: null,
      to: null,
    },
    onSubmit: (values, { resetForm }) => {
      setFilterTransactionData({ ...values });
      handleRefetch();
    },
    enableReinitialize: true,
  });

  const handleResetEvent = () => {
    setFilterTransactionData({ from: null, to: null })
    formik.resetForm();
  };

  const formatDataForExport = (data: TransactionsReport[]) => {
    const transactionReportData = data?.map((transaction: TransactionsReport) => {

    const { membershipType, description } = getMembershipDetails(transaction);

      return {
        sn: transaction?.snNumber,
        transactionId: transaction?.transactionId,
        paymentDate: transaction?.paymentDate ? moment(transaction?.paymentDate).format('DD/MM/YYYY') : "-",
        userFirstName: transaction?.userDetails?.firstName,
        userFamilyName: transaction?.userDetails?.familyName,
        companyName: transaction?.userDetails?.companyName,
        companyCode: transaction?.userDetails?.companyCode,
        membershipType: membershipType,
        membershipStatus:
          transaction?.userDetails?.membershipStatus ? IndividualStatusMappingObj[transaction?.userDetails?.membershipStatus] : "-",
        address: transaction?.userDetails?.address?.fullAddress,
        eventTitle: description,
        startDate: transaction?.eventDetails?.startDate ? moment(transaction?.eventDetails?.startDate).format('DD/MM/YYYY') : "-",
        fee: transaction?.fee,
        GST: transaction?.GST,
        creditCardSurcharge: transaction?.creditCardSurcharge,
        totalDue: transaction?.totalDue,
        paidAmount: transaction?.paidAmount,
        isRefunded: transaction?.isRefunded && "Refunded"
      };
    });
    return transactionReportData;
  };
  const [getTransactionReport] = useLazyQuery(GET_TRANSACTIONS_FOR_EXPORT, {
    variables: {
      input: {
        filter: {
          from:
            filter?.from === "" ? null : filter?.from,
          to: filter?.to === "" ? null : filter?.to,
        },
      },
    },
    fetchPolicy: "network-only",
    onCompleted(data) {
      const updatedData = formatDataForExport(
        data?.generateReportOfPayments?.paymentList
      );
      const dataToExport = {
        data: updatedData,
        fields: {
          sn: "snNumber",
          transactionId: "Transaction Id",
          paymentDate: "Payment Date",
          userFirstName: "User First Name",
          userFamilyName: "User Family Name",
          companyName: "Company Name",
          companyCode: "Company Code",
          membershipType: "Membership Type",
          membershipStatus: "Membership Status",
          address: "User Address",
          eventTitle: "Item Description",
          startDate: "Item Start Date",
          fee: "Fee",
          GST: "GST",
          creditCardSurcharge: "Credit Card Surcharge",
          totalDue: "Total Due",
          paidAmount: "Paid Amount",
          isRefunded: "Is Refunded"
        },
        fileName: "User Transaction Report",
        exportType: exportFromJSON.types.xls,
      };
      if (exportClicked) {
        exportFromJSON(dataToExport);
      }
      setExportClicked(false);
    },
    onError(error) {
      setExportClicked(false);
      console.log("error", error);
    },
  });


  return (
    <>
      {/* Formik form */}
      <form onSubmit={formik.handleSubmit}>
        {/* Form fields */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password-reset">
                From
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-reset"
                type={'number'}
                value={filter.from || ""}
                inputProps={{ min: 0, step: 0.1 }}
                name="from"
                // onBlur={handleBlur}
                onChange={(e) => {
                  formik.setFieldValue('from', e.target.value)
                  setFilterTransactionData((prev) => ({ ...prev, from: parseInt(e.target.value) }));
                }}
              />
            </FormControl>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                value={
                  formik.values.from
                    ? new Date(formik.values.from)
                    : null
                }
                label="Payment Date From"
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "from",
                    newValue ? newValue : null
                  );
                }}
              />
            </LocalizationProvider> */}
            { }
          </Grid>
          <Grid item xs={6}>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                value={
                  formik.values.to ? new Date(formik.values.to) : null
                }
                minDate={new Date(formik.values.from)}
                disabled={formik.values.from ? false : true}
                label="Payment Date To"
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    "to",
                    newValue ?  newValue : null
                  );
                }}
              />
            </LocalizationProvider> */}
            <FormControl
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password-reset">
                To
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-reset"
                type={'number'}
                value={filter.to || ""}
                name="to"
                // onBlur={handleBlur}
                onChange={(e) => {
                  formik.setFieldValue('to', e.target.value)
                  // handleChange(e);
                  setFilterTransactionData((prev) => ({ ...prev, to: parseInt(e.target.value) }));
                }}
                inputProps={{ min: 0, step: 0.1 }}
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* Submit button */}
        <Grid container justifyContent="flex-end">
          <Stack
            direction="row"
            spacing={2}
            alignItems="right"
            sx={{ mt: "1rem" }}
          >
            {" "}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={(e) => {
                setExportClicked(false);
                handleResetEvent();
              }}
            >
              Clear Filter
            </Button>
            <Button
              disabled={exportClicked}
              color="primary"
              onClick={() => {
                setExportClicked(true);
                getTransactionReport();
              }}
            >
              Export{" "}
              {exportClicked && (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  sx={{ marginLeft: "0.5rem" }}
                />
              )}
            </Button>
            <Button
              // disabled={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {
                setExportClicked(false);
              }}
            >
              View
            </Button>
          </Stack>
        </Grid>
      </form>
    </>
  );
};
export default FilterTransactionListModal;
