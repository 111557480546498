import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Spinner from "components/spinner";
import lang from "constants/language";
import dayjs from "dayjs";
import { ErrorMessage, Formik } from "formik";
import useSnackbar from "hooks/common/useSnackbar";
import { useEffect, useState } from "react";
import { useGQL } from "../hooks/useGQL";
import * as Yup from "yup";
const AddCpdModal = ({
  openCpdModal,
  setCpdModal,
  cpdInfo,
  refetch,
  mode,
  cpdId,
  refetchCpdInfo,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      md: 700,
      xs: "90%",
    },
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
  };

  const { handleOpenSnackbar } = useSnackbar();
  const { CREATE_CPD, UPDATE_CPD_DATA } = useGQL();
  const [handleCreate, { data: createdData, loading }] = CREATE_CPD();
  const [handleUpdate, { data: updatedData, loading: updateLoading }] =
    UPDATE_CPD_DATA();
  const params = new URLSearchParams(window.location.search);
  const UserId = params.get("UserId") as string;
  useEffect(() => {
    if (createdData?.createCpdPoint) {
      handleOpenSnackbar({
        message: lang.CPD.CREATED,
        alertType: "success",
      });
      setCpdModal(false);
    }
    if (updatedData?.updateCpdPoint) {
      handleOpenSnackbar({
        message: lang.CPD.UPDATED,
        alertType: "success",
      });
      setCpdModal(false);
    }
  }, [createdData, updatedData]);
  if (loading) {
    <Spinner />;
  }
  const handleCreateCpd = async (
    values,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const userId = UserId;
      if (mode === "create") {
        await handleCreate({
          variables: {
            createCpdPoint: { ...values, userId },
          },
        });
      }
      if (mode === "update") {
        await handleUpdate({
          variables: {
            updateCpdPoint: { ...values, userId, cpdId },
          },
        });
        refetchCpdInfo();
      }
      refetch();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };
  return (
    <Modal
      open={openCpdModal}
      onClose={() => {
        setCpdModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <Formik
            initialValues={{
              eventName: cpdInfo?.eventName || "",
              description: cpdInfo?.description || "",
              eventDate: cpdInfo?.eventDate || dayjs().toJSON(),
              points: parseFloat(cpdInfo?.points) || 0,
              units: parseFloat(cpdInfo?.units) || 0,
            }}
            //eventid and userid
            validationSchema={Yup.object().shape({
              eventName: Yup.string().required().label("Event name"),
              description: Yup.string().required().label("Event description"),
              eventDate: Yup.date().required().label("Event date"),
              points: Yup.number()
                .required()
                .label("CPD points")
                .integer()
                .min(0),
              units: Yup.number()
                .required()
                .label("Number of units/event hours")
                .integer()
                .min(0),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const input = {
                ...values,
              };

              setSubmitting(true);
              handleCreateCpd(input, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    marginBottom: "1.5rem",
                  }}
                >
                  {mode === "create" ? "Add" : "Update"} external CPD points
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ maxWidth: "697px" }}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Event name"
                      value={values.eventName}
                      name="eventName"
                      label="Event name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.eventName && errors.eventName && (
                      <FormHelperText error id="eventName-error">
                        {errors.eventName}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Event description"
                      value={values.description}
                      name="description"
                      label="Event description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.description && errors.description && (
                      <FormHelperText error id="description-error">
                        {errors.description}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        value={new Date(values.eventDate)}
                        sx={{ width: "100%" }}
                        label="Event date"
                        onChange={(newValue) => {
                          setFieldValue(`eventDate`, dayjs(newValue).toJSON());
                        }}
                      />
                    </LocalizationProvider>
                    <FormHelperText error id="title-error">
                      <ErrorMessage name={"eventDate"} />
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Number of units/event hours"
                      value={values.units}
                      name="units"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      label="Number of units/event hours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.units && errors.units && (
                      <FormHelperText error id="units-error">
                        {errors.units}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="CPD points"
                      value={values.points}
                      name="points"
                      label="CPD points"
                      inputProps={{
                        min: 0,
                      }}
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.points && errors.points && (
                      <FormHelperText error id="points-error">
                        {errors.points}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Box
                  sx={{ marginTop: "2em", display: "flex", gap: "20px" }}
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{ color: "white" }}
                    variant="contained"
                    onClick={() => setCpdModal(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? "Please wait..."
                      : mode === "create"
                      ? "Add new"
                      : "Update"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </>
    </Modal>
  );
};
export default AddCpdModal;
