import { useMutation, useQuery } from '@apollo/client';
import { GET_EVENT_REPORTS } from 'grapqhl/eventReport/query';
import { EventReportInputDto } from 'types/event';

export const useGQL = () => {
  const GET_ALL_EVENT_REPORT_LIST = (
    eventReportInput: EventReportInputDto = {}
  ) => useQuery(GET_EVENT_REPORTS, { variables: { eventReportInput } });

  return {
    GET_ALL_EVENT_REPORT_LIST,
  };
};
