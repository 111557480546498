/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import { headCells } from "../constants/variables";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "../hooks/useSnackbar";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";
import Spinner from "components/spinner";
import { useNavigate } from "react-router-dom";
import { resourceInformation } from "types/resource";
import moment from "moment";
import lang from "constants/language";
import Chip from "ui-component/extended/Chip";
import {
  ListMembershipsResponse,
  MembeshipStatusMap,
} from "../constants/types";
import { useGQL } from "../../hooks/useGQL";
import TableErrorMessage from "common/tableErrorMessage";

type Sort = { order: string; orderBy: string } | null;
type ResourceTableProps = {
  memershipList: ListMembershipsResponse[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

type Order = "asc" | "desc";
const MembershipListTable = ({
  memershipList,
  handleEdit,
  handleRefetch,
}: ResourceTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();

  const { getConfirmation } = useAlertDialog();

  const { DELETE_MEMBERSHIP } = useGQL();

  const [handleDeleteMembership, { data: update, loading, error }] =
    DELETE_MEMBERSHIP();

  const handleDelete = async (event: React.MouseEvent, id: string) => {
    const isConfirm = await getConfirmation({
      title: "Delete Membership",
      message: "Are you sure you want to delete membeship ?",
    });
    if (isConfirm) {
      try {
        await handleDeleteMembership({
          variables: {
            input: {
              _id: id,
              membershipStatus: "DISABLED",
            },
          },
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: "error" });
      }
    }
  };

  useEffect(() => {
    handleRefetch();
  }, [order, orderBy]);

  useEffect(() => {
    if (update?.deletemembership) {
      handleOpenSnackbar({
        message: lang.MEMBERSHIP.DELETE,
        alertType: "success",
      });
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const setOrder = isAsc ? "desc" : "asc";
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  const ActionButton = ({ membershipId, membership }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState("");
    const [data, setData] = useState("");

    const handleMenuActionClick = (event: any, membershipId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(membershipId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };

    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, membershipId)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            onClick={(e) =>
              navigate(`/membership/view?membershipId=${membershipId}`)
            }
          >
            {" "}
            View
          </MenuItem>

          <MenuItem onClick={(e) => handleEdit(e, selected)}> Edit</MenuItem>
          {membership?.membershipStatus !== "DISABLED" && (
            <MenuItem onClick={(e) => handleDelete(e, selected)}>
              {" "}
              Delete
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === "action") {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {memershipList.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              memershipList.map((membership) => (
                <TableRow key={membership._id}>
                  <TableCell align="left">{membership?.name}</TableCell>
                  <TableCell align="left">
                    {moment(membership?.startDate).format("Do MMMM YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {moment(membership?.endDate).format("Do MMMM YYYY")}
                  </TableCell>
                  <TableCell align="left">{membership?.createdBy}</TableCell>
                  <TableCell align="left">
                    {membership?.updatedAt
                      ? moment(membership?.updatedAt).format("Do MMMM YYYY")
                      : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {membership?.membershipStatus === "ACTIVE" ? (
                      <Chip
                        label={MembeshipStatusMap[membership?.membershipStatus]}
                        size="medium"
                        chipcolor="success"
                      />
                    ) : (
                      <Chip
                        label={MembeshipStatusMap[membership?.membershipStatus]}
                        size="medium"
                        chipcolor="error"
                      />
                    )}
                  </TableCell>

                  <TableCell align="center" sx={{ pr: 3 }}>
                    <ActionButton
                      membershipId={membership?._id}
                      membership={membership}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MembershipListTable;
