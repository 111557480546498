import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Spinner from "components/spinner";
import lang from "constants/language";
import { ErrorMessage, Formik } from "formik";
import useSnackbar from "hooks/common/useSnackbar";
import { useEffect, useState } from "react";
import { CountryStates, country } from "store/constant";
import { AddAttendeeUserInputDto } from "types/event";
import { useGQL } from "views/event/hooks/useGQL";
import useDebouncedSearch from "./hooks/useDebounceSearch";
import * as Yup from "yup";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_INDIVIDUAL_USER_LIST } from "grapqhl/event/query";
// import { resetForm } from 'store/slices/form';
import { useFormikContext } from "formik";
const PopModalUserAdd = ({
  open,
  setOpen,
  refetch,
  setDisableEmail,
  disableEmail,
  totalAttendee,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      md: 700,
      xs: "90%",
    },
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
  };
  const { handleOpenSnackbar } = useSnackbar();

  //local states
  const [individualUsers, setIndividualUsers] = useState<any>([]);
  const [errorEmail, setErrorEmail] = useState(true);
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearch] = useDebouncedSearch(
    (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | undefined
    ) => {
      if (event?.target) {
        setSearchText(event.target.value);
      }
    }
  );

  const [submitButtonDisable, setSubmitButtonDisable] = useState<boolean>(true);
  const [showField, setShowField] = useState(true);
  const formik = useFormikContext();

  //

  //gql queries

  const { GET_INDIVIDUAL_USERS_LIST, ADD_ATTENDEE_USER } = useGQL();
  const [getAllSystemUsers] = useLazyQuery(GET_ALL_INDIVIDUAL_USER_LIST);
  let userData;
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getAllSystemUsers({
        variables: {
          input: {
            limit: 2000,
            searchText: searchText,
          },
        },
      });

      setIndividualUsers(data?.getAllSystemUsers.users);
    };

    if (searchText) {
      fetchData();
    }
  }, [getAllSystemUsers, searchText]);

  //
  const params = new URLSearchParams(window.location.search);
  const eventId = params.get("eventId") as string;

  const [handleUpdate, { data: update, loading: loadingAttendee }] =
    ADD_ATTENDEE_USER();

  useEffect(() => {
    if (update?.addAttendee) {
      handleOpenSnackbar({
        message: lang.ATTENDEE.ADD,
        alertType: "success",
      });
      setOpen(false);
      setSubmitButtonDisable(true);
    }
  }, [update]);

  const handleAdd = async (
    values: Partial<AddAttendeeUserInputDto>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { userId, ...value } = values;
      let inputData;
      if (errorEmail) {
        inputData = { userId };
      } else {
        inputData = value;
      }

      await handleUpdate({
        variables: {
          docId: eventId,
          input: { ...inputData, totalAttendee },
        },
      });
      setErrorEmail(true);
      setDisableEmail(false);
      refetch();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };
  const handleCancel = () => {
    setOpen(false);
    setErrorEmail(true);
    setDisableEmail(false);
    setShowField(true);
    setSubmitButtonDisable(true);
    // formik.resetForm();
    // formik.resetForm();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <Formik
            enableReinitialize
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              address: {
                flatUnit: "",
                streetName: "",
                streetNumber: "",
                postcode: "",
                suburb: "",
                country: "",
                state: "",
              },
              userId: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .nullable()
                .email()
                .min(3)
                .max(1000)
                .label("Email"),
              firstName: Yup.string()
                .min(2)
                .max(1000)
                .when("email", {
                  is: (email) => email?.length,
                  then: Yup.string().required("First name is required"),
                }),

              lastName: Yup.string()
                .min(2)
                .max(1000)
                .when("email", {
                  is: (email) => email?.length,
                  then: Yup.string().required("Last name is required"),
                }),
              address: Yup.object().when("email", {
                is: (email) => email?.length,
                then: Yup.object({
                  streetNumber: Yup.string().required(
                    "Street number is required"
                  ),
                  streetName: Yup.string().required("Street name is required"),
                  postcode: Yup.string().required("Post code is required"),
                  country: Yup.string().required("Country is required"),
                  state: Yup.string().required("State is required"),
                  suburb: Yup.string().required("Suburb is required"),
                }),
              }),

              userId: Yup.string().nullable().label("User"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const input = {
                ...values,
              };

              setSubmitting(true);
              handleAdd(input, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              getFieldProps,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    marginBottom: "1.5rem",
                  }}
                >
                  Add User To Event
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ maxWidth: "697px" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="h1"
                      sx={{
                        marginBottom: "1rem",
                      }}
                    >
                      Add User Via Email
                    </Typography>

                    <TextField
                      fullWidth
                      placeholder="Email"
                      label="Email"
                      disabled={disableEmail}
                      {...getFieldProps("email")}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                        {
                          e.target.value
                            ? setFieldValue("userId", "")
                            : setFieldValue("userId", values.userId);
                        }
                        {
                          errors.email
                            ? setErrorEmail(true)
                            : setErrorEmail(false);
                        }
                        e.target.value
                          ? setSubmitButtonDisable(false)
                          : setSubmitButtonDisable(true);
                      }}
                    />
                    {!disableEmail && touched.email && errors.email && (
                      <FormHelperText error id="email-error">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Grid>

                  {values.email && (
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={2}
                      alignItems="center"
                      style={{ maxWidth: "697px" }}
                    >
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          placeholder="First Name"
                          label="First Name"
                          {...getFieldProps("firstName")}
                        />
                        {touched.firstName && errors.firstName && (
                          <FormHelperText error id="firstName-error">
                            {errors.firstName}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          placeholder="Last Name"
                          label="Last Name"
                          {...getFieldProps("lastName")}
                        />
                        {touched.lastName && errors.lastName && (
                          <FormHelperText error id="lastName-error">
                            {errors.lastName}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          placeholder="Enter Flat Unit"
                          label="Flat Unit"
                          {...getFieldProps("address.flatUnit")}
                        />
                        {touched.address?.flatUnit &&
                          errors.address?.flatUnit && (
                            <FormHelperText error id="flatUnit-error">
                              {errors.address?.flatUnit}
                            </FormHelperText>
                          )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          placeholder="Enter Street Number"
                          label="Street Number"
                          {...getFieldProps("address.streetNumber")}
                        />
                        {touched.address?.streetNumber &&
                          errors.address?.streetNumber && (
                            <FormHelperText error id="streetNumber-error">
                              {errors.address?.streetNumber}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          placeholder="Enter Street Name"
                          label="Street Name"
                          {...getFieldProps("address.streetName")}
                        />
                        {touched.address?.streetName &&
                          errors.address?.streetName && (
                            <FormHelperText error id="streetName-error">
                              {errors.address.streetName}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          placeholder="Enter Suburb"
                          label="Suburb"
                          {...getFieldProps("address.suburb")}
                        />
                        {touched.address?.suburb && errors.address?.suburb && (
                          <FormHelperText error id="suburb-error">
                            {errors.address?.suburb}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          // type={"number"}
                          placeholder="Enter Post Code"
                          // InputProps={{ inputProps: { min: 0, maxLength: 5 } }}
                          label="Postcode"
                          {...getFieldProps("address.postcode")}
                        />
                        {touched.address?.postcode &&
                          errors.address?.postcode && (
                            <FormHelperText error id="postcode-error">
                              {errors.address?.postcode}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {" "}
                        <TextField
                          id="state"
                          label="State"
                          select
                          fullWidth
                          sx={{ width: "100%" }}
                          {...getFieldProps("address.state")}
                        >
                          {CountryStates.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {touched.address?.country && errors?.address?.state && (
                          <FormHelperText error id="industry-error">
                            {errors?.address?.state}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="country"
                          label="Country"
                          select
                          fullWidth
                          {...getFieldProps("address.country")}
                        >
                          {country.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {touched.address?.country &&
                          errors.address?.country && (
                            <FormHelperText error id="country-error">
                              {errors.address?.country}
                            </FormHelperText>
                          )}
                      </Grid>
                    </Grid>
                  )}
                  {!values.email && (
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h1"
                        sx={{
                          marginBottom: "1rem",
                        }}
                      >
                        Or Search User
                      </Typography>
                      <Grid item lg={12}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          fullWidth
                          disabled={!errorEmail}
                          options={individualUsers || []}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName} (${option.email})`
                          }
                          onChange={(e: any, value: any) => {
                            setFieldValue("userId", value?._id);

                            {
                              values.userId
                                ? setDisableEmail(false)
                                : setDisableEmail(true);
                            }
                            {
                              value
                                ? setFieldValue("email", "")
                                : setFieldValue("email", values.email);
                            }
                            value
                              ? setSubmitButtonDisable(false)
                              : setSubmitButtonDisable(true);
                          }}
                          onInputChange={(e: any) => {
                            debouncedSearch(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Users" />
                          )}
                        />
                        <FormHelperText error id="title-error">
                          <ErrorMessage name={`userId`} />
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Box
                  sx={{ marginTop: "2em", display: "flex", gap: "20px" }}
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{ color: "white" }}
                    variant="contained"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    type="submit"
                    disabled={submitButtonDisable || isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Save Changes"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </>
    </Modal>
  );
};
export default PopModalUserAdd;
