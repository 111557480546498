import { gql } from '@apollo/client';

export const LIST_TAXONOMIES = gql`
    query ($input: GetListDTO!) {
        listTaxonomies(input: $input) {
            message
            taxonomies {
                _id
                name
                createdAt
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const LIST_TAXONOMY = gql`
    query ($id: String!) {
        listTaxonomy(id: $id) {
            message
            taxonomy {
                _id
                name
                taxons {
                    name
                    uuid
                    metaTitle
                    metaDescription
                    metaKeywords
                    image
                    description
                    nestedUnder
                    slug
                    children {
                        name
                        uuid
                        metaTitle
                        metaDescription
                        metaKeywords
                        image
                        description
                        nestedUnder
                        slug
                        children {
                            name
                            uuid
                            metaTitle
                            metaDescription
                            metaKeywords
                            image
                            description
                            nestedUnder
                            slug
                        }
                    }
                }
            }
        }
    }
`;
