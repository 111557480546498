/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Spinner from "components/spinner";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "hooks/common/useSnackbar";
import { useGQL } from "./hooks/useGQL";

import dayjs from "dayjs";

import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { PricingAndBooking } from "types/event";
import { PricingAndBookingValidationSchema } from "./pricingAndBookingValidationSchema";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import lang from "constants/language";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_ATTENDIES_USER } from "grapqhl/event/query";
import { formatDate } from "./dateFormatter";

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const PricingAndBookingDetails = ({ handleCancelEvent }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();

  const params = new URLSearchParams(window.location.search);
  const eventId = params.get("eventId") as string;
  const currentDate = new Date();
  const [totalAttendies, setTotalAttendies] = useState<any>([]);

  const { GET_ALL_ATTENDIES_LIST } = useGQL();
  const {
    loading: loadingAttendee,
    data: attendeeData,
    refetch: refetchAttendee,
  } = GET_ALL_ATTENDIES_LIST(eventId, {});

  const [getAllAttendies] = useLazyQuery(GET_ALL_ATTENDIES_USER); //get all attendies having payment status except Cancelled
  const fetchData = async () => {
    const { data } = await getAllAttendies({
      variables: {
        docId: eventId,
        input: {
          paymentStatus: "Canceled",
        },
      },
      fetchPolicy: "network-only",
    });
    setTotalAttendies(data?.getAllAttendiesList?.pagination?.total);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const {
    GET_EVENT_INFORMATION_DETAILS,
    CREATE_AND_UPDATE_PURCHASE_AND_BOOKINGS,
  } = useGQL();
  const {
    error,
    loading: getEventLoading,
    data: eventInformationData,
    refetch,
  } = GET_EVENT_INFORMATION_DETAILS(eventId);

  const eventInformationDetails = eventInformationData?.getEvent;
  const [handleUpdate, { data: update, loading }] =
    CREATE_AND_UPDATE_PURCHASE_AND_BOOKINGS();

  useEffect(() => {
    if (update?.updatePricingAndBooking) {
      handleOpenSnackbar({
        message: eventId
          ? lang.PRICINGANDBOOKING.UPDATE
          : lang.PRICINGANDBOOKING.CREATE,
        alertType: "success",
      });
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }
  const handlePricingAndBookingUpdate = async (
    values: Partial<PricingAndBooking>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await handleUpdate({
        variables: {
          eventId: eventId,
          input: { ...values },
        },
      });
      refetch();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <FormWrapper>
            <Formik
              enableReinitialize={true}
              initialValues={{
                bookingOpenAnytime:
                  eventInformationDetails?.bookingOpenAnytime || false,
                booking: {
                  startDate: eventInformationDetails?.booking?.startDate || "",
                  startTime: eventInformationDetails?.booking?.startTime
                    ? dayjs(
                        eventInformationDetails?.booking?.startTime,
                        "h:mm A"
                      ).toDate()
                    : "",
                  endDate: eventInformationDetails?.booking?.endDate || "",
                  endTime: eventInformationDetails?.booking?.endTime
                    ? dayjs(
                        eventInformationDetails?.booking?.endTime,
                        "h:mm A"
                      ).toDate()
                    : "",
                  seats: eventInformationDetails?.booking?.seats || null,
                },
                pricing: {
                  members: eventInformationDetails?.pricing?.members || 0,
                  nonMembers: eventInformationDetails?.pricing?.nonMembers || 0,
                  discount: eventInformationDetails?.pricing?.discount || 0,
                },
              }}
              validationSchema={PricingAndBookingValidationSchema}
              onSubmit={(values, { setSubmitting }) => {
                /* make data sync with update userDTO */
                const input = {
                  ...values,
                  booking:
                    values.bookingOpenAnytime === true
                      ? {
                          ...values.booking,
                          startDate: null,
                          endDate: null,
                          startTime: null,
                          endTime: null,
                        }
                      : {
                          ...values.booking,
                          startTime: dayjs(values?.booking?.startTime).format(
                            "h:mm A"
                          ),
                          endTime: dayjs(values?.booking?.endTime).format(
                            "h:mm A"
                          ),
                        },
                };
                setSubmitting(true);
                handlePricingAndBookingUpdate(input, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                isSubmitting,
                handleChange,
                getFieldProps,
                setFieldValue,

                /* and other goodies */
              }) => (
                <form onSubmit={(e) => handleSubmit(e)}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h3">Booking </Typography>
                    </Grid>
                    {!values.bookingOpenAnytime && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              format="dd/MM/yyyy"
                              label="Bookings Open"
                              maxDate={
                                eventInformationDetails?.openAnytime
                                  ? null
                                  : eventInformationDetails?.sessions?.[0]
                                      ?.startDate
                                  ? new Date(
                                      new Date(
                                        eventInformationDetails.sessions[0].startDate
                                      ).getTime() -
                                        24 * 60 * 60 * 1000
                                    ) // Subtract one day (24 hours) from the start date of the first session
                                  : new Date()
                              }
                              value={
                                values?.booking?.startDate
                                  ? new Date(values.booking.startDate)
                                  : null
                              }
                              sx={{ width: "100%" }}
                              onChange={(newValue) => {
                                setFieldValue(
                                  "booking.startDate",
                                  newValue ? formatDate(dayjs(newValue)) : null
                                );
                                if (newValue === null || newValue) {
                                  setFieldValue(`booking.endDate`, null);
                                }
                              }}
                            />
                          </LocalizationProvider>
                          {touched.booking?.startDate &&
                            errors.booking?.startDate && (
                              <FormHelperText error id="startDate-error">
                                {errors.booking?.startDate}
                              </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Start Time"
                              ampm={true}
                              timeSteps={{ minutes: 15 }}
                              sx={{ width: "100%" }}
                              value={
                                values?.booking?.startTime
                                  ? dayjs(values.booking.startTime)
                                  : null
                              }
                              onChange={(newValue) => {
                                setFieldValue(
                                  `booking.startTime`,
                                  newValue ? dayjs(newValue).toJSON() : null
                                );
                              }}
                            />
                          </LocalizationProvider>
                          {touched.booking?.startTime &&
                            errors.booking?.startTime && (
                              <FormHelperText error id="flatUnit-error">
                                {errors.booking?.startTime}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              format="dd/MM/yyyy"
                              label="Bookings close"
                              sx={{ width: "100%" }}
                              disabled={
                                values?.booking?.startDate ? false : true
                              }
                              value={
                                values?.booking?.endDate
                                  ? new Date(values.booking.endDate)
                                  : null
                              }
                              minDate={new Date(values?.booking?.startDate)}
                              // minDate={eventInformationDetails?.openAnytime? new Date(values.booking.startDate) :eventInformationDetails?.sessions}
                              maxDate={
                                eventInformationDetails?.openAnytime
                                  ? null
                                  : eventInformationDetails?.sessions?.[0]
                                      ?.startDate
                                  ? new Date(
                                      new Date(
                                        eventInformationDetails.sessions[0].startDate
                                      ).getTime() -
                                        24 * 60 * 60 * 1000
                                    ) // Subtract one day (24 hours) from the start date of the first session
                                  : new Date()
                              }
                              onChange={(newValue) => {
                                setFieldValue(
                                  "booking.endDate",
                                  values ? formatDate(dayjs(newValue)) : null
                                );
                                if (newValue === null) {
                                  setFieldValue(`booking.startDate`, null);
                                }
                              }}
                            />
                          </LocalizationProvider>
                          {touched.booking?.endDate &&
                            errors.booking?.endDate && (
                              <FormHelperText error id="endDate-error">
                                {errors.booking?.endDate}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Close Time"
                              ampm={true}
                              timeSteps={{ minutes: 15 }}
                              sx={{ width: "100%" }}
                              value={
                                values?.booking?.endTime
                                  ? dayjs(values.booking.endTime)
                                  : null
                              }
                              onChange={(newValue) => {
                                setFieldValue(
                                  `booking.endTime`,
                                  newValue ? dayjs(newValue).toJSON() : null
                                );
                              }}
                            />
                          </LocalizationProvider>
                          {touched.booking?.endTime &&
                            errors.booking?.endTime && (
                              <FormHelperText error id="flatUnit-error">
                                {errors.booking?.endTime}
                              </FormHelperText>
                            )}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter Number Of Seats"
                        label="Number Of Seats"
                        name="booking.seats"
                        InputProps={{
                          inputProps: { min: totalAttendies },
                        }}
                        value={values?.booking?.seats}
                        onChange={(e) => {
                          setFieldValue(
                            "booking.seats",
                            parseFloat(e?.target?.value)
                          );
                        }}
                      />
                      {touched.booking?.seats && errors.booking?.seats && (
                        <FormHelperText error id="seats-error">
                          {errors.booking?.seats}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              values.bookingOpenAnytime === true ? true : false
                            }
                            {...getFieldProps(`bookingOpenAnytime`)}
                            onChange={(e) => {
                              setFieldValue(
                                `bookingOpenAnytime`,
                                e.target.checked
                              );
                            }}
                            name="checked"
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="subtitle1">
                            Open Anytime
                          </Typography>
                        }
                      />
                      <FormHelperText error id="bookingOpenAnytime-error">
                        <ErrorMessage name={`bookingOpenAnytime`} />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h3">Pricing </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginTop: "1rem" }}>
                        {" "}
                        Members{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter Pricing For Members"
                        label="Price For Member"
                        value={values.pricing.members}
                        name={"pricing.members"}
                        InputProps={{
                          inputProps: { min: 0, step: 0.01 },
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onChange={(event: any) => {
                          handleChange(event);
                          setFieldValue(
                            "pricing.discount",
                            event.target.value - values?.pricing.nonMembers
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.pricing?.members && errors.pricing?.members && (
                        <FormHelperText error id="members-error">
                          {errors.pricing?.members}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginTop: "1rem" }}>
                        {" "}
                        Non-Members{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter Pricing For Non-Members"
                        label="Price For Non-Members"
                        value={values.pricing.nonMembers}
                        name={"pricing.nonMembers"}
                        onBlur={handleBlur}
                        InputProps={{
                          inputProps: { min: 0, step: 0.01 },
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onChange={(event: any) => {
                          handleChange(event);
                          setFieldValue(
                            "pricing.discount",
                            values?.pricing.members - event.target.value
                          );
                        }}
                      />
                      {touched.pricing?.nonMembers &&
                        errors.pricing?.nonMembers && (
                          <FormHelperText error id="nonMembers-error">
                            {errors.pricing?.nonMembers}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginTop: "1rem" }}>
                        {" "}
                        Discount{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        placeholder="Enter Discount"
                        label="Discount"
                        value={Math.abs(values?.pricing.discount).toFixed(2)}
                        name={"pricing.discount"}
                        disabled
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                      {touched.pricing?.discount &&
                        errors.pricing?.discount && (
                          <FormHelperText error id="discount-error">
                            {errors.pricing?.discount}
                          </FormHelperText>
                        )}
                    </Grid>

                    <Grid container justifyContent="flex-end">
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="right"
                        sx={{ mt: "1rem" }}
                      >
                        {eventInformationDetails?.openAnytime === true ? (
                          <Button
                            disabled={isSubmitting}
                            variant="outlined"
                            onClick={(e) => {
                              handleCancelEvent(eventId, "INACTIVE");
                            }}
                          >
                            Cancel Event
                          </Button>
                        ) : (
                          eventInformationDetails?.sessions[0]?.startDate &&
                          new Date(
                            eventInformationDetails?.sessions[0]?.startDate
                          ) >= currentDate && (
                            <Button
                              disabled={isSubmitting}
                              variant="outlined"
                              onClick={(e) => {
                                handleCancelEvent(eventId, "INACTIVE");
                              }}
                            >
                              Cancel Event
                            </Button>
                          )
                        )}

                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          sx={{ color: "white" }}
                        >
                          {isSubmitting ? "Please wait..." : "Save Changes"}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </FormWrapper>
        </>
      )}
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default PricingAndBookingDetails;
