import { CardContent, Grid, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
  debouncedSearch: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => void;
  width: string | undefined;
};

export const FilterList = ({ debouncedSearch, width }: Props) => {
  return (
    <CardContent
      sx={{
        width: width ? width : '100%',
        '@media (max-width:600px)': { width: '100%' },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={debouncedSearch}
            inputProps={{
              maxLength: 100, // Maximum character limit, including spaces
            }}
            sx={{ input: { background: '#FAFAFA' } }}
            placeholder="Search..."
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};
