import { HeadCell1 } from "types";


// table header options
export const headCells: HeadCell1[] = [
  {
    id: "id",
    numeric: true,
    label: "S.No.",
    align: "left",
    sort: false,
  },
  {
    id: "name",
    numeric: false,
    label: "Name",
    align: "left",
    sort: true,
  },
  {
    id: "title",
    numeric: false,
    label: "Title",
    align: "left",
    sort: true,
  },
  {
    id: "description",
    numeric: false,
    label: "Description",
    align: "left",
    sort: true,
  },
];

