import { SessionOutput } from 'types/event';
import { AdminAddress } from 'types/profile';
export type Certification = {
  crm: string;
  aml: string;
};
export type UserInformation = {
  _id: string;
  company: string;
  lastName: string;
  firstName: string;
  phone: string;
  email: string;
  position: string;
  industry: number;
  timeInProfession: string;
  address: AdminAddress;
  certification?: Certification;
  status: string;
  hasPaidJoiningFee: boolean;
};

// export type CompanyInformationResponse = {};

export type UserResponse = {
  updateUserInformation?: {
    user: UserInformation;
  };
};

export type MembershipObject = {
  membershipType: string;
  status: string;
  validUptoDate: Date | String;
  code?: string;
  memberSince?: Date | String | null;
  paymentDate?: Date | null;
};

export type getUserInformationDetailsResponse = {
  _id: string;
  company: string;
  lastName: string;
  firstName: string;
  phone: string;
  email: string;
  position: string;
  industry: string;
  timeInProfession: string;
  address: AdminAddress;
  certification?: Certification;
  membership?: MembershipObject;
  status: string;
  isGuestUser: boolean;
  memberNumber?: Number;
  hasPaidJoiningFee: boolean;
};

export type individualMembershipInputDto = {
  code?: string;
  membershipType?: string;
  status?: string;
  companyMemberExist?: boolean;
  validUptoDate?: Date | null;
};

export type individualMembershipResponse = {
  membership?: MembershipObject;
};
export type MembershipResponse = {
  updateUser?: {
    user: individualMembershipResponse;
  };
};
export type FilterObj = {
  filterStatus?: string;
  filterMembershipType?: string;
  isGuestUser?: boolean | null;
};
export type individualUserListInputDto = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  filterInput?: FilterObj;
};

export type individualUserListOutputDto = {
  getAllUsers?: {
    _id: string;
    company: string;
    lastName: string;
    firstName: string;
    phone: string;
    email: string;
    position: string;
    industry: string;
    address: AdminAddress;
    code?: string;
    certification?: Certification;
    membership?: MembershipObject;
  };
};

export type resetPasswordResponseDto = {
  resetUserPassword?: {
    message: string;
  };
};

export type userCpdListInputDto = {
  searchText?: string;
  limit?: number;
  skip?: number;
  from?: Date | string | null;
  to?: Date | string | null;
  userId?: string;
};

export type getCpdListResponse = {
  _id: string;
  eventId: string;
  eventName: string;
  description: string;
  units: string;
  eventDate: Date;
  points: string;
  status: string;
  rejectionDescription: string;
};

export type cpdresponse = {
  createCpdPoint?: {
    _id: string;
    eventId?: string;
    eventName: string;
    description: string;
    units: string;
    eventDate: string;
    points: string;
    status: string;
    rejectionDescription?: string;
    createdBy: string;
  };
};
export type cpdInput = {
  description: string;
  eventDate: Date | string;
  eventId?: string;
  eventName: string;
  points: number;
  units: number;
  userId?: string;
  cpdId?: string;
};

export type UpdateCpdResponse = {
  updateCpdPoint?: {
    _id: string;
    eventId?: string;
    eventName: string;
    description: string;
    units: string;
    eventDate: string;
    points: string;
    status: string;
    rejectionDescription?: string;
    createdBy: string;
  };
};

export type UpdateCpdStatusResponse = {
  updateCpdStatus?: {
    message: string;
  };
};

export type updateCpdStatusInput = {
  cpdId: string;
  status: string;
  rejectionDescription?: string;
};

export type notesInput = {
  userId: string;
  description: string;
};

export type NoteResponses = {
  addUserNote?: {
    description?: string;
    createdAt: string;
    status: string;
    createdBy: string;
  };
};

export type NoteListInputDto = {
  limit?: number;
  skip?: number;
  userId?: string;
};

export type NotesResponseList = {
  status: string;
  createdBy: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  description: string;
};
export type EventHistoryListInput = {
  limit?: number;
  skip?: number;
  userId?: string;
};

export type EventProp = {
  _id: string;
  title: string;
  eventType: string;
  sessions: SessionOutput;
};
export type EventHistoryListResponse = {
  bookingDate: Date;
  event: EventProp;
  status: string;
};

export const BookingStatusMap = {
  RESERVED: 'reserved',
  PAID: 'Confirmed',
};

export const EventModalityMap = {
  EVENT: 'Event',
  COURSE: 'Course',
  DISCUSSION_GROUP: 'Discussion Group',
};
export type ExpiryDateResponse = {
  searchMembershipTypeExpiry?: {
    endDate?: Date;
  };
};

export type individualUserInvoiceListInput = {
  limit?: number;
  skip?: number;
  userId?: string;
};

export type IndividualUserInvoiceList = {
  _id: string;
  invoiceNum?: string;
  transactionId?: string;
  paymentDate: Date;
  paymentMethod: string;
  paidAmount: number;
  description?: string;
  dueDate?: Date;
  status?: string
  itemDescription?: string
};
