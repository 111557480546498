/* eslint no-underscore-dangle: 0 */
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Chip from "ui-component/extended/Chip";
import moment from "moment";

import { eventTypeMappingObj, statusTypeMappingObj } from "../constants/types";

import { headCells } from "../constants/variables";
import useAlertDialog from "hooks/common/useAlertDialog";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Spinner from "components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";
import { EventReport, getAdminListType } from "types/event";
import { useGQL } from "views/event/hooks/useGQL";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_EVENT_INFORMATION } from "grapqhl/event/query";
import { CANCEL_EVENT_DATA } from "grapqhl/event/mutation";
import { useNavigate } from "react-router-dom";
import TableErrorMessage from "common/tableErrorMessage";

type Sort = { order: string; orderBy: string } | null;
type EventReportListTableProps = {
  eventReports: EventReport[];
};

type Order = "asc" | "desc";
const EventReportTable = ({ eventReports }: EventReportListTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);

  const { getConfirmation } = useAlertDialog();

  const { UPDATE_EVENT_INFORMATIONS } = useGQL();
  const [handleUpdate, { data: update, loading, error }] =
    UPDATE_EVENT_INFORMATIONS();

  const [getEventInfo] = useLazyQuery(GET_EVENT_INFORMATION);
  const [cancelEventMutation] = useMutation(CANCEL_EVENT_DATA);
  const navigate = useNavigate();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const setOrder = isAsc ? "desc" : "asc";
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {eventReports.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              eventReports.map((event) => (
                <TableRow key={event._id}>
                  <TableCell sx={{ width: "17rem" }} align="left">
                    {event?.title}
                  </TableCell>

                  <TableCell align="left">
                    {event?.sessions?.length
                      ? moment(event.sessions[0].startDate).format("DD/MM/YY")
                      : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {event?.sessions?.length
                      ? moment(
                          event.sessions[event.sessions.length - 1].endDate
                        ).format("DD/MM/YY")
                      : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {eventTypeMappingObj[event?.eventType]}
                  </TableCell>

                  <TableCell align="left">
                    {event.status == "ACTIVE" ? (
                      <Chip
                        label={statusTypeMappingObj[event?.status]}
                        size="medium"
                        chipcolor="success"
                      />
                    ) : event.status == "INACTIVE" ? (
                      <Chip
                        label={statusTypeMappingObj[event?.status]}
                        size="medium"
                        chipcolor="warning"
                      />
                    ) : (
                      event.status == "REMOVED" && (
                        <Chip
                          label={statusTypeMappingObj[event?.status]}
                          size="medium"
                          chipcolor="error"
                        />
                      )
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {event?.mainLocation || "-"}
                  </TableCell>
                  <TableCell align="center">
                    {event?.noOfConfirmedUsers}
                  </TableCell>
                  <TableCell align="center">
                    {event?.noOfCancelledUsers}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EventReportTable;
