import { TableCellProps } from '@mui/material';
import { initialValues } from './variables';

export interface EnhancedTableHeadProps extends TableCellProps {
  headCells: HeadCell[];
  order: ArrangementOrder;
  orderBy: string;
  onRequestSort: (e: React.SyntheticEvent, p: string) => void;
}

export type ArrangementOrder = 'asc' | 'desc' | undefined;

export type HeadCell = {
  id: string;
  numeric: boolean;
  label: string;
  disablePadding?: string | boolean | undefined;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  sort: boolean;
};

export enum AuthType {
  EMAIL = 'email',
  PHONE = 'phone',
}

export const StatusMappingObject: any = {
  VALID: 'Valid',
  AWAITING_PAYMENT: 'Awaiting Payment',
  GRACE: 'Grace',
  RESIGNED: 'Resigned',
};

export const IndividualStatusMappingObj: any = {
  VALID: 'Valid',
  AWAITING_PAYMENT: 'Awaiting Payment',
  GRACE: 'Grace',
  RESIGNED: 'Resigned',
  CORPORATE: 'Corporate',
};

export const individualUserStatus: any = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  REMOVED: 'Removed',
};

export const MembershipTypesIndividualMappingObj = {
  CORPORATE: 'Corporate',
  INDIVIDUAL: 'Individual',
};

export type membershipListInput = {
  limit?: number;
  skip?: number;
  order?: string;
  orderBy?: string;
  searchText?: string;
  filterInput?: string;
};

export const MembershipType = [
  {
    value: 'INDIVIDUAL',
    label: 'Individual',
  },
  {
    value: 'CORPORATE',
    label: 'Corporate',
  },
];
export type CorporateFees = {
  seats: string;
  unitPrice: number;
};

export type ListMembershipsResponse = {
  _id: string;
  startDate: Date;
  endDate: Date;
  membershipType: string;
  membershipFee?: number;
  membershipFeeIncGST?: number;
  joiningFee?: number;
  joiningFeeIncGST?: number;
  corporateFees?: CorporateFees[];
  name: string;
  createdBy: string;
  updatedAt: Date;
  membershipStatus: string;
};
export type MembershipsResponseState = {
  startDate: Date | null;
  endDate: Date | null;
  membershipType: string;
  membershipFee?: number | null;
  membershipFeeIncGST?: number | null;
  joiningFee?: number | null;
  joiningFeeIncGST?: number | null;
  corporateFees?: CorporateFees[];
  name: string;
  createdBy: string;
  membershipStatus: string;
};

export const MembeshipStatusMap = {
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
};

export type CreateMembership = {
  _id?: string;
  name: string;
  startDate: Date;
  endDate: Date;
  membershipType: string;
  membershipFee?: number;
  membershipFeeIncGST?: number;
  joiningFee?: number;
  joiningFeeIncGST?: number;
  corporateFees?: CorporateFees[];
  membershipStatus: string;
};

export type createMembershipResponse = {
  updateMembership?: {
    message: string;
  };
};

export type getMembershipResponse = {
  getMemberInformation?: {
    membership: ListMembershipsResponse;
  };
};

export type getMembershipInfoInput = {
  membershipId: string;
};

export type DeleteMembershipResponse = {
  deletemembership?: {
    message: string;
  };
};
export type DeleteMembershipInput = {
  _id?: string;

  membershipStatus?: string;
};
