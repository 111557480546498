import { HeadCell } from './types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'eventName',
    numeric: true,
    label: 'Event Name',
    align: 'left',
    sort: false,
  },
  {
    id: 'eventType',
    numeric: false,
    label: 'Event Type',
    align: 'left',
    sort: false,
  },
  {
    id: 'startDate',
    numeric: false,
    label: 'Start Date',
    align: 'center',
    sort: true,
  },
  {
    id: 'endDate',
    numeric: false,
    label: 'End Date',
    align: 'center',
    sort: true,
  },
  {
    id: 'bookingDate',
    numeric: false,
    label: 'Registration Date',
    align: 'center',
    sort: true,
  },
  {
    id: 'bookingStatus',
    numeric: false,
    label: 'Registration Status',
    align: 'center',
    sort: true,
  },
];

export const initialValues = {
  eventName: '',
  eventType: '',
  startDate: '',
  endDate: '',
  bookingDate: '',
  bookingStatus: '',
};
