export const defaultValue = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  status: '',
  role: '',
  familyName: '',
  firstName: '',
  industry: '',
  company: '',
  position: '',
  address: {
    flatUnit: '',
    streetNumber: '',
    streetName: '',
    postcode: '',
    country: '',
    state: '',
    suburb: '',
  },
};

export const PaymentDefaultValue = {
  _id: '',
  settingType: '',
  surchrageValueVisa: '',
  paymentTypeVisa: 'VISA',
  surchrageValueMasterCard: '',
  paymentTypeMasterCard: 'MASTERCARD',
  surchrageValueAmericanExpress: '',
  paymentTypeAmericanExpress: 'AMERICAN_EXPRESS',
};

export const userRole = [
  {
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    value: 'SUPERADMIN',
    label: 'Super Admin',
  },
];
export const paymentTypeSelection = [
  {
    value: 'VISA',
    label: 'Visa',
  },
  {
    value: 'MASTERCARD',
    label: 'Mastercard',
  },
  {
    value: 'AMERICAN_EXPRESS',
    label: 'American Express',
  },
];
