import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

// project imports

import useConfig from "hooks/useConfig";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

// assets
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import LocalOfferTwoToneIcon from "@mui/icons-material/LocalOfferTwoTone";
import { TabsProps } from "types";

import useSnackbar from "hooks/common/useSnackbar";
import lang from "constants/language";
import { useMutation } from "@apollo/client";
import { CANCEL_EVENT_DATA } from "grapqhl/event/mutation";
import useAlertDialog from "hooks/common/useAlertDialog";
import { useNavigate } from "react-router-dom";
import AdminAddForm from "./forms/add-admin";
import useGQL from "hooks/useGQL";
import Resetpassword from "./forms/Reset-password-link";
import makeFirstLetterUpperCaseOfEachWord from "common/upperCase";
// tabs
function TabPanel({
  children,
  value,
  index,
  adminId,
  handleOpenSnackbar = () => {},
  setValue = () => {},
  ...other
}: TabsProps) {
  const check = value === 1 || value === 2 || value === 3;
  if ((adminId === null || typeof adminId === "undefined") && check) {
    setValue(0);
    handleOpenSnackbar({
      message: lang.ADMIN.CREATE,

      alertType: "error",
    });
  } else {
    setValue(value);
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// tabs option
const tabsOption = [
  {
    label: "Admin details",
    icon: <PersonOutlineTwoToneIcon />,
    caption: "Edit admin details",
    show: "ALAWAYS",
  },
  {
    label: "Change password",
    icon: <LocalOfferTwoToneIcon />,
    caption: "Change admin password",
  },
];

// ==============================|| PROFILE 2 ||============================== //

const AdminManagementIndex = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();
  const [value, setValue] = React.useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const adminId = params.get("AdminId") as string;

  const { GET_ADMIN, DELETE_ADMIN } = useGQL();

  const {
    error: adminProfileError,
    loading: adminProfileLoading,
    data: adminProfileData,
    refetch: refetchAdmin,
  } = GET_ADMIN(adminId);

  const [adminDataInformation, setAdminDataInformation] = useState({
    firstName: "",
    familyName: "",
    email: "",
    phone: "",
    role: "ADMIN",
    status: "",
  });

  useEffect(() => {
    setAdminDataInformation(adminProfileData?.getAdmin?.admin);
  }, [adminProfileData]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={
            !adminProfileLoading &&
            (adminId
              ? makeFirstLetterUpperCaseOfEachWord(
                  adminDataInformation?.firstName +
                    " " +
                    adminDataInformation?.familyName
                ) || null
              : "Admin Management")
          }
          content={false}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={4} xl={2}>
              <CardContent>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  orientation="vertical"
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      borderBottom: "none",
                    },
                    "& button": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.grey[600]
                          : theme.palette.grey[600],
                      minHeight: "auto",
                      minWidth: "100%",
                      py: 1.5,
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      textAlign: "left",
                      justifyContent: "flex-start",
                      borderRadius: `${borderRadius}px`,
                    },
                    "& button.Mui-selected": {
                      color: theme.palette.primary.main,
                      background:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.main
                          : theme.palette.grey[50],
                    },
                    "& button > svg": {
                      marginBottom: "0px !important",
                      marginRight: 1.25,
                      marginTop: 1.25,
                      height: 20,
                      width: 20,
                    },
                    "& button > div > span": {
                      display: "block",
                    },
                    "& > div > span": {
                      display: "none",
                    },
                  }}
                >
                  {tabsOption.map((tab, index) => (
                    <Tab
                      key={index}
                      icon={tab.icon}
                      label={
                        <Grid container direction="column">
                          <Typography variant="subtitle1" color="inherit">
                            {tab.label}
                          </Typography>
                          <Typography
                            component="div"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab.caption}
                          </Typography>
                        </Grid>
                      }
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </CardContent>
            </Grid>
            <Grid item xs={12} md={8} xl={10}>
              <CardContent
                sx={{
                  borderLeft: "1px solid",
                  borderColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : theme.palette.grey[200],
                  height: "100%",
                }}
              >
                <TabPanel value={value} index={0} setValue={setValue}>
                  <AdminAddForm
                    refetchAdmin={refetchAdmin}
                    adminDataInformation={adminDataInformation}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                  adminId={adminId}
                  handleOpenSnackbar={handleOpenSnackbar}
                  setValue={setValue}
                >
                  {adminId ? (
                    <Resetpassword
                      adminDataInformation={adminDataInformation}
                    />
                  ) : (
                    <Alert severity="error">{lang.ADMIN.CREATE}</Alert>
                  )}
                </TabPanel>
              </CardContent>
            </Grid>
          </Grid>
          <Divider />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default AdminManagementIndex;
