import { gql } from '@apollo/client';

export const LIST_MEMBERSHIPS = gql`
  query ListMemberships($input: GetMembershipDTO!) {
    listMemberships(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      memberships {
        _id
        startDate
        endDate
        membershipType
        membershipFee
        membershipFeeIncGST
        joiningFee
        joiningFeeIncGST
        corporateFees {
          seats
          unitPrice
        }
        name
        createdBy
        updatedAt
        membershipStatus
      }
    }
  }
`;

export const GET_MEMBERSHIP = gql`
  query GetMemberInformation($input: GetmembershipInformationInput!) {
    getMemberInformation(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      membership {
        _id
        startDate
        endDate
        membershipType
        membershipFee
        membershipFeeIncGST
        joiningFee
        joiningFeeIncGST
        corporateFees {
          seats
          unitPrice
        }
        name
        createdBy
        updatedAt
        membershipStatus
      }
    }
  }
`;
