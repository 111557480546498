/* eslint no-underscore-dangle: 0 */
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import moment from 'moment';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

import { headCells } from '../constants/variables';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from '../hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import Spinner from 'components/spinner';
import { useDispatch, useSelector } from 'react-redux';

import {
  BookingStatusMap,
  EventModalityMap,
} from 'views/individual-user/types';
import TableErrorMessage from 'common/tableErrorMessage';
import { Link } from 'react-router-dom';

type Sort = { order: string; orderBy: string } | null;
type EventHistoryTableProps = {
  eventHistories;
  handleRefetch;
};

type Order = 'asc' | 'desc';
const EventHistoryTable = ({
  eventHistories,
  handleRefetch,
}: EventHistoryTableProps) => {
  const dispatch = useDispatch();
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          {eventHistories.length === 0 ? (
            <TableErrorMessage colSpan={12} />
          ) : (
            <TableBody>
              {eventHistories
                .slice()
                .sort(
                  (a, b): number =>
                    +new Date(b.createdAt) - +new Date(a.createdAt)
                )
                .map((history) => (
                  <TableRow key={history._id}>
                    <TableCell sx={{ width: '17rem' }} align="left">
                      {history?.event?._id ? (
                        <Link
                          to={`/events?eventId=${history?.event?._id}`}
                          style={{ textDecoration: 'none', color: '#004C8F' }}
                        >
                          {' '}
                          {history?.event?.title}
                        </Link>
                      ) : (
                        history?.event?.title
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {EventModalityMap[history?.event?.eventType]}
                    </TableCell>
                    <TableCell align="center">
                      {history?.event.sessions.length &&
                        moment(history?.event?.sessions[0]?.startDate).format(
                          'DD/MM/YY'
                        )}
                    </TableCell>
                    <TableCell align="center">
                      {history?.event?.sessions?.length &&
                        moment(
                          history?.event?.sessions[
                            history?.event?.sessions?.length - 1
                          ].endDate
                        ).format('DD/MM/YY')}
                    </TableCell>
                    <TableCell align="center">
                      {moment(history?.bookingDate).format('DD/MM/YY')}
                    </TableCell>
                    <TableCell align="center">
                      {BookingStatusMap[history?.status] || '-'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default EventHistoryTable;
