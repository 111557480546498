import { useMutation, useQuery } from '@apollo/client';

import {
  CreateMembership,
  DeleteMembershipInput,
  DeleteMembershipResponse,
  createMembershipResponse,
  membershipListInput,
} from '../membership-list/constants/types';
import { GET_MEMBERSHIP, LIST_MEMBERSHIPS } from 'grapqhl/memberships/query';
import {
  DELETE_MEMBERSHIP_INFORMATION,
  UPDATE_MEMBERSHIP_INFORMATION,
} from 'grapqhl/memberships/mutation';

export const useGQL = () => {
  const GET_MEMBERSHIP_LIST = (input: membershipListInput = {}) =>
    useQuery(LIST_MEMBERSHIPS, {
      variables: { input },
    });

  const GET_MEMBERSHIP_INFORMATION = (membershipId: string) =>
    useQuery(GET_MEMBERSHIP, {
      variables: {
        input: {
          membershipId: membershipId,
        },
      },
    });

  const UPDATE_MEMBERSHIP = () =>
    useMutation<
      createMembershipResponse,
      {
        input: Partial<CreateMembership>;
      }
    >(UPDATE_MEMBERSHIP_INFORMATION);

  const DELETE_MEMBERSHIP = () =>
    useMutation<
      DeleteMembershipResponse,
      {
        input: Partial<DeleteMembershipInput>;
      }
    >(DELETE_MEMBERSHIP_INFORMATION);

  return {
    GET_MEMBERSHIP_LIST,
    UPDATE_MEMBERSHIP,
    GET_MEMBERSHIP_INFORMATION,
    DELETE_MEMBERSHIP,
  };
};
