/* eslint no-underscore-dangle: 0 */
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Chip from "ui-component/extended/Chip";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import { headCells } from "../constants/variables";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "../hooks/useSnackbar";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Spinner from "components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";
import { GetAllCorporateUsersResponse } from "types/event";
import { StatusMappingObject } from "../constants/types";
import { useGQL } from "views/company/hooks/useGQL";
import TableErrorMessage from "common/tableErrorMessage";

type Sort = { order: string; orderBy: string } | null;
type CompanyTableProps = {
  companyUsers: GetAllCorporateUsersResponse[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

type Order = "asc" | "desc";
const CompanyTable = ({
  companyUsers,
  handleEdit,
  handleRefetch,
}: CompanyTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);

  const { getConfirmation } = useAlertDialog();
  const { handleOpenSnackbar } = useSnackbar();
  const { UPDATE_CORPORATE_MEMBERSHIP } = useGQL();
  const [handleUpdate, { data: update, loading, error }] =
    UPDATE_CORPORATE_MEMBERSHIP();

  useEffect(() => {
    handleRefetch();
  }, [order, orderBy]);
  
  const updateStatusOfCompanyUser = async (
    event: React.MouseEvent,
    id: string,
    status: string
  ) => {
    const isConfirm = await getConfirmation({
      title: status === "VALID" ? "Resign User" : "Valid User",
      message:
        status === "VALID"
          ? `Are you sure you want to resign user?`
          : `Are you sure you want to validate user?`,
    });
    if (isConfirm) {
      const input = {
        status: status === "VALID" ? "RESIGNED" : "VALID",
      };
      try {
        await handleUpdate({
          variables: {
            updateCorporateUserId: id,
            input: { ...input },
          },
        });
        handleOpenSnackbar({
          message: "User has been resigned successfully",
          alertType: "success",
        });
        handleRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: "error" });
      }
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const setOrder = isAsc ? "desc" : "asc";
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  const ActionButton = ({ companyUserId, status }) => {
    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState("");

    const handleMenuActionClick = (event: any, companyUserId: string) => {
      setAnchorElAction(event?.currentTarget);
      setSelected(companyUserId);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };
    return (
      <>
        <IconButton
          onClick={(e) => handleMenuActionClick(e, companyUserId)}
          size="large"
          className="action-button"
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls="menu-popular-card-1"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="menu-popular-card-1"
          anchorEl={anchorElAction}
          keepMounted
          open={Boolean(anchorElAction)}
          onClose={handleActionClose}
          variant="selectedMenu"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={(e) => handleEdit(e, selected)}>
            {" "}
            View / Edit
          </MenuItem>
          {status !== "RESIGNED" && (
            <MenuItem
              onClick={(e) => updateStatusOfCompanyUser(e, selected, status)}
            >
              {" "}
              Resign
            </MenuItem>
          )}
          {status !== "VALID" && (
            <MenuItem
              onClick={(e) => updateStatusOfCompanyUser(e, selected, status)}
            >
              {" "}
              Valid
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === "action") {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {companyUsers.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              companyUsers.map((companyUser) => (
                <TableRow key={companyUser._id}>
                  <TableCell align="left">{companyUser?.company}</TableCell>
                  <TableCell align="left">{`${companyUser?.firstName} ${companyUser?.lastName}`}</TableCell>
                  <TableCell align="left">{companyUser?.phone}</TableCell>
                  <TableCell align="left">{companyUser?.email}</TableCell>
                  <TableCell align="center">
                    {companyUser?.membership?.hasUnlimitedSeats
                      ? "Unlimited"
                      : companyUser?.membership?.totalSeats || 0}
                  </TableCell>
                  <TableCell align="center">
                    {companyUser?.membership?.hasUnlimitedSeats
                      ? "Unlimited"
                      : (companyUser?.membership?.totalSeats ?? 0) -
                          (companyUser?.membership?.seatsTaken ?? 0) || 0}
                  </TableCell>
                  <TableCell align="left">
                    {companyUser?.membership &&
                    companyUser?.membership?.status == "VALID" ? (
                      <Chip
                        label={
                          StatusMappingObject[companyUser?.membership?.status]
                        }
                        size="medium"
                        chipcolor="success"
                      />
                    ) : companyUser?.membership &&
                      companyUser?.membership?.status == "RESIGNED" ? (
                      <Chip
                        label={
                          StatusMappingObject[companyUser?.membership?.status]
                        }
                        size="medium"
                        chipcolor="error"
                      />
                    ) : companyUser?.membership &&
                      companyUser?.membership?.status == "AWATING_PAYMENT" ? (
                      <Chip
                        label={
                          StatusMappingObject[companyUser?.membership?.status]
                        }
                        size="medium"
                        chipcolor="warning"
                      />
                    ) : (
                      companyUser?.membership &&
                      companyUser?.membership?.status == "GRACE" && (
                        <Chip
                          label={
                            StatusMappingObject[companyUser?.membership?.status]
                          }
                          size="medium"
                          chipcolor="secondary"
                        />
                      )
                    )}
                  </TableCell>

                  <TableCell align="center" sx={{ pr: 3 }}>
                    <ActionButton
                      companyUserId={companyUser._id}
                      status={companyUser?.membership?.status}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CompanyTable;
