import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  DUPLICATE,
  UPDATE_RESOURCE,
  UPDATE_RESOURCE_INFORMATION,
} from "grapqhl/resaource/mutation";
import { GET_RESOURCE, GET_RESOURCE_LIST } from "grapqhl/resaource/query";
import {
  deleteResource,
  duplicateResource,
  getResourceInfoInuptDto,
  resourceInformationInputDto,
  resourceInformationListInputDto,
  resourceInformationListOutputDto,
  resourceInformationOutputDto,
} from "types/resource";

export const useGQL = () => {
  const GET_RESOURCE_INFORMATION = (resourceId: string) =>
    useQuery(GET_RESOURCE, {
      variables: {
        input: {
          _id: resourceId,
        },
      },
    });

  const CREATE_RESOURCE = () =>
    useMutation<
      resourceInformationOutputDto,
      { input: Partial<resourceInformationInputDto> }
    >(UPDATE_RESOURCE_INFORMATION);

  const GET_ALL_RESOURCES = (input: resourceInformationListInputDto = {}) =>
    useQuery(GET_RESOURCE_LIST, { variables: { input } });

  const UPDATE_RESOURCE_INFO = () =>
    useMutation<
      deleteResource,
      { resourceId: string; input: Partial<resourceInformationInputDto> }
    >(UPDATE_RESOURCE);

  const DUPLICATE_RESOURCE = () =>
    useMutation<duplicateResource, { resourceId: string }>(DUPLICATE);

  const GET_ALL_RESOURCES_LAZILY = (
    input: resourceInformationListInputDto = {}
  ) => useLazyQuery(GET_RESOURCE_LIST, { variables: { input } });

  return {
    CREATE_RESOURCE,
    GET_RESOURCE_INFORMATION,
    GET_ALL_RESOURCES,
    UPDATE_RESOURCE_INFO,
    DUPLICATE_RESOURCE,
    GET_ALL_RESOURCES_LAZILY,
  };
};
