import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Modal,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Formik } from 'formik';
import useSnackbar from 'hooks/common/useSnackbar';
import { CorporateMembership } from 'types/event';
import * as Yup from 'yup';
import { individualMembershipInputDto } from './types';
import { useEffect } from 'react';
import { useGQL } from './hooks/useGQL';
import lang from 'constants/language';
import Spinner from 'components/spinner';
import dayjs from 'dayjs';

const InputDatePopModal = ({
  open,
  setOpen,
  validUpToDate,
  userId,
  refetchIndividualInformation,
}) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      md: 700,
      xs: '90%',
    },
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
  };
  const { handleOpenSnackbar } = useSnackbar();
  const { UPDATE_INDIVIDUAL_MEMBERSHIP, GET_END_DATE } = useGQL();
  const [handleUpdate, { data: update, loading }] =
    UPDATE_INDIVIDUAL_MEMBERSHIP();
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (update?.updateUser) {
      handleOpenSnackbar({
        message: lang.INDIVIDUAL_USER.MEMBERSHIP_PAID_UP_TO,
        alertType: 'success',
      });
      setOpen(false);
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }

  const handlemembershipPaidToDate = async (
    values: Partial<individualMembershipInputDto>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await handleUpdate({
        variables: {
          updateUserId: userId,
          input: {
            validUptoDate: values?.validUptoDate || null,
          },
        },
      });
      setSubmitting(false);
      refetchIndividualInformation();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: 'error' });
      setSubmitting(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <Formik
            initialValues={{
              validUptoDate: null,
            }}
            validationSchema={Yup.object().shape({
              validUptoDate: Yup.date()
                .required('Membership paid up to is required')
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const input = {
                ...values,
              };

              setSubmitting(true);
              handlemembershipPaidToDate(input, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    marginBottom: '1.5rem',
                  }}
                >
                  Enter Membership Paid To Date
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ maxWidth: '697px' }}
                >
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        value={
                          values?.validUptoDate
                            ? new Date(values?.validUptoDate)
                            : null
                        }
                        label="Membership Paid To"
                        minDate={
                          validUpToDate
                            ? dayjs(validUpToDate).add(1, 'month').toDate()
                            : null
                        }
                        sx={{ width: '100%' }}
                        onChange={(newValue: any) => {
                          setFieldValue(
                            'validUptoDate',
                            newValue ? dayjs(newValue).toJSON() : null
                          );
                        }}
                      />
                    </LocalizationProvider>
                    {touched?.validUptoDate && errors.validUptoDate && (
                      <FormHelperText error id="validUptoDate-error">
                        {errors.validUptoDate}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Box
                  sx={{ marginTop: '2em', display: 'flex', gap: '20px' }}
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{ color: 'white' }}
                    variant="contained"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ color: 'white' }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Please wait...' : 'Save Changes'}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </>
    </Modal>
  );
};

export default InputDatePopModal;
